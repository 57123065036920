import cl from './Misson.module.scss';
import { Link } from 'react-router-dom';

const Mission = () => {
  return (
    <div className={cl.block}>
      <p className={cl.text + ` ${cl.text_first}`}>
        Мы приглашаем вас воспользоваться возможностью стать частью нашей
        растущей экосистемы. Для начала сотрудничества просим заполнить заявку
        на партнерство на нашем сайте. После рассмотрения вашей заявки наша
        команда свяжется с вами для обсуждения деталей потенциального
        сотрудничества.
      </p>
      <p className={cl.text + ` ${cl.text_sec}`}>
        С уважением,
        <br />
        Команда Qlixmed
      </p>
      <Link to="tel:+78000707072" className={`${cl.btn} btn btn-accent`}>
        Оставить заявку на сотрудничество
      </Link>
    </div>
  );
};

export default Mission;
