import React, { useEffect } from 'react';
import { Navigate, Route, Routes, useLocation } from 'react-router-dom';
import HomePage from '@pages/home/HomePage';
import NotFoundPage from '@pages/notFound/NotFoundPage';
import SignPage from '@pages/sign/SignPage';
import Bar from '@components/bar/Bar';
import { useDispatch, useSelector } from 'react-redux';
import {
  setIsSign,
  setToken,
  setUid,
  setUser,
  signSel,
} from '@store/slices/sign/signSlice';
import ProfilePage from '@pages/profile/ProfilePage';
import CatalogPage from '@pages/catalog/CatalogPage';
import ProductPage from '@pages/product/ProductPage';
import CartPage from '@pages/cart/CartPage';
import OrdersPage from '@pages/orders/OrdersPage';
import useWindowWidth from '@hooks/windowWidth';

import Confirm from '@components/confirm/Confirm';
import { confirmSel } from '@store/slices/confirm/confirmSlice';
import { v4 as uuidv4 } from 'uuid';
import OrderPage from '@pages/order/OrderPage';
import SellerPage from '@pages/seller/SellerPage';
import PartnersPage from '@pages/partners/PartnersPage';
import FaqPage from '@pages/faq/FaqPage';
import AboutPage from '@pages/about/AboutPage';
import DeliveryPage from '@pages/delivery/DeliveryPage';

const App = () => {
  const { isSign } = useSelector(signSel);
  const { isConfirm, confirmProps } = useSelector(confirmSel);
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const windowWidth = useWindowWidth();

  useEffect(() => {
    const storedToken = localStorage.getItem('token');
    const storedUser = localStorage.getItem('user');

    if (storedUser) {
      const tokenData = JSON.parse(storedToken);
      const userData = JSON.parse(storedUser);

      dispatch(setToken(tokenData));
      dispatch(setUser(userData));
      dispatch(setIsSign(true));
    }
  }, [dispatch]);

  useEffect(() => {
    const storedUid = localStorage.getItem('uid');

    if (storedUid && storedUid.length > 1) {
      const uidData = JSON.parse(storedUid);

      dispatch(setUid(uidData));
    } else {
      localStorage.setItem('uid', JSON.stringify(uuidv4()));
    }
  }, [dispatch]);

  useEffect(() => {
    if (!pathname.includes('chats')) {
      window.scrollTo(0, 0);
    }

    if (pathname === '/profile') {
    }
  }, [pathname]);

  return (
    <div className="site-container">
      {isConfirm && (
        <Confirm
          title={confirmProps.title}
          subtitle={confirmProps.subtitle}
          btns={confirmProps.btns}
        />
      )}
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/catalog/*" element={<CatalogPage />} />
        <Route path="/product/:productSlugId" element={<ProductPage />} />
        <Route path="/seller/:sellerSlugId" element={<SellerPage />} />
        <Route path="/cart" element={<CartPage />} />
        <Route path="/partners" element={<PartnersPage />} />
        <Route path="/faq" element={<FaqPage />} />
        <Route path="/about" element={<AboutPage />} />
        <Route path="/delivery" element={<DeliveryPage />} />

        {isSign ? (
          <>
            {/* <Route path="/favors/products" element={<FavorsPage />} />
            <Route path="/favors/sellers" element={<FavorsPage />} /> */}
            <Route path="/orders" element={<OrdersPage />} />
            <Route path="/orders/:uId" element={<OrderPage />} />
            {windowWidth < 1025 ? (
              <>
                <Route path="/profile" element={<ProfilePage />} />
                <Route path="/profile/personal" element={<ProfilePage />} />
                <Route path="/profile/payment" element={<ProfilePage />} />
                <Route path="/profile/addresses" element={<ProfilePage />} />
              </>
            ) : (
              <>
                <Route
                  path="/profile/personal"
                  element={<Navigate to="/profile" />}
                />
                <Route index path="/profile" element={<ProfilePage />} />
                <Route path="/profile/personal" element={<ProfilePage />} />
                <Route path="/profile/payment" element={<ProfilePage />} />
                <Route path="/profile/addresses" element={<ProfilePage />} />
              </>
            )}
          </>
        ) : (
          <Route path="/sign" element={<SignPage />} />
        )}
        <Route path="*" element={<NotFoundPage />} />
      </Routes>
      {windowWidth < 1025 && <Bar />}
    </div>
  );
};

export default App;
