import cl from './Pagination.module.scss';
import ReactPaginate from 'react-paginate';

const Pagination = ({ classNames, itemsPerPage, total, onPaginate }) => {
  const pageCount = Math.ceil(total / itemsPerPage);

  const handlePageClick = (data) => {
    let selected = data.selected + 1;

    if (onPaginate) {
      onPaginate(selected);
    }
  };

  return (
    total > itemsPerPage && (
      <ReactPaginate
        previousLabel={
          <svg
            className={`${cl.next} ico-10 stroke`}
            xmlns="http://www.w3.org/2000/svg"
            width="7"
            height="10"
            viewBox="0 0 7 10"
            fill="none"
          >
            <path
              d="M6 1L3.5 3L1.48804 4.60957C1.23784 4.80973 1.23784 5.19027 1.48804 5.39043L6 9"
              stroke="currentColor"
              strokeWidth="1.74773"
              strokeLinecap="round"
            />
          </svg>
        }
        nextLabel={
          <svg
            className={`${cl.prev} ico-10 stroke`}
            xmlns="http://www.w3.org/2000/svg"
            width="7"
            height="10"
            viewBox="0 0 7 10"
            fill="none"
          >
            <path
              d="M1 1L3.5 3L5.51196 4.60957C5.76216 4.80973 5.76216 5.19027 5.51196 5.39043L1 9"
              stroke="currentColor"
              strokeWidth="1.74773"
              strokeLinecap="round"
            />
          </svg>
        }
        onPageChange={handlePageClick}
        pageRangeDisplayed={2}
        marginPagesDisplayed={1}
        pageCount={pageCount}
        renderOnZeroPageCount={null}
        containerClassName={cl.container + ` ${classNames}`}
        pageLinkClassName={`${cl.link}`}
        previousLinkClassName={`${cl.prev} ${cl.link}`}
        nextLinkClassName={`${cl.next} ${cl.link}`}
        activeLinkClassName={cl.link__active}
      />
    )
  );
};

export default Pagination;
