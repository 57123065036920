const { createSlice } = require("@reduxjs/toolkit");

const initialState = {
  order: null,
};

const orderSlice = createSlice({
  name: 'order',
  initialState,
  reducers: {
    setOrder: (state, action) => {
      state.order = action.payload;
    }
  }
})

export const {setOrder} = orderSlice.actions;
export const orderSel = state => state.order;

export default orderSlice.reducer;
