import React from "react";

const MyForm = ({
  children,
  classNames,
  action = '#',
  onSubmit,
  ...props
}) => {
  return (
    <form className={`${classNames} form`} action={action} onSubmit={onSubmit} {...props}>
      {children}
    </form>
  );
};

export default MyForm;
