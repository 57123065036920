import Descr from './descr/Descr';
import { useSelector } from 'react-redux';
import { productSel, setCurTab } from '@store/slices/product/productSlice';
import Chars from './chars/Chars';
import Reviews from './reviews/Reviews';
import MyTabs from '@components/_UI/tabs/MyTabs';

const Tabs = ({ outerCl }) => {
  const { tabs, curTab } = useSelector(productSel);

  return (
    <MyTabs
      tabs={tabs}
      curTab={curTab}
      setCurTab={setCurTab}
      outerCl={outerCl}
      id="productTabs"
    >
      {curTab.key === tabs[0].key ? (
        <Descr />
      ) : (
        curTab.key === tabs[1].key && <Reviews curTab={curTab} reviewsTab={tabs[1]} />
      )}
    </MyTabs>
  );
};

export default Tabs;
