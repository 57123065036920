import AccData from "./accData/AccData";

import cl from "./Personal.module.scss";

const Personal = () => {
  return (
    <div className={cl.outer}>
      <AccData />
    </div>
  );
};

export default Personal;
