import './HomePageCategories.scss';

import { useDispatch, useSelector } from 'react-redux';
import {
  catalogSel,
  setCatTree,
  setCurCatRoot,
} from '@store/slices/catalog/catalogSlice';
import { Link } from 'react-router-dom';

const HomePageCategories = () => {
  const { catRoots } = useSelector(catalogSel);
  const dispatch = useDispatch();

  const onCat = (item) => {
    dispatch(setCurCatRoot(item));
    dispatch(setCatTree(item.sub_categories));
  };

  return (
    <section className="categories">
      <div className="container">
        <h3 className="categories__title title-section">Каталог</h3>
        <div className="categories__inner">
          <div className="categories__block-outer">
            {catRoots?.map((category) => (
              <Link
                className="categories__block"
                key={category.id}
                to={`/catalog/${category.slug}`}
                onClick={() => onCat(category)}
              >
                <h5 className="categories__block-title">
                  {category.category_name}
                </h5>
                <img
                  className="categories__block-img"
                  loading="lazy"
                  src={category.image_path}
                  width="210"
                  height="184"
                  alt={category?.seo_title} title={category?.seo_title}
                />
              </Link>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};

export default HomePageCategories;
