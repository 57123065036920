import MyPage from '@components/_UI/MyPage';
import MySection from '@components/_UI/MySection';
import Breadcrumbs from '@components/breadcrumbs/Breadcrumbs';
import Footer from '@components/footer/Footer';
import Header from '@components/header/Header';
import NotFound from '@components/notFound/NotFound';
import { getInfo, partnersSel } from '@store/slices/partners/partnersSlice';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Potencial from './potencial/Potencial';
import Provide from './provide/Provide';
import Mission from './mission/Mission';

const PartnersPage = () => {
  const dispatch = useDispatch();
  const { info } = useSelector(partnersSel);

  useEffect(() => {
    dispatch(getInfo());
  }, [dispatch]);

  return (
    <>
      <Header />
      <MyPage
        metaTitle={`Qlixmed | ${info ? info.seo_title : 'Не найдено'}`}
        metaDescr={`${info ? info.seo_description : 'Не найдено'}`}
        metaKeywords={`${info ? info.seo_keywords : 'Не найдено'}`}
      >
        <MySection>
          {info ? (
            <>
              <Breadcrumbs />
              <div className={'flex flex-col gap-30'}>
                <h1 className={`title title-section`}>Партнерам</h1>
                <Potencial />
                <Provide />
                <Mission />
              </div>
            </>
          ) : (
            <NotFound
              title={'Нет данных'}
              subtitle={'Не удалось получить данные'}
            />
          )}
        </MySection>
      </MyPage>
      <Footer />
    </>
  );
};

export default PartnersPage;
