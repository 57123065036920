import Product from '@components/product/Product';
import cl from './Products.module.scss';
import Pagination from '@components/pagination/Pagination';
import { useDispatch, useSelector } from 'react-redux';
import {
  getPopularProducts,
  productsSel,
} from '@store/slices/products/productsSlice';
import { useEffect } from 'react';

const Products = () => {
  const dispatch = useDispatch();
  const { popularProducts } = useSelector(productsSel);

  useEffect(() => {
    dispatch(getPopularProducts());
  }, [dispatch]);

  return (
    <div className={`${cl.content}`}>
      <div className={cl.items}>
        {popularProducts?.slice(0,8).map((item) => (
          <Product classNames={cl.item} product={item} key={item.id} />
        ))}
      </div>
      <Pagination itemsPerPage={15} total={popularProducts?.length} />
    </div>
  );
};

export default Products;
