import { Link } from 'react-router-dom';

import cl from './Product.module.scss';
import Rating from '@components/rating/Rating';

const Product = ({ classNames, product, link }) => {
  return (
    <Link className={`${classNames} ${cl.card}`} to={link}>
      <img
        className={cl.img}
        loading="lazy"
        src={product.image?.path}
        width="222"
        height="194"
        alt={product?.seo_title} title={product?.seo_title}
      />
      <div className={cl.info}>
        <h3 className={`${cl.title} slider__title`}>{product.product_name}</h3>
        <Rating
          ratingCl={cl.rating}
          starCl={cl.star}
          rateCl={cl.rate}
          rateCount={product.review_count}
          stars={product.rating}
        />
        <p className={cl.text}>{product.description}</p>
        <div className={cl.bar + ' slider-bar'}>
          <button className={`${cl.btn} slider-btn btn btn-accent btn-lazur`}>
            <svg
              className="ico-16 stroke"
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
            >
              <g clipPath="url(#clip0_1970_10181)">
                <path
                  d="M6.00016 14.6663C6.36835 14.6663 6.66683 14.3679 6.66683 13.9997C6.66683 13.6315 6.36835 13.333 6.00016 13.333C5.63197 13.333 5.3335 13.6315 5.3335 13.9997C5.3335 14.3679 5.63197 14.6663 6.00016 14.6663Z"
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M13.3332 14.6663C13.7014 14.6663 13.9998 14.3679 13.9998 13.9997C13.9998 13.6315 13.7014 13.333 13.3332 13.333C12.965 13.333 12.6665 13.6315 12.6665 13.9997C12.6665 14.3679 12.965 14.6663 13.3332 14.6663Z"
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M0.666504 0.666992H3.33317L5.11984 9.59366C5.1808 9.90059 5.34778 10.1763 5.59153 10.3725C5.83529 10.5688 6.1403 10.673 6.45317 10.667H12.9332C13.246 10.673 13.551 10.5688 13.7948 10.3725C14.0386 10.1763 14.2055 9.90059 14.2665 9.59366L15.3332 4.00033H3.99984"
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </g>
              <defs>
                <clipPath id="clip0_1970_10181">
                  <rect width="16" height="16" fill="currentColor" />
                </clipPath>
              </defs>
            </svg>
            <span>Купить</span>
          </button>
        </div>
      </div>
    </Link>
  );
};

export default Product;
