import React from "react";
import cl from "./NotFound.module.scss";

const NotFound = ({
  children,
  outerCl,
  titleCl,
  subtitleCl,
  title,
  subtitle,
}) => {
  return (
      <div className={`${outerCl} ${cl.outer}`}>
        <h3 className={`${titleCl} ${cl.title}`}>{title}</h3>
        <p className={`${subtitleCl} ${cl.subtitle}`}>{subtitle}</p>
        {children}
      </div>
  );
};

export default NotFound;
