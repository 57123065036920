import MySection from '@components/_UI/MySection';
import MyBtn from '@components/_UI/btns/MyBtn';
import SliderBtn from '@components/_UI/slider/sliderBtn/SliderBtn';
import Product from '@components/product/Product';
import React, { useRef } from 'react';
import { Link } from 'react-router-dom';
import { Grid, Mousewheel, Navigation } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';

const Products = ({
  classNames,
  containerCl,
  innerCl,
  products,
  sliderCl,
  slideCl,
  slideContentCl,
  sliderNavCl,
  sliderBtnCl,
  title,
  onClick,
}) => {
  const goPrev = useRef(null);
  const goNext = useRef(null);

  return (
    <MySection
      classNames={classNames}
      containerCl={containerCl}
      innerCl={innerCl}
    >
      <div className="slider-section__high">
        <div className="slider-section__title-outer">
          <h3 className="slider-section__title title-section">{title}</h3>
        </div>
        <div className={`${sliderNavCl} slider-nav`}>
          <SliderBtn classNames={`${sliderBtnCl} prev`} ref={goPrev} />
          <SliderBtn classNames={`${sliderBtnCl} next`} ref={goNext} />
        </div>
        <MyBtn classNames="slider-section__more btn-accent" onClick={onClick}>
          <span>Смотреть больше</span>
          <svg className="ico-16 stroke">
            <g id="chevron-down">
              <path
                id="Vector"
                d="M6.75 4.5L11.25 9L6.75 13.5"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </g>
          </svg>
        </MyBtn>
      </div>

      <Swiper
        className={`${sliderCl} slider swiper`}
        modules={[Navigation, Mousewheel, Grid]}
        grabCursor={true}
        speed={500}
        loop={'true'}
        mousewheel={{
          forceToAxis: true,
        }}
        navigation={{
          prevEl: goPrev.current,
          nextEl: goNext.current,
        }}
        onSwiper={(swiper) => {
          setTimeout(() => {
            swiper.params.navigation.prevEl = goPrev.current;
            swiper.params.navigation.nextEl = goNext.current;

            swiper.navigation.destroy();
            swiper.navigation.init();
            swiper.navigation.update();
          });
        }}
        breakpoints={{
          320: {
            slidesPerView: 2,
            spaceBetween: 10,
            grid: {
              fill: 'row',
              rows: '2',
            },
          },

          1025: {
            slidesPerView: 4,
            spaceBetween: 25,
            grid: {
              fill: 'row',
              rows: '1',
            },
          },
        }}
      >
        {products?.map((product) => (
          <SwiperSlide
            className={`${slideCl} slider-slide swiper-slide`}
            key={product.id}
          >
            <Product
              link={`/product/${product.slug}-${product.id}`}
              classNames={`${slideContentCl} slider-link`}
              product={product}
              key={product.id}
            />
          </SwiperSlide>
        ))}
      </Swiper>
      <Link className="slider-section__more-mob btn btn-accent">
        <span>Смотреть больше</span>
        <svg className="ico-16 stroke">
          <g id="chevron-down">
            <path
              id="Vector"
              d="M6.75 4.5L11.25 9L6.75 13.5"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </g>
        </svg>
      </Link>
    </MySection>
  );
};

export default Products;
