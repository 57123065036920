import { useDispatch, useSelector } from 'react-redux';
import {
  headerSel,
  searchProducts,
  setSearchDebVal,
  setSearchIsOpen,
  setSearchResults,
  setSearchVal,
} from '@store/slices/header/headerSlice';
import MyModal from '@components/_UI/modal/MyModal';
import SearchComp from '@components/Search/Search';
import { useEffect } from 'react';
import NotFound from '@components/notFound/NotFound';
import { Link } from 'react-router-dom';
import Product from './product/Product';
import { onHide } from '@hooks/toHide';

const Search = () => {
  const dispatch = useDispatch();
  const { searchVal, searchDebVal, searchIsOpen, searchResults } =
    useSelector(headerSel);

  // const [sortIsActive, setSortIsActive] = useState(false);

  // const onSort = () => {
  //   if (sortIsActive) {
  //     enableScroll();
  //   } else {
  //     disableScroll();
  //   }
  //   setSortIsActive(!sortIsActive);
  // };

  useEffect(() => {
    if (searchDebVal.length > 1) {
      dispatch(searchProducts(searchDebVal));
      dispatch(setSearchIsOpen(true));
      onHide(false);
    } else {
      dispatch(setSearchResults([]));
      dispatch(setSearchIsOpen(false));
      onHide(true);
    }
  }, [dispatch, searchDebVal]);

  const onLink = () => {
    console.log('onLink');
    dispatch(setSearchVal(''));
    dispatch(setSearchDebVal(''));
    dispatch(setSearchResults([]));
    dispatch(setSearchIsOpen(false));
  };

  return (
    <>
      <SearchComp
        value={searchVal}
        setValue={setSearchVal}
        setDebValue={setSearchDebVal}
      />
      <MyModal
        overlayCl={'header__search-modal-overlay'}
        modalCl={'header__search-modal'}
        innerCl={'header__search-modal-inner'}
        closeCl={'header__search-modal-close'}
        modalIsOpen={searchIsOpen}
        setModalIsOpen={setSearchIsOpen}
        closeIsNeed={false}
      >
        {searchResults && searchResults?.length < 1 ? (
          <NotFound
            outerCl={'header__search-modal-notFound'}
            title={'Товары не найдены'}
            subtitle={'Проверьте - правильно ли Вы написали свой запрос'}
          />
        ) : (
          <div className="container">
            <div className="header__search-modal-results">
              {searchResults?.map((product) => (
                <Link
                  className={'header__search-modal-results-link'}
                  to={`/product/${product.slug}-${product.id}`}
                  key={product.id}
                  onClick={onLink}
                >
                  {/* <span>{product.product_name}</span> */}
                  <Product product={product} />
                </Link>
              ))}
            </div>
          </div>
        )}
      </MyModal>
    </>
  );
};

export default Search;
