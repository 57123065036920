import CheckRadio from '@components/_UI/CheckRadio';
import React from 'react';

import cl from './Product.module.scss';

import MyBtn from '@components/_UI/btns/MyBtn';
import useWindowWidth from '@hooks/windowWidth';
import { useDispatch, useSelector } from 'react-redux';
import {
  cartSel,
  setSelectedCartProducts,
  setUpdQuantity,
  updCartProductCount,
} from '@store/slices/cart/cartSlice';
import { signSel } from '@store/slices/sign/signSlice';
import { clsx } from 'clsx';

const Product = ({ id, product, quantity, toDelProduct }) => {
  const windowWidth = useWindowWidth();
  const dispatch = useDispatch();

  const { selectedCartProducts } = useSelector(cartSel);
  const { uid } = useSelector(signSel);

  const onSelect = (value) => {
    dispatch(setSelectedCartProducts(value));
  };

  const onDecQuantity = () => {
    const newQuantity = quantity - 1;
    dispatch(
      updCartProductCount({ uid, cartId: id, quantity: Number(newQuantity) }),
    ).then(() => {
      dispatch(setUpdQuantity({ cartId: id, quantity: Number(newQuantity) }));
    });
  };

  const onIncQuantity = () => {
    const newQuantity = quantity + 1;
    dispatch(
      updCartProductCount({ uid, cartId: id, quantity: Number(newQuantity) }),
    ).then(() => {
      dispatch(setUpdQuantity({ cartId: id, quantity: Number(newQuantity) }));
    });
  };

  return (
    <>
      <div className={cl.product}>
        <CheckRadio
          classNames={cl.check}
          customClassName={cl.check__custom}
          value={id}
          checked={selectedCartProducts.includes(id)}
          onChange={() => onSelect(id)}
          type={'checkbox'}
        />
        <div className={cl.inner}>
          <img className={cl.img} src={product?.image?.path}  alt={product?.seo_title} title={product?.seo_title} />
          <div className={cl.info}>
            <div className={cl.texts}>
              {windowWidth < 1025 && (
                <p className={cl.price}>{/*{product.price} ₸*/}Цена по запросу</p>
              )}
              <h3 className={cl.title}>{product.product_name}</h3>
              <ul className={cl.list}>
                <li className={cl.item}>Код товара: {product.sku}</li>
                <li className={cl.item}>{quantity} шт.</li>
              </ul>
            </div>
            <div className={cl.count}>
              <button
                className={clsx(cl.dec + ' ', { [cl.dis]: quantity <= 1 })}
                onClick={() => onDecQuantity()}
              >
                <svg
                  className="ico-12 stroke"
                  xmlns="http://www.w3.org/2000/svg"
                  width="8"
                  height="13"
                  viewBox="0 0 8 13"
                  fill="none"
                >
                  <path
                    d="M7 1L2 6.5L7 12"
                    stroke="currentColor"
                    strokeWidth="2"
                  />
                </svg>
              </button>

              <span>{quantity}</span>

              <button className={cl.inc} onClick={() => onIncQuantity()}>
                <svg
                  className="ico-12 stroke"
                  xmlns="http://www.w3.org/2000/svg"
                  width="8"
                  height="13"
                  viewBox="0 0 8 13"
                  fill="none"
                >
                  <path
                    d="M1 1L6 6.5L1 12"
                    stroke="currentColor"
                    strokeWidth="2"
                  />
                </svg>
              </button>
            </div>
            <div className={cl.output}>
              {windowWidth >= 1025 && (
                <p className={cl.price}>{/*{product.price} ₸*/} Цена по запросу</p>
              )}
              <div className={cl.btns}>
                <MyBtn
                  classNames={cl.del + ' btn btn-ico'}
                  onClick={() => toDelProduct(id, true)}
                >
                  <svg
                    className="ico-24 stroke"
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                  >
                    <path
                      d="M3 6H5H21"
                      stroke="currentColor"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M19 6V20C19 20.5304 18.7893 21.0391 18.4142 21.4142C18.0391 21.7893 17.5304 22 17 22H7C6.46957 22 5.96086 21.7893 5.58579 21.4142C5.21071 21.0391 5 20.5304 5 20V6M8 6V4C8 3.46957 8.21071 2.96086 8.58579 2.58579C8.96086 2.21071 9.46957 2 10 2H14C14.5304 2 15.0391 2.21071 15.4142 2.58579C15.7893 2.96086 16 3.46957 16 4V6"
                      stroke="currentColor"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M10 11V17"
                      stroke="currentColor"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M14 11V17"
                      stroke="currentColor"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </MyBtn>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Product;
