import Modal from './modal/Modal';
import cl from './Burger.module.scss';
import { useDispatch, useSelector } from 'react-redux';
import { headerSel, setBurgerIsOpen } from '@store/slices/header/headerSlice';
import { onHide } from '@hooks/toHide';
import { clsx } from 'clsx';

const Burger = () => {
  const dispatch = useDispatch();
  const { burgerIsOpen } = useSelector(headerSel);

  const onBurger = () => {
    dispatch(setBurgerIsOpen(true));
    onHide(false, false, true);
  };

  return (
    <>
      <button
        className={clsx(cl.burger, { [cl.burger_active]: burgerIsOpen })}
        onClick={onBurger}
      >
        <span className={cl.burger_line}></span>
      </button>
      <Modal modalIsOpen={burgerIsOpen} setModalIsOpen={setBurgerIsOpen} />
    </>
  );
};

export default Burger;
