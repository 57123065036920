import MyBtn from "@components/_UI/btns/MyBtn";
import { clsx } from "clsx";
// import CatalogPageFilterPrice from "@pages/catalog/filter/price/CatalogPageFilterPrice";
// import CatalogPageFilterSellers from "@pages/catalog/filter/sellers/CatalogPageFilterSellers";
// import CatalogPageFilterRating from "@pages/catalog/filter/rating/CatalogPageFilterRating";
import CatalogPageFilterCategories from "@pages/catalog/filter/categories/CatalogPageFilterCategories";
import PerPage from "@pages/catalog/filter/perPage/PerPage";
import { useSelector } from "react-redux";
import { catalogSel } from "@store/slices/catalog/catalogSlice";

const CatalogPageHeadFilter = ({
  filterIsOpen,
  onFilter,
}) => {
  const {totalProducts} = useSelector(catalogSel);
  return (
    <div
      className={clsx("catalog__head-filter-overlay overlay fixed-block", {
        active: filterIsOpen,
      })}
      onClick={onFilter}
    >
      <div className="catalog__head-filter" onClick={e => e.stopPropagation()}>
        <div className="catalog__head-filter-head">
          <button
            className="catalog__head-filter-close link link-accent"
            onClick={onFilter}
          >
          <svg
            className="ico-24 stroke"
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
          >
            <path
              d="M15 6L9 12L15 18"
              stroke="#337BF9"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
          </button>
          <h4 className="catalog__head-filter-title">Фильтр</h4>
        </div>
        <div className="catalog__head-filter-block-outer">
          <CatalogPageFilterCategories
          />
          <PerPage/>
          {/* <CatalogPageFilterPrice /> */}
          {/* <CatalogPageFilterSellers />
          <CatalogPageFilterRating /> */}
        </div>
        <div className="catalog__head-filter-btn-outer">
          <MyBtn
            classNames={"catalog__head-filter-btn btn-accent"}
            onClick={onFilter}
          >
            Показать {' '}
            {totalProducts}{" "} тоавара
          </MyBtn>
        </div>
      </div>
    </div>
  );
};

export default CatalogPageHeadFilter;
