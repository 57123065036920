import React from "react";

import cl from "./MyDropdown.module.scss";
import { clsx } from "clsx";

const MyDropdown = ({ classNames, options, value, sortIsOpen, setSortIsOpen, onSelect }) => {
  const closeSort = () => {
    setSortIsOpen(false)
  };

  return (
    <>
      <div
        className={clsx(`overlay ${cl.overlay}`, { active: sortIsOpen })}
        onClick={closeSort}
      ></div>
      <div className={clsx(`${cl.dropdown}  ${classNames}`, { [cl.active]: sortIsOpen })}>
        <button className={cl.control} onClick={() => {setSortIsOpen(!sortIsOpen)}} type="button">
          <span className={cl.control__text}>{value.label}</span>
          <svg
            className={`ico-10 stroke ${cl.control__arrow}`}
            xmlns="http://www.w3.org/2000/svg"
            width="11"
            height="7"
            viewBox="0 0 11 7"
            fill="none"
          >
            <path
              d="M10 1.25L5.5 5.75L1 1.25"
              stroke="black"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </button>
        <div className={cl.list}>
          {options.map(obj => (
            <button
              className={clsx(cl.item, {
                [cl.active]: value.value === obj.value,
              })}
              onClick={() => onSelect(obj)}
              key={obj.value}
              type="button"
            >
              {obj.label}
            </button>
          ))}
        </div>
      </div>
    </>
  );
};

export default MyDropdown;
