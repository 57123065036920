import React, { forwardRef } from "react";

const SliderBtn = forwardRef((props, ref) => {
  const {classNames} = props;
  return (
    <button className={`${classNames} slider-nav__btn btn btn-slider`} ref={ref}>
      <svg className="ico-16 stroke">
        <g id="chevron-down">
          <path
            id="Vector"
            d="M6.75 4.5L11.25 9L6.75 13.5"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>
      </svg>
    </button>
  );
});

export default SliderBtn;
