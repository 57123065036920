import { loadStatus } from '@store/loadStatus';
import axios from 'axios';

const { createSlice, createAsyncThunk } = require('@reduxjs/toolkit');

export const getCartProducts = createAsyncThunk(
  'cart/getCart',
  async ({ uid }, { rejectWithValue }) => {
    try {
      const { data } = await axios.get(
        `${process.env.REACT_APP_API_URL}v1/cart/${uid}`,
      );

      return data;
    } catch (err) {
      console.log(`ошибка получения продуктов корзины: ${err}`);
      return rejectWithValue(err.message);
    }
  },
);

export const addToCart = createAsyncThunk(
  'cart/addToCart',
  async ({ uid, product_id, quantity }, { rejectWithValue }) => {
    try {
      const bodyParameters = {
        user_uid: uid,
        product_id,
        quantity,
      };

      const { data } = await axios.post(
        `${process.env.REACT_APP_API_URL}v1/cart`,
        bodyParameters,
      );

      return data;
    } catch (err) {
      console.log(`ошибка получения продуктов корзины: ${err}`);
      return rejectWithValue(err.message);
    }
  },
);

export const updCartProductCount = createAsyncThunk(
  'cart/updCartProductCount',
  async ({ uid, cartId, quantity }, { rejectWithValue }) => {
    try {
      const bodyParameters = {
        user_uid: uid,
        cart_id: cartId,
        quantity,
      };

      const { data } = await axios.post(
        `${process.env.REACT_APP_API_URL}v1/cart/update`,
        bodyParameters,
      );

      return data;
    } catch (err) {
      console.log(`ошибка обновления продукта корзины: ${err}`);
      return rejectWithValue(err.message);
    }
  },
);

export const removeCartProducts = createAsyncThunk(
  'cart/removeCartProducts',
  async ({ uid, cart_ids }, { rejectWithValue }) => {
    try {
      const bodyParameters = {
        user_uid: uid,
        cart_ids,
      };

      const { data } = await axios.post(
        `${process.env.REACT_APP_API_URL}v1/cart/delete`,
        bodyParameters,
      );

      return data;
    } catch (err) {
      console.log(`ошибка удаления продуктов из корзины: ${err}`);
      return rejectWithValue(err.message);
    }
  },
);

const initialState = {
  totalLength: 0,
  totalPrice: 0,
  cartProducts: null,
  cartGot: false,
  delModal: false,
  videoModal: false,
  allSelected: false,
  selectedCartProducts: [],
  cartLoadStatus: 'idle',
};

const cartSlice = createSlice({
  name: 'cart',
  initialState,

  reducers: {
    setSelectedCartProducts: (state, action) => {
      const value = action.payload;
      if (state.selectedCartProducts.includes(value)) {
        state.selectedCartProducts = state.selectedCartProducts.filter(
          (item) => item !== value,
        );
      } else {
        state.selectedCartProducts.push(value);
      }
    },

    setAllSelected: (state, action) => {
      state.allSelected = action.payload;
    },

    setAddToCart: (state, action) => {
      const findItem = state.cartProducts?.find(
        (item) => item.product.id === action.payload.product.id,
      );

      if (findItem) {
        findItem.quantity++;
      } else {
        state.cartProducts.push({ ...action.payload, quantity: 1 });
        state.totalLength = state.totalLength + 1;
      }
    },

    setUpdQuantity: (state, action) => {
      const findItem = state.cartProducts?.find(
        (item) => item.id === action.payload.cartId,
      );

      findItem.quantity = action.payload.quantity;

      state.totalPrice = state.cartProducts.reduce(
        (all, cur) => all + cur.product.price * cur.quantity,
        0,
      );

      state.totalLength = state.cartProducts.reduce(
        (all, cur) => all + cur.quantity, 0
      );
    },

    setRemovedProduct: (state, action) => {
      action.payload.cartIds.forEach((cartId) => {
        const removedProduct = state.cartProducts.find(
          (item) => item.id === cartId,
        );

        state.cartProducts = state.cartProducts.filter(
          (product) => product.id !== removedProduct.id,
        );

        state.selectedCartProducts = state.selectedCartProducts.filter(
          (id) => id !== removedProduct.id,
        );

        state.totalPrice = state.cartProducts.reduce(
          (all, cur) => all + cur.product.price * cur.quantity,
          0,
        );

        state.totalLength = state.cartProducts.reduce(
          (all, cur) => all + cur.quantity, 0
        );
      });
    },

    setVideoModal: (state, action) => {
      state.videoModal = action.payload;
    },

    setDelModal: (state, action) => {
      state.delModal = action.payload;
    },

    setCartGot: (state, action) => {
      state.cartGot = action.payload;
    },

    selectAllCartProducts: (state, action) => {
      state.selectedCartProducts = action.payload;
    },

    deselectAllCartProducts: (state) => {
      state.selectedCartProducts = [];
    },

    setTotalLength: (state, action) => {
      state.totalLength = action.payload;
    },
  },

  extraReducers: (builder) => {
    builder
      .addCase(getCartProducts.pending, (state) => {
        state.cartLoadStatus = loadStatus.pending;
      })
      .addCase(getCartProducts.fulfilled, (state, action) => {
        state.cartLoadStatus = loadStatus.fulfilled;

        if (action.payload.message !== 'Корзина пуста') {
          state.cartProducts = action.payload;

          state.totalPrice = action.payload.reduce(
            (all, cur) => all + cur.product.price * cur.quantity,
            0,
          );

          state.totalLength = action.payload.reduce(
            (all, cur) => all + cur.quantity, 0
          );
        } else {
          state.cartProducts = null;
        }
      })
      .addCase(getCartProducts.rejected, (state, action) => {
        state.cartLoadStatus = loadStatus.rejected;
        console.log('rejected: ', action);
      });

    builder
      .addCase(addToCart.pending, (state) => {
        state.cartLoadStatus = loadStatus.pending;
      })
      .addCase(addToCart.fulfilled, (state, action) => {
        state.cartLoadStatus = loadStatus.fulfilled;
        console.log('fulfilled: ', action);
      })
      .addCase(addToCart.rejected, (state, action) => {
        state.cartLoadStatus = loadStatus.rejected;
        console.log('rejected: ', action);
      });

    builder
      .addCase(updCartProductCount.pending, (state) => {
        state.cartLoadStatus = loadStatus.pending;
      })
      .addCase(updCartProductCount.fulfilled, (state, action) => {
        state.cartLoadStatus = loadStatus.fulfilled;
        console.log('fulfilled: ', action);
      })
      .addCase(updCartProductCount.rejected, (state, action) => {
        state.cartLoadStatus = loadStatus.rejected;
        console.log('rejected: ', action);
      });

    builder
      .addCase(removeCartProducts.pending, (state) => {
        state.cartLoadStatus = loadStatus.pending;
      })
      .addCase(removeCartProducts.fulfilled, (state, action) => {
        state.cartLoadStatus = loadStatus.fulfilled;

        console.log('fulfilled: ', action);
      })
      .addCase(removeCartProducts.rejected, (state, action) => {
        state.cartLoadStatus = loadStatus.rejected;
        console.log('rejected: ', action);
      });
  },
});

export const {
  setSelectedCartProducts,
  selectAllCartProducts,
  deselectAllCartProducts,
  setTotalLength,
  setAddToCart,
  setUpdQuantity,
  setCartGot,
  setVideoModal,
  setDelModal,
  setRemovedProduct,
  setAllSelected,
} = cartSlice.actions;
export const cartSel = (state) => state.cart;

export default cartSlice.reducer;
