const { disableScroll } = require("./disableScroll");
const { enableScroll } = require("./enableScroll");

export const onHide = (boolly, inMain = false) => {
  const toHide = document.querySelectorAll(".toHide");
  const main = document.querySelector('.main');
  if (!boolly) {
    disableScroll();

    toHide.forEach(elem => {
      elem.classList.add("z-9991");
    });
    if(inMain) {
      main.classList.add('z-9999');
    }
  } else {
    enableScroll();

    toHide.forEach(elem => {
      elem.classList.remove("z-9991");
    });

    if(inMain) {
      main.classList.remove('z-9999');
    }
  }
};
