import MyBtn from '@components/_UI/btns/MyBtn'
import cl from './Confirm.module.scss'
import { useDispatch } from 'react-redux'
import { cancelConfirm } from '@store/slices/confirm/confirmSlice'

const Confirm = ({title, subtitle, btns}) => {
  const dispatch = useDispatch();

  const onOverlay = (e) => {
    e.preventDefault();

    dispatch(cancelConfirm());
  }

  return (
    <div className={cl.overlay} onClick={onOverlay}>
      <div className={cl.modal} onClick={e => e.stopPropagation()}>
        {
          title && subtitle &&
          <div className={cl.info}>
            {title && <h4 className={cl.title}>{title}</h4>}
            {subtitle && <p className={cl.subtitle}>{subtitle}</p>}
          </div>
        }
        <div className={cl.btns}>
          {
            btns?.map(btn => (
              <MyBtn classNames={`${cl.btn} ${btn.cl}`} onClick={btn.onClick} key={btn.id}>
                {btn.text}
              </MyBtn>
            ))
          }
        </div>
      </div>
    </div>
  )
}

export default Confirm
