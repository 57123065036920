import Status from '@components/order/status/Status'
import cl from './Output.module.scss'

const Output = ({order}) => {


  return (
    <div className={cl.output}>
      <Status classNames={cl.status} status={order.status} />
      <p className={cl.seller}>Название магазина</p>
      <p className={cl.text}>Ожидайте звонка от продавца</p>
    </div>
  )
}

export default Output
