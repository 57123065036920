import React from 'react';
import { hydrate, render } from 'react-dom';
import { store } from '@store/store';
import { Provider } from 'react-redux';

import '@assets/styles/_vars.scss';
import '@assets/styles/_fonts.scss';
import '@assets/styles/_keyframes.scss';
import '@assets/styles/_settings.scss';
import '@assets/styles/_ui.scss';

import App from './App.js';
import { BrowserRouter } from 'react-router-dom';

const APP = (
  <Provider store={store}>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </Provider>
);

const rootElement = document.getElementById('root');
if (typeof window !== 'undefined') {
  if (rootElement.hasChildNodes()) {
    hydrate(APP, rootElement);
  } else {
    render(APP, rootElement);
  }
}
