const { createSlice } = require("@reduxjs/toolkit");

const initialState = {
  newMessages: 34,
  chats: [
    {
      id: 1,
      callerId: 1,
      name: "Медикс Фарм",
      type: "Продавец",
      dateTime: "10:49",
      isOnline: false,
      newsCount: 0,
      messages: [
        // {
        //   id: 1,
        //   authorId: 85,
        //   text: "Саламалэйкум Уалейкум, пойдет Как дела?",
        //   dateTime: "10:49",
        //   isOrder: true,
        // },
      ],
    },
    // {
    //   id: 2,
    //   callerId: 2,
    //   name: "Aiwwwi",
    //   type: "Продавец",
    //   dateTime: "10:49",
    //   isOnline: true,
    //   newsCount: 2,
    //   messages: [
    //     {
    //       id: 1,
    //       authorId: 1,
    //       text: "Саламалэйкум",
    //     },
    //     {
    //       id: 2,
    //       authorId: 1,
    //       text: "Как дела?",
    //     },
    //     {
    //       id: 3,
    //       authorId: 85,
    //       text: "Уалейкум, пойдет",
    //     },
    //   ],
    // },
    // {
    //   id: 3,
    //   callerId: 3,
    //   name: "BRSYS",
    //   type: "Пользователь",
    //   dateTime: "10:49",
    //   isOnline: false,
    //   newsCount: 0,
    //   messages: [
    //     {
    //       id: 1,
    //       authorId: 1,
    //       text: "Саламалэйкум",
    //     },
    //     {
    //       id: 2,
    //       authorId: 1,
    //       text: "Как дела?",
    //     },
    //     {
    //       id: 3,
    //       authorId: 2,
    //       text: "Уалейкум, пойдет",
    //     },
    //   ],
    // },
    // {
    //   id: 4,
    //   callerId: 4,
    //   name: "Лев",
    //   type: "Пользователь",
    //   dateTime: "10:49",
    //   isOnline: true,
    //   newsCount: 3,
    //   messages: []
    // },
    // {
    //   id: 5,
    //   callerId: 5,
    //   name: "Вова",
    //   type: "Продавец",
    //   dateTime: "10:49",
    //   isOnline: false,
    //   newsCount: 0,
    //   messages: [
    //     {
    //       id: 1,
    //       authorId: 1,
    //       text: "Саламалэйкум",
    //     },
    //     {
    //       id: 2,
    //       authorId: 1,
    //       text: "Как дела?",
    //     },
    //     {
    //       id: 3,
    //       authorId: 2,
    //       text: "Уалейкум, пойдет",
    //     },
    //   ],
    // },
  ],

  chatsSearchVal: "",
  chatsFieldVal: "",
  chatsLoadStatus: "idle",
};

const chatsSlice = createSlice({
  name: "chats",
  initialState,
  reducers: {
    setChatsSearchVal: (state, action) => {
      state.chatsSearchVal = action.payload;
    },

    setChatsFieldVal: (state, action) => {
      state.chatsFieldVal = action.payload;
    },

    clearChatsFieldVal: state => {
      state.chatsFieldVal = "";
    },

    addMessage: (state, action) => {
      const currentChat = state.chats.find(
        chat => Number(chat.id) === Number(action.payload.id)
      );

      if (currentChat) {
        const isMessageUnique = currentChat.messages.every(
          message => message.id !== action.payload.newMessage.id
        );
        if (isMessageUnique) {
          currentChat.messages.push(action.payload.newMessage);
        }
      } else {
        console.log("Chat not found");
      }
    },
  },
});

export const chatsSel = state => state.chats;
export const {
  setChatsSearchVal,
  setChatsFieldVal,
  clearChatsFieldVal,
  addMessage,
} = chatsSlice.actions;

export default chatsSlice.reducer;
