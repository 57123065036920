import Modal from './modal/Modal';
import { useDispatch, useSelector } from 'react-redux';
import { headerSel, setCatalogIsOpen } from '@store/slices/header/headerSlice';
import { disableScroll } from '@hooks/disableScroll';
import { enableScroll } from '@hooks/enableScroll';
import {
  catalogSel,
  getCatAll,
  setCatTree,
  setCurCatRoot,
  setCurCatTree,
} from '@store/slices/catalog/catalogSlice';
import { useEffect } from 'react';
import { clsx } from 'clsx';
import { useLocation } from 'react-router-dom';

const Catalog = () => {
  const dispatch = useDispatch();
  const { catalogIsOpen } = useSelector(headerSel);
  const { catRoots, curCatRoot, catTree, curCatTree } = useSelector(catalogSel);

  const location = useLocation();

  useEffect(() => {
    dispatch(getCatAll());
  }, [dispatch]);

  const toggleCatalog = () => {
    dispatch(setCatalogIsOpen(!catalogIsOpen));
    if (catalogIsOpen) {
      enableScroll();
    } else {
      disableScroll();
    }
  };

  return (
    <>
      <button
        className={clsx('bar__nav-link', {
          active: location.pathname.includes('catalog') || catalogIsOpen,
        })}
        onClick={toggleCatalog}
      >
        <span className="bar__nav-btn btn btn-bar">
          <svg
            className="ico-20 stroke"
            xmlns="http://www.w3.org/2000/svg"
            width="25"
            height="20"
            viewBox="0 0 25 20"
            fill="none"
          >
            <path
              d="M19.2702 16.3658L21.8743 19.0279M4.16602 3.05566H20.8327M4.16602 9.44455H8.33268M4.16602 15.8334H8.33268M11.4577 12.639C11.4577 13.7686 11.8967 14.852 12.6781 15.6507C13.4595 16.4495 14.5193 16.8983 15.6243 16.8983C16.7294 16.8983 17.7892 16.4495 18.5706 15.6507C19.352 14.852 19.791 13.7686 19.791 12.639C19.791 11.5094 19.352 10.426 18.5706 9.62725C17.7892 8.82848 16.7294 8.37974 15.6243 8.37974C14.5193 8.37974 13.4595 8.82848 12.6781 9.62725C11.8967 10.426 11.4577 11.5094 11.4577 12.639Z"
              strokeWidth="1.30208"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
          <span>Каталог</span>
        </span>
      </button>
      <Modal
      onCategory={toggleCatalog}
        catalogIsOpen={catalogIsOpen}
        setModalIsOpen={setCatalogIsOpen}
        catalogList={catRoots}
        curCatListItem={curCatRoot}
        setCatTree={setCatTree}
        setCurCatListItem={setCurCatRoot}
        catTree={catTree}
        curCatTree={curCatTree}
        setCurCatTree={setCurCatTree}
      />
    </>
  );
};

export default Catalog;
