import cl from './Images.module.scss';

const Images = ({ order }) => {
  return (
    <div className={cl.images}>
      {order?.order_items.length === 0 || order?.order_items === undefined ? (
        <></>
      ) : order?.order_items.length <= 3 ? (
        order?.order_items.map((item) => (
          <img
            className={`${cl.image} ${cl.item}`}
            src={item.product.image.path}
            alt={item.product.product_name}
            title={item.product.product_name}
            key={item.product.image.id}
          />
        ))
      ) : (
        <>
          <img
            className={`${cl.image} ${cl.item}`}
            src={order?.order_items[0].product.image.path}
            alt={order?.order_items[0].product.product_name}
            title={order?.order_items[0].product.product_name}
          />
          <img
            className={`${cl.image} ${cl.item}`}
            src={order?.order_items[1].product.image.path}
            alt={order?.order_items[1].product.product_name}
            title={order?.order_items[1].product.product_name}
          />
          <div className={`${cl.count} ${cl.item}`}>
            <span>+{order?.order_items.length - 2}</span>
          </div>
        </>
      )}
    </div>
  );
};

export default Images;
