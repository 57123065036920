import TelInput from '@components/telInput/TelInput';
import cl from './Auth.module.scss';
import MyForm from '@components/_UI/MyForm';
import MyBtn from '@components/_UI/btns/MyBtn';
import MyInput from '@components/_UI/fields/MyInput';
import { clsx } from 'clsx';

const Auth = ({
  cleanedNum,
  numValue,
  setNumValue,
  codeValue,
  setCodeValue,
  onSignUser,
  onSendCode,
  toReNum,
  onReSendNewCode,
  isNum,
}) => {
  return (
    <div className={cl.block}>
      {!isNum ? (
        <>
          <h3 className={`${cl.title} title title-modal`}>
            Введите номер телефона:
          </h3>
          <p className={cl.subtitle}>
            Мы отправим код сообщением. Отвечать на сообщение не нужно
          </p>
          <MyForm classNames={cl.form + ' gap-12'} onSubmit={onSignUser}>
            <TelInput telInput={numValue} setTelInput={setNumValue} />
            <MyBtn
              classNames={clsx(cl.btn + ' btn-accent w-full', {
                dis: cleanedNum.length !== 11,
              })}
              type="submit"
            >
              Продолжить
            </MyBtn>
          </MyForm>
        </>
      ) : (
        <>
          <h3 className={`${cl.title} title title-modal`}>Введите код:</h3>
          <p className={cl.subtitle}>
            Мы отправили код подтверждения на номер {numValue}.{' '}
            <span className="link-accent cursor-pointer" onClick={toReNum}>Изменить</span>
          </p>
          <MyForm classNames={cl.form + ' gap-12'} onSubmit={onSendCode}>
            <MyInput value={codeValue} setValue={setCodeValue} />
            <MyBtn
              classNames={clsx(cl.btn + ' btn-accent w-full', {
                dis: codeValue.length !== 4,
              })}
              type="submit"
            >
              Продолжить
            </MyBtn>
            <button className={`${cl.getNewCode} link-accent`} type={'button'} onClick={onReSendNewCode}>Получить новый код</button>
          </MyForm>
        </>
      )}
    </div>
  );
};

export default Auth;
