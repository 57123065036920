import { useEffect, useState } from 'react';
import MyBtn from '@components/_UI/btns/MyBtn';
import cl from './Reviews.module.scss';
import Review from './review/Review';
import { clsx } from 'clsx';
import { useDispatch, useSelector } from 'react-redux';
import {
  clearReviews,
  getProductReviews,
  productSel,
} from '@store/slices/product/productSlice';
import NotFound from '@components/notFound/NotFound';

const sorts = [
  {
    key: 'all',
    val: 'Все',
  },
  {
    key: 'new',
    val: 'Новые',
  },
  {
    key: 'inc',
    val: 'Положительные',
  },
  {
    key: 'dec',
    val: 'Отрицательные',
  },
];

const Reviews = ({ curTab, reviewsTab }) => {
  const dispatch = useDispatch();
  const { reviewsData, reviewsCurPage, reviewsLastPage, product } =
    useSelector(productSel);
  const [selected, setSelected] = useState(sorts[0]);

  useEffect(() => {
    if (curTab.key === reviewsTab.key) {
      dispatch(clearReviews());
      dispatch(
        getProductReviews({
          productId: product.id,
          perPage: 1,
          page: reviewsCurPage,
        }),
      );
    }
  }, [dispatch, curTab, product.id]);

  console.log(reviewsCurPage, reviewsLastPage);

  const onSort = (item) => {
    setSelected(item);
  };

  const onMoreReviews = () => {
    if (reviewsCurPage < reviewsLastPage) {
      dispatch(
        getProductReviews({
          productId: product.id,
          perPage: 1,
          page: reviewsCurPage + 1,
        }),
      );
    }
  };

  return (
    <div className={`${cl.content}`}>
      {reviewsData && reviewsData.length > 0 ? (
        <>
          {/* <div className={`${cl.sort_outer}`}>
            {sorts.map((item) => (
              <MyBtn
                classNames={`${clsx(cl.sort_btn + ' btn-border', {
                  active: item.key === selected.key,
                })} `}
                key={item.key}
                onClick={() => onSort(item)}
              >
                {item.val}
              </MyBtn>
            ))}
          </div> */}
          <div className={cl.reviews}>
            {reviewsData.map((review) => (
              <Review review={review} key={review.id} />
            ))}
          </div>

          {reviewsCurPage < reviewsLastPage && (
            <MyBtn classNames={cl.more + ' btn-border'} onClick={onMoreReviews}>
              Показать еще
            </MyBtn>
          )}
        </>
      ) : (
        <NotFound
          title={'Нет отзывов'}
          subtitle={'У данного товара на данный момент нет отзывов'}
        />
      )}
    </div>
  );
};

export default Reviews;
