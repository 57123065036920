import { useState } from "react";
import MyBtn from "@components/_UI/btns/MyBtn";
import cl from "./Reviews.module.scss";
import Review from "./review/Review";
import {clsx} from "clsx";


const sorts = [
  {
    key: "all",
    val: "Все",
  },
  {
    key: "new",
    val: "Новые",
  },
  {
    key: "inc",
    val: "Положительные",
  },
  {
    key: "dec",
    val: "Отрицательные",
  },
];

const reviews = [1, 2, 3];

const Reviews = () => {
  const [selected, setSelected] = useState(sorts[0]);

  const onSort = item => {
    setSelected(item);
  };

  return (
    <div className={`${cl.content}`}>
      <div className={`${cl.sort_outer}`}>
        {sorts.map(item => (
          <MyBtn
            classNames={`${clsx(cl.sort_btn + " btn-border", {
              'active': item.key === selected.key,
            })} `}
            key={item.key}
            onClick={() => onSort(item)}
          >
            {item.val}
          </MyBtn>
        ))}
      </div>

      <div className={cl.reviews}>
        {reviews.map(review => (
          <Review {...review} key={review} />
        ))}
      </div>

      <MyBtn classNames={cl.more + " btn-border"}>Показать еще</MyBtn>
    </div>
  );
};

export default Reviews;
