import { useRef } from 'react';

import Big from './big/Big';
import Small from './small/Small';

import cl from './Slider.module.scss';
import Modal from './modal/Modal';

import useWindowWidth from '@hooks/windowWidth';

const Slider = ({
  classNames,
  smallSliderCl,
  bigSliderCl,
  modalSliderCl,
  slides,
}) => {
  const slider1 = useRef(null);
  const slider2 = useRef(null);
  const slider3 = useRef(null);

  const window = useWindowWidth();

  return (
    <div className={`${classNames} ${cl.sliders}`}>
      {window >= 1025 && (
        <Small
          slideCl={smallSliderCl}
          slides={slides}
          slider2={slider2}
          slider3={slider3}
          ref={slider1}
        />
      )}
      <Big
        sliderCl={bigSliderCl}
        slides={slides}
        slider1={slider1}
        slider3={slider3}
        ref={slider2}
      />
      <Modal
        sliderCl={modalSliderCl}
        slides={slides}
        slider1={slider1}
        slider2={slider2}
        ref={slider3}
      />
    </div>
  );
};

export default Slider;
