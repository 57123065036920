import cl from "./GrayWar.module.scss";

const GrayWar = ({ classNames, ico, text }) => {
  return (
    <div className={`${classNames} ${cl.alert} +  btn`}>
      {ico ? (
        ico
      ) : (
        <svg
          className={`${cl.ico} ico-30 stroke`}
          xmlns="http://www.w3.org/2000/svg"
          width="30"
          height="30"
          viewBox="0 0 30 30"
          fill="none"
        >
          <path
            d="M15 2.5C8.09644 2.5 2.5 8.09644 2.5 15C2.5 21.9036 8.09644 27.5 15 27.5C21.9036 27.5 27.5 21.9036 27.5 15C27.5 8.09644 21.9036 2.5 15 2.5Z"
            stroke="#969696"
            strokeWidth="1.875"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M15 20V15"
            stroke="#969696"
            strokeWidth="1.875"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M15 10H14.9875"
            stroke="#969696"
            strokeWidth="1.875"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      )}
      <span className={cl.text}>{text}</span>
    </div>
  );
};

export default GrayWar;
