import { loadStatus } from '@store/loadStatus';
import axios from 'axios';

const { createSlice, createAsyncThunk } = require('@reduxjs/toolkit');

export const getInfo = createAsyncThunk(
  'orders/getInfo',
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await axios.get(
        `${process.env.REACT_APP_API_URL}v1/page/3`,
      );

      console.log(data);
      return data;
    } catch (err) {
      console.log(`ошибка получения заказов: ${err}`);
      return rejectWithValue(err.message);
    }
  },
);

const initialState = {
  infoLoadStatus: 'idle',
  info: null,
  list: [
    {
      "id": 1,
      "title": "Как зарегистрироваться на маркетплейсе?",
      "text": "Мы всегда рады новым пользователям!<br/> Для регистрации, пожалуйста, перейдите в раздел «Войти». Укажите свой номер телефона, после чего на него будет отправлено сообщение с кодом. Введите этот код и нажмите кнопку регистрации, чтобы получить доступ к своему личному кабинету, который вы можете настроить по своему усмотрению."
    },
    {
      "id": 2,
      "title": "Как сделать заказ?",
      "text": "Сделать заказ очень просто! Найдите нужный вам товар, добавьте его в корзину и оформите заказ. Наша команда оперативно обработает ваш заказ и свяжется с вами для подтверждения деталей. После этого ваш заказ будет отправлен в кратчайшие сроки. Благодарим за выбор Qlixmed!"
    },
    {
      "id": 3,
      "title": "Какие условия доставки медицинского оборудования?",
      "text": "Мы предлагаем удобную и надежную систему доставки медицинского оборудования на нашем маркетплейсе. Сотрудничая с проверенными партнерами, мы гарантируем быструю и безопасную доставку товаров прямо к вам. Так же мы предлагаем различные варианты доставки, включая курьерскую доставку, почтовую службу и самовывоз. Подробности об условиях, сроках и стоимости доставки доступны на странице каждого товара или через нашу службу поддержки. Мы прилагаем все усилия, чтобы ваш заказ был доставлен вовремя и в идеальном состоянии."
    },
    {
      "id": 4,
      "title": "Как стать партнёром Qlixmed?",
      "text": "Для того чтобы начать сотрудничество, просто заполните форму на нашем сайте, после чего наш менеджер свяжется с вами для обсуждения всех деталей. Мы очень ценим каждого партнера и нацелены на долгосрочное и взаимовыгодное сотрудничество. Давайте вместе развивать бизнес и делать мир здоровее!"
    },
    {
      "id": 5,
      "title": "Как связаться с продавцом товара, который меня интересует?",
      "text": "На нашем маркетплейсе вы можете легко связаться с пользователями, заинтересованными в вашем продукте. Просто зайдите на страницу продукта, которым вы заинтересованы, и воспользуйтесь кнопкой \"Связаться с продавцом\". Вы сможете отправить сообщение напрямую продавцу, задать вопросы, уточнить детали и обсудить условия покупки. Мы ценим Ваше внимание к нашему маркетплейсу и стремимся обеспечить удобный и прозрачный процесс взаимодействия между покупателями и продавцами."
    },
    {
      "id": 6,
      "title": "Какие виды медицинского оборудования доступны на нашем маркетплейсе?",
      "text": "На нашем маркетплейсе доступны различные виды медицинского оборудования, включая, но не ограничиваясь: - медицинские приборы для диагностики и измерений,<br/> - оборудование для рентгенологии и ультразвуковых исследований,<br/> - хирургическое оборудование и инструменты,<br/> - медицинские мониторы и аппараты жизнеобеспечения,<br/> - медицинские мебель и аксессуары,<br/> - инвентарь для стерилизации и дезинфекции,<br/> - амбулаторное оборудование,<br/> - специализированная одежда и обувь,<br/> - оборудование для ветеринарной клиники.<br/> Мы постоянно расширяем наш ассортимент, чтобы предложить нашим клиентам самые инновационные и качественные решения в области медицинского оборудования. Вы можете найти подробную информацию о наличии и характеристиках каждого продукта на нашем маркетплейсе."
    },
    {
      "id": 7,
      "title": "Какие бренды медицинского оборудования доступны на нашем маркетплейсе?",
      "text": "На нашем маркетплейсе медицинского оборудования представлены все бренды, официально зарегистрированные в Казахстане. Мы стремимся предложить широкий ассортимент продукции от надежных и проверенных производителей, чтобы обеспечить нашим клиентам доступ к качественному и инновационному медицинскому оборудованию."
    },
    {
      "id": 8,
      "title": "Какие сроки и условия гарантии на медицинское оборудование?",
      "text": "Если товар доступен на складе, он будет отправлен в течение 2-4 рабочих дней. Товары отсутствующие на складе и спец. заказы обсуждаются индивидуально. В случае доставки товара в регионы время доставки зависит от выбранного метода отправки и особенностей работы транспортной компании."
    },
    {
      "id": 9,
      "title": "Как происходит оплата заказа медицинского оборудования?",
      "text": "У нас доступны различные способы оплаты, включая банковские переводы, кредитование и рассрочка, а также деление на доли. После размещения заказа наш менеджер свяжется с вами для уточнения деталей оплаты. Мы обеспечиваем безопасность вашей информации и предлагаем прозрачные условия оплаты. Если у вас возникли вопросы или вам нужна помощь с оплатой, наши специалисты всегда готовы помочь вам."
    },
    {
      "id": 10,
      "title": "Есть ли возможность вернуть или обменять медицинское оборудование?",
      "text": "На нашем маркетплейсе предусмотрена возможность возврата или обмена медицинского оборудования в соответствии с нашей политикой возврата. Если Вы обнаружили какие-либо дефекты или недостатки в продукте, пожалуйста, свяжитесь с нашей службой поддержки клиентов для получения дальнейших инструкций. Мы стремимся обеспечить удовлетворение наших клиентов и решение любых возникающих проблем."
    },
    {
      "id": 11,
      "title": "Как я могу получить консультацию по выбору медицинского оборудования?",
      "text": "Наши квалифицированные специалисты готовы помочь вам выбрать оптимальное решение, учитывая ваши потребности и требования. Чтобы получить консультацию, просто свяжитесь с нами по указанным контактным данным, и наши эксперты окажут вам помощь в выборе медицинского оборудования, ответив на все ваши вопросы."
    },
    {
      "id": 12,
      "title": "Можно ли у Вас арендовать оборудование?",
      "text": "Мы специализируемся исключительно на поставке нового оборудования."
    },
    {
      "id": 13,
      "title": "Как узнать цену на оборудование, и почему не все цены указаны на сайте?",
      "text": "Главной причиной отсутствия цен на определенное оборудование является нестабильность современного рынка. Большинство товаров производится за пределами страны, и в связи с разнообразием ассортимента любое изменение валютного курса существенно влияет на стоимость товаров."
    },
    {
      "id": 14,
      "title": "Работаете ли Вы с другими странами СНГ?",
      "text": "К сожалению, наши услуги доступны только на территории Казахстана."
    }
  ]
  ,
  curItem: null,
};

const faqSlice = createSlice({
  name: 'faq',
  initialState,
  reducers: {
    setCurItem: (state, action) => {
      state.curItem = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getInfo.pending, (state) => {
        state.infoLoadStatus = loadStatus.pending;
      })
      .addCase(getInfo.fulfilled, (state, action) => {
        state.infoLoadStatus = loadStatus.fulfilled;
        console.log(`fulfilled: `, action);
        state.info = action.payload;
      })
      .addCase(getInfo.rejected, (state, action) => {
        state.infoLoadStatus = loadStatus.rejected;
        console.log(`rejected: `, action);
        state.isOrdered = false;
      });
  },
});

export const { setCurItem } = faqSlice.actions;
export const faqSel = (state) => state.faq;

export default faqSlice.reducer;
