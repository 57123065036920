import axios from 'axios';
import { loadStatus } from 'src/store/loadStatus';

const { createSlice, createAsyncThunk } = require('@reduxjs/toolkit');

export const getProducts = createAsyncThunk(
  'products/getProducts',
  async () => {
    try {
      const { data } = await axios.get(
        `${process.env.REACT_APP_API_URL}products`,
      );
      return data;
    } catch (err) {
      console.log(err);
    }
  },
);

export const getPopularProducts = createAsyncThunk(
  'products/getPopularProducts',
  async (_, {rejectWithValue}) => {
    try {
      const { data } = await axios.get(
        `${process.env.REACT_APP_API_URL}v1/product/popular?perPage=30&page=1`,
      );
      return data;
    } catch (err) {
      console.log(err);
      return rejectWithValue(err.message);
    }
  },
);

const initialState = {
  products: [],
  popularProducts: [],
  productsLoadStatus: 'idle',
};

const productsSlice = createSlice({
  name: 'products',
  initialState,

  extraReducers: (builder) => {
    builder
      .addCase(getProducts.pending, (state) => {
        state.productsLoadStatus = loadStatus.pending;
        state.products = [];
      })
      .addCase(getProducts.fulfilled, (state, action) => {
        state.productsLoadStatus = loadStatus.fulfilled;
        state.products = action.payload;
      })
      .addCase(getProducts.rejected, (state) => {
        state.productsLoadStatus = loadStatus.rejected;
        state.products = [];
      });

    builder
      .addCase(getPopularProducts.pending, (state) => {
        state.productsLoadStatus = loadStatus.pending;
      })
      .addCase(getPopularProducts.fulfilled, (state, action) => {
        state.productsLoadStatus = loadStatus.fulfilled;
        state.popularProducts = action.payload.data;
      })
      .addCase(getPopularProducts.rejected, (state, action) => {
        state.productsLoadStatus = loadStatus.rejected;
        state.popularProducts = [];
        console.log('rejected: ', action);
      });
  },
});

// export const { } = productsSlice.actions;
export const productsSel = (state) => state.products;

export default productsSlice.reducer;
