import MyBtn from '@components/_UI/btns/MyBtn';
import Modal from './modal/Modal';
import { useDispatch, useSelector } from 'react-redux';
import { headerSel, setCatalogIsOpen } from '@store/slices/header/headerSlice';
import { disableScroll } from '@hooks/disableScroll';
import { enableScroll } from '@hooks/enableScroll';
import { catalogSel, getCatAll, setCatTree, setCurCatRoot, setCurCatTree } from '@store/slices/catalog/catalogSlice';
import { useEffect } from 'react';

const Catalog = () => {
  const dispatch = useDispatch();
  const { catalogIsOpen } = useSelector(headerSel);
  const {catRoots, curCatRoot, catTree, curCatTree} = useSelector(catalogSel);

  useEffect(() => {
    dispatch(getCatAll());
  },[dispatch]);

  const toggleCatalog = () => {
    dispatch(setCatalogIsOpen(!catalogIsOpen));
    if (catalogIsOpen) {
      enableScroll();
    } else {
      disableScroll();
    }
  };

  return (
    <>
      <MyBtn classNames={'header__catalog btn-accent'} onClick={toggleCatalog}>
        {!catalogIsOpen ? (
          <svg
            className="ico-20 stroke"
            xmlns="http://www.w3.org/2000/svg"
            width="23"
            height="12"
            viewBox="0 0 23 12"
            fill="none"
          >
            <path d="M5 1H23" stroke="currentColor" strokeWidth="1.5" />
            <path d="M5 6H23" stroke="currentColor" strokeWidth="1.5" />
            <path d="M5 11H16" stroke="currentColor" strokeWidth="1.5" />
            <circle cx="1" cy="1" r="1" fill="currentColor" />
            <circle cx="1" cy="6" r="1" fill="currentColor" />
            <circle cx="1" cy="11" r="1" fill="currentColor" />
          </svg>
        ) : (
          <svg
            className="ico-20 stroke"
            xmlns="http://www.w3.org/2000/svg"
            width="19"
            height="19"
            viewBox="0 0 19 19"
            fill="none"
          >
            <path
              d="M14.25 4.75L4.75 14.25"
              stroke="currentColor"
              strokeWidth="1.33333"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M4.75 4.75L14.25 14.25"
              stroke="currentColor"
              strokeWidth="1.33333"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        )}
        <span>Каталог</span>
      </MyBtn>
      <Modal
        catalogIsOpen={catalogIsOpen}
        setModalIsOpen={setCatalogIsOpen}
        catalogList={catRoots}
        curCatListItem={curCatRoot}
        setCatTree={setCatTree}
        setCurCatListItem={setCurCatRoot}
        catTree={catTree}
        curCatTree={curCatTree}
        setCurCatTree={setCurCatTree}
      />
    </>
  );
};

export default Catalog;
