import React from 'react';
import img from '@assets/img/default-avatar.png';

import cl from './Head.module.scss';
import MyBtn from '@components/_UI/btns/MyBtn';
import { useSelector } from 'react-redux';
import { signSel } from '@store/slices/sign/signSlice';

const Head = () => {
  const { user } = useSelector(signSel);

  console.log(user);
  return (
    <div className={cl.head}>
      <div className={cl.top}>
        <img
          className={cl.img}
          src={user.avatar ? user.avatar : img}
          alt="img"
        />
        <div className={cl.body}>
          <h3 className={cl.name}>{user.name}</h3>
          <ul className={cl.list}>
            <li className={cl.list_item}>{user.email}</li>
          </ul>
        </div>
      </div>
      <div className={cl.bot}>
        <p className={cl.status}>Статус: Пользователь</p>
        <MyBtn classNames={`${cl.btn} btn-accent`}>Стать продавцом</MyBtn>
      </div>
    </div>
  );
};

export default Head;
