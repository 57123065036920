import React from 'react';
import './SignPage.scss';
import MyPage from '@components/_UI/MyPage';
import SignIn from './in/SignIn';
import SignUp from './up/SignUp';
import SignNum from './num/SignNum';
import { useSelector } from 'react-redux';
import { signSel } from 'src/store/slices/sign/signSlice';

const SignPage = () => {
  const { isNum, isIn, isUp } = useSelector(signSel);

  return (
    <>
      <MyPage>
        <section className="sign">
          <div className="container">
            <div className="sign__inner">
              {!isNum && <SignNum />}
              {isIn && <SignIn />}
              {isUp && <SignUp />}
            </div>
          </div>
        </section>
      </MyPage>
    </>
  );
};

export default SignPage;
