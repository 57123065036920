import { useSelector } from 'react-redux';
import Reviews from './reviews/Reviews';
import { sellerSel, setCurTab } from '@store/slices/seller/sellerSlice';
import MyTabs from '@components/_UI/tabs/MyTabs';
import Products from './products/Products';

const Tabs = ({ outerCl }) => {
  const { tabs, curTab } = useSelector(sellerSel);

  return (
    <MyTabs
      tabs={tabs}
      curTab={curTab}
      setCurTab={setCurTab}
      outerCl={outerCl}
      id="sellerTabs"
    >
      {curTab.key === tabs[0].key ? (
        <Products />
      ) : (
        curTab.key === tabs[1].key && <Reviews />
      )}
    </MyTabs>
  );
};

export default Tabs;
