import HomePageCategories from './categories/HomePageCategories';
import HomePagePopular from './popular/HomePagePopular';
import MyPage from '@components/_UI/MyPage';
import Footer from '@components/footer/Footer';
import Header from '@components/header/Header';
import HomePageHero from './hero/HomePageHero';

const HomePage = () => {
  return (
    <>
      <Header />
      <MyPage
        hiddenTitle={'QlixMed'}
        metaTitle="Qlixmed"
        metaDescr="Главная Qlixmed"
        metaKeywords="qlixmed"
      >
        <HomePageHero />
        <HomePageCategories />
        <HomePagePopular />
        {/* <HomePageBest /> */}
      </MyPage>
      <Footer />
    </>
  );
};

export default HomePage;
