import MyModal from '@components/_UI/modal/MyModal';
import cl from './PerPage.module.scss';
import { useDispatch, useSelector } from 'react-redux';
import {
  catalogSel,
  getFilteredProducts,
  setPerPageModal,
  setProductsPerPage,
} from '@store/slices/catalog/catalogSlice';
import { enableScroll } from '@hooks/enableScroll';
import { disableScroll } from '@hooks/disableScroll';

const PerPage = () => {
  const dispatch = useDispatch();
  const {
    perPageModal,
    productsPerPage,
    curCatTree,
    selectedSort,
    perPageItems,
    curPage,
  } = useSelector(catalogSel);

  const onBack = () => {
    dispatch(setPerPageModal(false));
  };

  const onPerPage = () => {
    dispatch(setPerPageModal(!perPageModal));
    if (perPageModal) {
      enableScroll();
    } else {
      disableScroll();
    }
  };

  const onPerPageItem = (option) => {
    dispatch(setProductsPerPage(option));
    dispatch(
      getFilteredProducts({
        categoryId: curCatTree.id,
        sortBy: selectedSort.value,
        perPage: option.value,
        curPage: curPage,
      }),
    );
    onPerPage();
  };

  return (
    <>
      <button className="catalog__head-filter-link" onClick={onPerPage}>
        <span className="catalog__head-filter-link-title">Показывать по:</span>
        <span className="catalog__head-filter-link-subtitle">
          {productsPerPage.label} товаров
        </span>
      </button>
      <MyModal
        overlayCl={cl.overlay}
        modalCl={cl.modal}
        innerCl={cl.inner}
        closeIsNeed={false}
        modalIsOpen={perPageModal}
        setModalIsOpen={setPerPageModal}
      >
        <div className={cl.head}>
          <button
            className={`${cl.close} link link-accent`}
            onClick={onPerPage}
          >
            <svg
              className="ico-24 stroke"
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
            >
              <path
                d="M15 6L9 12L15 18"
                stroke="#337BF9"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </button>
          <h4 className={cl.title}>Показывать по:</h4>
        </div>
        <div className={cl.content}>
          <ul className={cl.list}>
            {perPageItems?.map((item) => (
              <li className={cl.item} key={item.value}>
                <button
                  className={cl.item__btn}
                  onClick={() => onPerPageItem(item)}
                >
                  {item.label}
                </button>
              </li>
            ))}
          </ul>
        </div>
      </MyModal>
    </>
  );
};

export default PerPage;
