

import { Link } from "react-router-dom";
import cl from "./Head.module.scss";
import { formatDate } from "@hooks/formatDate";

const Head = ({order}) => {
  const updDate = formatDate(order?.created_at, true, true, true, false, false)
  return (
    <div className={cl.head}>
      <Link className={`${cl.goBack} link link-accent`} to={'/orders'}>К списку заказов</Link>
      <h1 className={`${cl.title} title title-section`}>Заказ № {order?.id}</h1>
      <p className={cl.date}>от {updDate}</p>
    </div>
  );
};

export default Head;
