import { clsx } from 'clsx';
import cl from './Status.module.scss'

const Status = ({classNames, status}) => {
  return (
    <p className={`${cl.status_outer} ${classNames}`}>
      <span>Cтатус заказа:</span>
      <span className={clsx(cl.status, { [cl.pending]: status === 1, [cl.full]: status === 2, [cl.rej]: status === 3 })}>
        {
          status === 1 ? 'В обработке' : status === 2 ? 'Прибыл' : 'Доставляется'
        }
      </span>
    </p>
  );
};

export default Status;
