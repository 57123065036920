import { clsx } from 'clsx';
import cl from './MyTabs.module.scss';
import { useDispatch } from 'react-redux';

const MyTabs = ({
  outerCl,
  btnOuterCl,
  btnCl,
  tabs,
  curTab,
  setCurTab,
  id,
  children,
}) => {
  const dispatch = useDispatch();

  const onSelected = (btn) => {
    dispatch(setCurTab(btn));
  };

  return (
    <div className={cl.tab + ` ${outerCl}`} id={id}>
      <div className={cl.btn_outer + ` ${btnOuterCl}`}>
        {tabs.map((btn) => (
          <button
            className={clsx(cl.btn + ` ${btnCl}`, {
              [cl.btn_active]: btn.key === curTab?.key,
            })}
            onClick={() => onSelected(btn)}
            key={btn.key}
          >
            {btn.val}
          </button>
        ))}
      </div>

      {children}
    </div>
  );
};

export default MyTabs;
