import MyInput from '@components/_UI/fields/MyInput';
import { useMask } from '@react-input/mask';

const TelInput = ({ classNames, telInput, setTelInput }) => {
  const telInputRef = useMask({
    mask: '+_ (___) ___-__-__',
    replacement: { _: /\d/ },
  });

  return (
    <MyInput
      ref={telInputRef}
      classNames={classNames}
      title={'Номер телефона'}
      placeholder="+7 (777) 777-77-77"
      required={true}
      value={telInput}
      setValue={setTelInput}
    />
  );
};

export default TelInput;
