import React from 'react';
import cl from './Blockquote.module.scss'

const Blockquote = () => {
  return (
    <div className={cl.block}>
      <span className={cl.title}>Qlixmed</span>
      <span className={cl.subtitle}>Маркетплейс медицинских товаров №1 в Казахстане</span>
    </div>
  );
};

export default Blockquote;
