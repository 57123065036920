import cl from './Misson.module.scss';
import { Link } from 'react-router-dom';

const Mission = () => {
  return (
    <div className={cl.block}>
      <p className={cl.text + ` ${cl.text_first}`}>
        Наша миссия — сделать медицинскую помощь более эффективной и доступной.
        Мы поддерживаем вас в стремлении к здоровью и благополучию.
      </p>
      <p className={cl.text + ` ${cl.text_sec}`}>
        С уважением,
        <br />
        Команда Qlixmed
      </p>
      <Link to="tel:+78000707072" className={`${cl.btn} btn btn-accent`}>
        Задать вопрос
      </Link>
    </div>
  );
};

export default Mission;
