import { loadStatus } from '@store/loadStatus';
import axios from 'axios';
const { createSlice, createAsyncThunk } = require('@reduxjs/toolkit');

export const getProduct = createAsyncThunk(
  'product/getProduct',
  async ({ productId }, { rejectWithValue }) => {
    try {
      const { data } = await axios.get(
        `${process.env.REACT_APP_API_URL}v1/product/detailed/${productId}`,
      );

      return data;
    } catch (err) {
      console.log(`ошибка создания товара: ${err}`);
      return rejectWithValue(err.message);
    }
  },
);

export const createProduct = createAsyncThunk(
  'product/createProduct',
  async ({ token, product }) => {
    try {
      const config = {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${token}`,
        },
      };

      const { data } = await axios.post(
        `${process.env.REACT_APP_API_URL}addNewProduct`,
        product,
        config,
      );

      return data;
    } catch (err) {
      console.log(`ошибка создания товара: ${err}`);
    }
  },
);

export const getSimilarProducts = createAsyncThunk(
  'products/getPopularProducts',
  async ({ productId }, { rejectWithValue }) => {
    try {
      const { data } = await axios.get(
        `${process.env.REACT_APP_API_URL}v1/product/similar/${productId}?perPage=14&page=1`,
      );
      return data;
    } catch (err) {
      console.log(err);
      return rejectWithValue(err.message);
    }
  },
);

export const getProductReviews = createAsyncThunk(
  'products/getProductReviews',
  async ({ productId, perPage, page }, { rejectWithValue }) => {
    try {
      const { data } = await axios.get(
        `${process.env.REACT_APP_API_URL}v1/review/${productId}?perPage=${perPage}&page=${page}`,
      );
      return data;
    } catch (err) {
      console.log(err);
      return rejectWithValue(err.message);
    }
  },
);

/////////////////////////////////////////////////////////////////////////////////////////////////////
/////////////////////////////////////////// initial state ///////////////////////////////////////////
const initialState = {
  productLoadStatus: 'idle',
  reviewsLoadStatus: 'idle',
  modalIsOpen: false,
  product: null,
  similarProducts: null,

  reviewsData: [],
  reviewsCurPage: 1,
  reviewsLastPage: null,

  tabs: [
    { key: 'descr', val: 'Характеристики и описание' },
    { key: 'reviews', val: 'Отзывы' },
    // { key: 'sellers', val: 'Выбор продавца' },
  ],
  curTab: { key: 'descr', val: 'Характеристики и описание' },
};

const productSlice = createSlice({
  name: 'product',
  initialState,
  reducers: {
    setModalIsOpen: (state, action) => {
      state.modalIsOpen = action.payload;
    },

    setProduct: (state, action) => {
      state.product = action.payload;
    },

    setCurTab: (state, action) => {
      state.curTab = action.payload;
    },
    
    clearReviews: (state) => {
      state.reviewsLoadStatus = "idle";
      state.reviewsData = [];
      state.reviewsCurPage = 1;
      state.reviewsLastPage = null;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getProduct.pending, (state) => {
        state.productLoadStatus = loadStatus.pending;
      })
      .addCase(getProduct.fulfilled, (state, action) => {
        state.productLoadStatus = loadStatus.fulfilled;
        state.product = action.payload;
      })
      .addCase(getProduct.rejected, (state, action) => {
        state.productLoadStatus = loadStatus.rejected;
        console.log(`rejected: `, action);
      });
    builder
      .addCase(createProduct.pending, (state) => {
        state.productLoadStatus = loadStatus.pending;
      })
      .addCase(createProduct.fulfilled, (state, action) => {
        state.productLoadStatus = loadStatus.fulfilled;
        console.log('товар создан: ', action.payload.product);
      })
      .addCase(createProduct.rejected, (state) => {
        state.productLoadStatus = loadStatus.rejected;
      });
    builder
      .addCase(getSimilarProducts.pending, (state) => {
        state.productLoadStatus = loadStatus.pending;
      })
      .addCase(getSimilarProducts.fulfilled, (state, action) => {
        state.productLoadStatus = loadStatus.fulfilled;
        state.similarProducts = action.payload.data;
      })
      .addCase(getSimilarProducts.rejected, (state, action) => {
        state.productLoadStatus = loadStatus.rejected;
        console.log('rejected: ', action);
      });
    builder
      .addCase(getProductReviews.pending, (state) => {
        state.reviewsLoadStatus = loadStatus.pending;
      })
      .addCase(getProductReviews.fulfilled, (state, action) => {
        state.reviewsLoadStatus = loadStatus.fulfilled;
        state.reviewsData = [...state.reviewsData, ...action.payload.data];
        state.reviewsCurPage = action.payload.meta.current_page;
        state.reviewsLastPage = action.payload.meta.last_page;
      })
      .addCase(getProductReviews.rejected, (state, action) => {
        state.reviewsLoadStatus = loadStatus.rejected;
        console.log('rejected: ', action);
      });
  },
});

export const { setModalIsOpen, setProduct, setCurTab, clearReviews } = productSlice.actions;
export const productSel = (state) => state.product;

export default productSlice.reducer;
