import cl from './Advantages.module.scss';

const Advantages = () => {
  return (
    <div className={cl.wrapper}>
      <h3 className={cl.title}>Наши преимущества</h3>
      <div className={cl.block_outer}>
        <div className={cl.block}>
          <h5 className={cl.block_title}>Обширный ассортимент продукции</h5>
          <p className={cl.block_text}>
            наш каталог насчитывает более 15,000 наименований от ведущих мировых
            производителей, обеспечивая полное покрытие потребностей рынка и
            предоставляя многообразие выбора для удовлетворения любых
            требований.
          </p>
        </div>
        <div className={cl.block}>
          <h5 className={cl.block_title}>
            Непрерывная готовность к выполнению заказов
          </h5>
          <p className={cl.block_text}>
            мы гарантируем оперативность обработки и выполнения заказов,
            поддерживая высокий уровень сервиса.
          </p>
        </div>
        <div className={cl.block}>
          <h5 className={cl.block_title}>
            Прямые договоренности с производителями
          </h5>
          <p className={cl.block_text}>
            благодаря прямым контрактам с производителями и дистрибьюторами, мы
            предлагаем выгодные цены на продукцию, обеспечивая при этом высокие
            стандарты качества.
          </p>
        </div>
        <div className={cl.block}>
          <h5 className={cl.block_title}>Высокоэффективная логистика</h5>
          <p className={cl.block_text}>
            владея развитой логистической инфраструктурой, мы обещаем быструю и
            надежную доставку по всем регионам, гарантируя точность и
            своевременность поставок.
          </p>
        </div>
        <div className={cl.block}>
          <h5 className={cl.block_title}>Компетентная поддержка</h5>
          <p className={cl.block_text}>
            наша команда состоит из опытных специалистов, предлагающих
            профессиональную поддержку и консультации на всех этапах
            сотрудничества.
          </p>
        </div>
      </div>
    </div>
  );
};

export default Advantages;
