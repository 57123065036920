const { createSlice } = require('@reduxjs/toolkit');

const initialState = {
  accFields: {
    fioField: '',
    telField: '',
    cityField: '',
    addressField: '',
    emailField: '',
  },
};

//tokyo@night.jp

const profileSlice = createSlice({
  name: 'profile',
  initialState,
  reducers: {
    setAccFields: (state, action) => {
      state.accFields = action.payload;
    },
    setFioField: (state, action) => {
      state.accFields.fioField = action.payload;
    },
    setCityField: (state, action) => {
      state.accFields.cityField = action.payload;
    },
    setAddressField: (state, action) => {
      state.accFields.addressField = action.payload;
    },
    setTelField: (state, action) => {
      state.accFields.telField = `+${action.payload}`;
    },
    setEmailField: (state, action) => {
      state.accFields.emailField = action.payload;
    },
  },
});

export const profileSel = (state) => state.profile;
export const {
  setAccFields,
  setFioField,
  setTelField,
  setEmailField,
  setCityField,
  setAddressField,
} = profileSlice.actions;

export default profileSlice.reducer;
