import React from 'react';
import { Link, NavLink, useLocation } from 'react-router-dom';
import { clsx } from 'clsx';

import './Bar.scss';
import { useDispatch, useSelector } from 'react-redux';
import { barSel } from 'src/store/slices/bar/barSlice';

import useWindowWidth from '@hooks/windowWidth';
import { signSel } from '@store/slices/sign/signSlice';
import Catalog from './catalog/Catalog';
import { onHide } from '@hooks/toHide';
import {
  setBurgerIsOpen,
  setSearchDebVal,
  setSearchIsOpen,
  setSearchResults,
  setSearchVal,
} from '@store/slices/header/headerSlice';
import { cartSel } from '@store/slices/cart/cartSlice';

const Bar = () => {
  const windowWidth = useWindowWidth();

  const dispatch = useDispatch();
  const { isSign } = useSelector(signSel);
  const { catalogIsActive } = useSelector(barSel);
  const { totalLength } = useSelector(cartSel);
  const location = useLocation();

  // const onCatalog = () => {
  //   dispatch(setCatalogIsActive(!catalogIsActive));
  // };

  const defLinkCl = 'bar__nav-link';
  const activeLinkCl = 'bar__nav-link active';

  const onRouting = () => {
    dispatch(setSearchVal(''));
    dispatch(setSearchDebVal(''));
    dispatch(setSearchResults([]));
    dispatch(setSearchIsOpen(false));
    dispatch(setBurgerIsOpen(false));
    onHide(true);
  };

  return (
    <div className="bar toHide fixed-block" onClick={onRouting}>
      {windowWidth < 1025 ? (
        <div className="bar__inner">
          <nav className="bar__nav">
            <ul className="bar__nav-list">
              <li className="bar__nav-item">
                <NavLink
                  to="/"
                  className={({ isActive }) =>
                    isActive ? 'bar__nav-link active' : 'bar__nav-link'
                  }
                >
                  <button className="bar__nav-btn btn btn-bar">
                    <svg
                      className="ico-20 stroke"
                      xmlns="http://www.w3.org/2000/svg"
                      width="19"
                      height="19"
                      viewBox="0 0 19 19"
                      fill="none"
                    >
                      <path
                        d="M2.375 7.12467L9.5 1.58301L16.625 7.12467V15.833C16.625 16.2529 16.4582 16.6557 16.1613 16.9526C15.8643 17.2495 15.4616 17.4163 15.0417 17.4163H3.95833C3.53841 17.4163 3.13568 17.2495 2.83875 16.9526C2.54181 16.6557 2.375 16.2529 2.375 15.833V7.12467Z"
                        strokeWidth="1.25"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M7.125 17.4167V9.5H11.875V17.4167"
                        strokeWidth="1.25"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                    <span>Главная</span>
                  </button>
                </NavLink>
              </li>
              <li className="bar__nav-item">
                <Catalog />
              </li>
              <li className="bar__nav-item">
                <Link
                  className={clsx(defLinkCl, {
                    [activeLinkCl]: location.pathname.includes('cart'),
                  })}
                  to={'/cart'}
                >
                  <button className="bar__nav-btn btn btn-bar">
                    <svg
                      className="ico-20 stroke"
                      xmlns="http://www.w3.org/2000/svg"
                      width="19"
                      height="19"
                      viewBox="0 0 19 19"
                      fill="none"
                    >
                      <path
                        d="M4.75 1.58301L2.375 4.74967V15.833C2.375 16.2529 2.54181 16.6557 2.83875 16.9526C3.13568 17.2495 3.53841 17.4163 3.95833 17.4163H15.0417C15.4616 17.4163 15.8643 17.2495 16.1613 16.9526C16.4582 16.6557 16.625 16.2529 16.625 15.833V4.74967L14.25 1.58301H4.75Z"
                        strokeWidth="1.2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M2.375 4.75H16.625"
                        strokeWidth="1.25"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M12.6663 7.91602C12.6663 8.75587 12.3327 9.56132 11.7388 10.1552C11.145 10.7491 10.3395 11.0827 9.49967 11.0827C8.65982 11.0827 7.85437 10.7491 7.2605 10.1552C6.66664 9.56132 6.33301 8.75587 6.33301 7.91602"
                        strokeWidth="1.25"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>

                    {totalLength > 0 && (
                      <span className="count">{totalLength}</span>
                    )}
                    <span>Корзина</span>
                  </button>
                </Link>
              </li>
              <li className="bar__nav-item">
                <Link
                  className={clsx(defLinkCl, {
                    [activeLinkCl]: location.pathname.includes('orders'),
                  })}
                  to={isSign ? '/orders' : '/sign'}
                >
                  <button className="bar__nav-btn btn btn-bar">
                    <svg className="ico-20 stroke">
                      <g>
                        <path
                          id="Vector"
                          d="M13.75 7.8333L6.25 3.5083"
                          strokeWidth="1.25"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          id="Vector_2"
                          d="M17.5 13.3334V6.66675C17.4997 6.37448 17.4225 6.08742 17.2763 5.83438C17.13 5.58134 16.9198 5.37122 16.6667 5.22508L10.8333 1.89175C10.58 1.74547 10.2926 1.66846 10 1.66846C9.70744 1.66846 9.42003 1.74547 9.16667 1.89175L3.33333 5.22508C3.08022 5.37122 2.86998 5.58134 2.72372 5.83438C2.57745 6.08742 2.5003 6.37448 2.5 6.66675V13.3334C2.5003 13.6257 2.57745 13.9127 2.72372 14.1658C2.86998 14.4188 3.08022 14.6289 3.33333 14.7751L9.16667 18.1084C9.42003 18.2547 9.70744 18.3317 10 18.3317C10.2926 18.3317 10.58 18.2547 10.8333 18.1084L16.6667 14.7751C16.9198 14.6289 17.13 14.4188 17.2763 14.1658C17.4225 13.9127 17.4997 13.6257 17.5 13.3334Z"
                          strokeWidth="1.25"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          id="Vector_3"
                          d="M2.7251 5.7998L10.0001 10.0081L17.2751 5.7998"
                          strokeWidth="1.25"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          id="Vector_4"
                          d="M10 18.4V10"
                          strokeWidth="1.25"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </g>
                    </svg>
                    <span>Заказы</span>
                  </button>
                </Link>
              </li>
              <li className="bar__nav-item">
                <Link
                  className={clsx(defLinkCl, {
                    [activeLinkCl]: location.pathname.includes('profile'),
                  })}
                  to={isSign ? '/profile' : '/sign'}
                >
                  <button className="bar__nav-btn btn btn-bar">
                    <svg
                      className="ico-20 stroke"
                      xmlns="http://www.w3.org/2000/svg"
                      width="19"
                      height="20"
                      viewBox="0 0 19 20"
                      fill="none"
                    >
                      <path
                        d="M15.8337 17.125V15.5417C15.8337 14.7018 15.5 13.8964 14.9062 13.3025C14.3123 12.7086 13.5068 12.375 12.667 12.375H6.33366C5.49381 12.375 4.68835 12.7086 4.09449 13.3025C3.50062 13.8964 3.16699 14.7018 3.16699 15.5417V17.125"
                        strokeWidth="1.25"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M9.49967 9.20833C11.2486 9.20833 12.6663 7.79057 12.6663 6.04167C12.6663 4.29276 11.2486 2.875 9.49967 2.875C7.75077 2.875 6.33301 4.29276 6.33301 6.04167C6.33301 7.79057 7.75077 9.20833 9.49967 9.20833Z"
                        strokeWidth="1.25"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                    <span>{isSign ? <>Профиль</> : <>Войти</>}</span>
                  </button>
                </Link>
              </li>
            </ul>
          </nav>
        </div>
      ) : (
        <>
          <div
            className={clsx('bar__catalog', { active: catalogIsActive })}
          ></div>
          <div className="bar__inner">
            <Link
              className={clsx('bar__catalog-btn btn btn-bar btn-catalog', {
                active: catalogIsActive,
              })}
              to="/catalog"
            >
              <span> Каталог </span>
              <div className="bar__catalog-btn-icon">
                <span className="line"></span>
                <span className="line"></span>
                <span className="line"></span>
              </div>
            </Link>
            <nav className="bar__nav">
              <ul className="bar__nav-list">
                <li className="bar__nav-item">
                  <Link to="/orders" className="bar__nav-link">
                    <button className="bar__nav-btn btn btn-bar">
                      <svg className="ico-20 stroke">
                        <g>
                          <path
                            id="Vector"
                            d="M13.75 7.8333L6.25 3.5083"
                            strokeWidth="1.25"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            id="Vector_2"
                            d="M17.5 13.3334V6.66675C17.4997 6.37448 17.4225 6.08742 17.2763 5.83438C17.13 5.58134 16.9198 5.37122 16.6667 5.22508L10.8333 1.89175C10.58 1.74547 10.2926 1.66846 10 1.66846C9.70744 1.66846 9.42003 1.74547 9.16667 1.89175L3.33333 5.22508C3.08022 5.37122 2.86998 5.58134 2.72372 5.83438C2.57745 6.08742 2.5003 6.37448 2.5 6.66675V13.3334C2.5003 13.6257 2.57745 13.9127 2.72372 14.1658C2.86998 14.4188 3.08022 14.6289 3.33333 14.7751L9.16667 18.1084C9.42003 18.2547 9.70744 18.3317 10 18.3317C10.2926 18.3317 10.58 18.2547 10.8333 18.1084L16.6667 14.7751C16.9198 14.6289 17.13 14.4188 17.2763 14.1658C17.4225 13.9127 17.4997 13.6257 17.5 13.3334Z"
                            strokeWidth="1.25"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            id="Vector_3"
                            d="M2.7251 5.7998L10.0001 10.0081L17.2751 5.7998"
                            strokeWidth="1.25"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            id="Vector_4"
                            d="M10 18.4V10"
                            strokeWidth="1.25"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </g>
                      </svg>
                      <span>Заказы</span>
                    </button>
                  </Link>
                </li>
                <li className="bar__nav-item">
                  <Link to="/cart" className="bar__nav-link">
                    <button className="bar__nav-btn btn btn-bar">
                      <svg className="ico-20 stroke">
                        <g>
                          <path
                            id="Vector"
                            d="M5 1.6665L2.5 4.99984V16.6665C2.5 17.1085 2.67559 17.5325 2.98816 17.845C3.30072 18.1576 3.72464 18.3332 4.16667 18.3332H15.8333C16.2754 18.3332 16.6993 18.1576 17.0118 17.845C17.3244 17.5325 17.5 17.1085 17.5 16.6665V4.99984L15 1.6665H5Z"
                            strokeWidth="1.25"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            id="Vector_2"
                            d="M2.5 5H17.5"
                            strokeWidth="1.25"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            id="Vector_3"
                            d="M13.3332 8.3335C13.3332 9.21755 12.982 10.0654 12.3569 10.6905C11.7317 11.3156 10.8839 11.6668 9.99984 11.6668C9.11578 11.6668 8.26794 11.3156 7.64281 10.6905C7.01769 10.0654 6.6665 9.21755 6.6665 8.3335"
                            strokeWidth="1.25"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </g>
                      </svg>
                      <span>Корзина</span>
                    </button>
                  </Link>
                </li>
                <li className="bar__nav-item">
                  <Link to="/favors/products" className="bar__nav-link">
                    <button className="bar__nav-btn btn btn-ico btn-bar">
                      <svg
                        className="ico-24 stroke"
                        width="16"
                        height="16"
                        viewBox="0 0 16 16"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <g id="heart">
                          <path
                            id="Vector"
                            d="M13.8931 3.07357C13.5526 2.73291 13.1483 2.46267 12.7033 2.2783C12.2584 2.09392 11.7814 1.99902 11.2998 1.99902C10.8181 1.99902 10.3412 2.09392 9.89618 2.2783C9.45121 2.46267 9.04692 2.73291 8.70642 3.07357L7.99975 3.78024L7.29309 3.07357C6.60529 2.38578 5.67244 1.99938 4.69975 1.99938C3.72706 1.99938 2.79422 2.38578 2.10642 3.07357C1.41863 3.76137 1.03223 4.69422 1.03223 5.66691C1.03223 6.6396 1.41863 7.57245 2.10642 8.26024L2.81309 8.96691L7.99975 14.1536L13.1864 8.96691L13.8931 8.26024C14.2337 7.91974 14.504 7.51545 14.6884 7.07048C14.8727 6.6255 14.9676 6.14857 14.9676 5.66691C14.9676 5.18525 14.8727 4.70831 14.6884 4.26334C14.504 3.81836 14.2337 3.41408 13.8931 3.07357Z"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </g>
                      </svg>
                    </button>
                  </Link>
                </li>
                <li className="bar__nav-item">
                  <Link to="/profile" className="bar__nav-link">
                    <button className="bar__nav-btn btn btn-ico btn-bar">
                      <svg
                        className="ico-24 stroke"
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M16.6668 17.5V15.8333C16.6668 14.9493 16.3156 14.1014 15.6905 13.4763C15.0654 12.8512 14.2176 12.5 13.3335 12.5H6.66683C5.78277 12.5 4.93493 12.8512 4.30981 13.4763C3.68469 14.1014 3.3335 14.9493 3.3335 15.8333V17.5"
                          strokeWidth="1.25"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M9.99984 9.16667C11.8408 9.16667 13.3332 7.67428 13.3332 5.83333C13.3332 3.99238 11.8408 2.5 9.99984 2.5C8.15889 2.5 6.6665 3.99238 6.6665 5.83333C6.6665 7.67428 8.15889 9.16667 9.99984 9.16667Z"
                          strokeWidth="1.25"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    </button>
                  </Link>
                </li>
              </ul>
            </nav>
          </div>
        </>
      )}
    </div>
  );
};

export default Bar;
