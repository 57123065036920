import MyBtn from '@components/_UI/btns/MyBtn';
import cl from './Head.module.scss';
import Status from '@components/order/status/Status';

const Head = ({ order }) => {
  return (
    <div className={cl.head}>
      <Status classNames={cl.status} status={order?.status} />
      <ul className={cl.list}>
        <li className={cl.list_item}>
          <MyBtn className={`${cl.list_btn} btn btn-accent`}>
            Вопрос по заказу
          </MyBtn>
        </li>
        {order?.status === 3 && (
          <li className={cl.list_item}>
            <MyBtn className={`${cl.list_btn} btn btn-accent`}>
              Связаться с продавцом
            </MyBtn>
          </li>
        )}
      </ul>
    </div>
  );
};

export default Head;
