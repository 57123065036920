import { loadStatus } from "@store/loadStatus";
import axios from "axios";

const { createSlice, createAsyncThunk } = require("@reduxjs/toolkit");

export const getInfo = createAsyncThunk(
  'orders/getInfo',
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await axios.get(
        `${process.env.REACT_APP_API_URL}v1/page/1`,
      );

      console.log(data);
      return data;
    } catch (err) {
      console.log(`ошибка получения заказов: ${err}`);
      return rejectWithValue(err.message);
    }
  },
);

const initialState = {
  infoLoadStatus: 'idle',
  info: null,
};

const aboutSlice = createSlice({
  name: 'about',
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(getInfo.pending, (state) => {
        state.infoLoadStatus = loadStatus.pending;
      })
      .addCase(getInfo.fulfilled, (state, action) => {
        state.infoLoadStatus = loadStatus.fulfilled;
        console.log(`fulfilled: `, action);
        state.info = action.payload;
      })
      .addCase(getInfo.rejected, (state, action) => {
        state.infoLoadStatus = loadStatus.rejected;
        console.log(`rejected: `, action);
        state.isOrdered = false;
      });
  },
})

// export const {} = aboutSlice.actions;
export const aboutSel = state => state.about;

export default aboutSlice.reducer;
