import Breadcrumbs from '@components/breadcrumbs/Breadcrumbs';

import cl from './Head.module.scss';

const Head = () => {
  return (
    <div className={cl.head}>
      <Breadcrumbs classNames={cl.crumbs} />
      <div className={cl.title_sort}>
        <h1 className={cl.title + ' title title-section'}>Мои заказы</h1>
      </div>
    </div>
  );
};

export default Head;
