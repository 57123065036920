const { createSlice } = require("@reduxjs/toolkit");

const initialState = {
  hostname: window.location.hostname === 'localhost' ? 'mmo.tech-tester.ru' : window.location.hostname,
  userHost: "mmo.tech-tester.ru",
  sellerHost: "seller-mmo.tech-tester.ru",
};

const hostSlice = createSlice({
  name: 'host',
  initialState,
})

export const hostSel = state => state.host;

export default hostSlice.reducer;
