import CatalogPageHead from './head/CatalogPageHead';
import CatalogPageProducts from './products/CatalogPageProducts';
import CatalogPageFilter from './filter/CatalogPageFilter';
import MyPage from '@components/_UI/MyPage';
import MySection from '@components/_UI/MySection';

import './CatalogPage.scss';
import useWindowWidth from '@hooks/windowWidth';
import { useDispatch, useSelector } from 'react-redux';
import {
  catalogSel,
  getFilteredProducts,
  setCatTree,
  setCurCatRoot,
  setCurCatTree,
} from '@store/slices/catalog/catalogSlice';
import { useEffect } from 'react';
import Header from '@components/header/Header';
import Footer from '@components/footer/Footer';
import { useLocation, useMatch, useNavigate } from 'react-router-dom';
import { setCatalogIsOpen } from '@store/slices/header/headerSlice';
import { disableScroll } from '@hooks/disableScroll';

const CatalogPage = () => {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const match = useMatch('/catalog/*');
  const nestedPath = match.params['*'];
  const windowWidth = useWindowWidth();
  const dispatch = useDispatch();
  const { catRoots, curCatTree, selectedSort, productsPerPage } =
    useSelector(catalogSel);

  const findFirstCatBySlugs = () => {
    const slug = nestedPath.split('/')[0];
    const curCat = catRoots?.find((cat) => cat.slug === slug);

    dispatch(setCurCatRoot(curCat));
    dispatch(setCatTree(curCat.sub_categories));
  };

  const findLastCatBySlugs = () => {
    const slugs = nestedPath ? nestedPath.split('/') : [];
    let curCats = catRoots;
    let curCat = null;

    for (let slug of slugs) {
      curCat = curCats?.find((category) => category.slug === slug);
      if (curCat && curCat.sub_categories) {
        curCats = curCat.sub_categories;
      } else {
        break;
      }
    }

    dispatch(setCurCatTree(curCat));
  };

  useEffect(() => {
    if (catRoots) {
      findFirstCatBySlugs();
      findLastCatBySlugs();
    }
  }, [catRoots]);

  useEffect(() => {
    if (curCatTree) {
      dispatch(
        getFilteredProducts({
          categoryId: curCatTree.id,
          sortBy: selectedSort.value,
          perPage: productsPerPage.value,
          curPage: 1,
        }),
      );
    }
  }, [curCatTree]);

  useEffect(() => {
    if (pathname === '/catalog') {
      navigate('/');
      dispatch(setCatalogIsOpen(true));
      disableScroll();
    }
  }, [pathname]);

  return (
    <>
      <Header />

      <MyPage
        metaDescr={curCatTree?.seo_description}
        metaTitle={`Qlixmed | ${curCatTree?.seo_title}`}
        metaKeywords={curCatTree?.seo_keywords}
        hiddenTitle="catalog"
      >
        <MySection>
          <CatalogPageHead />
          <div className="catalog__content">
            {windowWidth >= 1025 && <CatalogPageFilter />}
            <CatalogPageProducts />
          </div>
        </MySection>
      </MyPage>

      <Footer />
    </>
  );
};

export default CatalogPage;
