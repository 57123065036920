import { loadStatus } from '@store/loadStatus';
import axios from 'axios';

const { createSlice, createAsyncThunk } = require('@reduxjs/toolkit');

export const searchProducts = createAsyncThunk(
  'header/searchProducts',
  async (productName, { rejectWithValue }) => {
    try {
      const { data } = await axios.get(
        `${process.env.REACT_APP_API_URL}v1/product/search?name=${productName}`,
      );

      return data;
    } catch (err) {
      console.log('ошибка при попытке поиска товаров из шапки: ', err);
      return rejectWithValue(err.message);
    }
  },
);

const initialState = {
  burger: false,

  catalogIsOpen: false,

  searchVal: '',
  searchDebVal: '',
  searchIsOpen: false,
  burgerIsOpen: false,
  searchResults: null,
  searchLoadStatus: 'idle',
};

const headerSlice = createSlice({
  name: 'header',
  initialState,
  reducers: {
    setBurger: (state, action) => {
      state.burger = action.payload;
    },
    setCatalogIsOpen: (state, action) => {
      state.catalogIsOpen = action.payload;
    },
    setSearchIsOpen: (state, action) => {
      state.searchIsOpen = action.payload;
    },
    setBurgerIsOpen: (state, action) => {
      state.burgerIsOpen = action.payload;
    },

    setSearchVal: (state, action) => {
      state.searchVal = action.payload;
    },

    setSearchDebVal: (state, action) => {
      state.searchDebVal = action.payload;
    },

    setSearchResults: (state, action) => {
      state.searchResults = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(searchProducts.pending, (state) => {
        state.searchLoadStatus = loadStatus.pending;
      })
      .addCase(searchProducts.fulfilled, (state, action) => {
        state.searchLoadStatus = loadStatus.fulfilled;
        state.searchResults = action.payload;
      })
      .addCase(searchProducts.rejected, (state, action) => {
        state.searchLoadStatus = loadStatus.rejected;
        console.log(action.payload);
      });
  },
});

export const {
  setBurger,
  setCatalogIsOpen,
  setSearchVal,
  setSearchDebVal,
  setSearchIsOpen,
  setBurgerIsOpen,
  setSearchResults,
} = headerSlice.actions;
export const headerSel = (state) => state.header;

export default headerSlice.reducer;
