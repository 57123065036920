import MyModal from '@components/_UI/modal/MyModal';
import outerCl from '../Ordering.module.scss';
import Auth from '../auth/Auth';
import Success from '../success/Success';
import Confirm from '../confirm/Confirm';

const CartModal = ({
  modalIsOpen,
  setModalIsOpen,
  cleanedNum,
  numValue,
  setNumValue,
  codeValue,
  setCodeValue,
  onSignUser,
  onSendCode,
  onConfirm,
  onCancel,
  toReNum,
  onReSendNewCode,
  isNum,
  isSign,
  isOrdered,
}) => {
  console.log(isOrdered);
  return (
    <MyModal
      modalCl={outerCl.modal}
      overlayCl={outerCl.overlay}
      innerCl={outerCl.inner}
      closeIsNeed={false}
      modalIsOpen={modalIsOpen}
      setModalIsOpen={setModalIsOpen}
    >
      {!isOrdered ? (
        !isSign ? (
          <Auth
            cleanedNum={cleanedNum}
            numValue={numValue}
            setNumValue={setNumValue}
            codeValue={codeValue}
            setCodeValue={setCodeValue}
            onSignUser={onSignUser}
            onSendCode={onSendCode}
            toReNum={toReNum}
            onReSendNewCode={onReSendNewCode}
            isNum={isNum}
          />
        ) : (
          <Confirm onConfirm={onConfirm} onCancel={onCancel} />
        )
      ) : (
        <Success />
      )}
    </MyModal>
  );
};

export default CartModal;
