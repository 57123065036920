const { createSlice } = require("@reduxjs/toolkit");


const initialState = {
  productLoadStatus: "idle",
  modalIsOpen: false,
  product: {},

  selectedImages: [],

  nameVal: "",
  priceVal: "",
  descrVal: "",

  catOptions: [
    { value: "all", label: "Все категории" },
    { value: "onSale", label: "Хирургия" },
    { value: "retired", label: "Педиатрия" },
  ],
  selectedCatSort: { value: "all", label: "Всe категории" },

  statusOptions: [
    { value: 2, label: "В продаже" },
    { value: 1, label: "Снят с продажи" },
    { value: 3, label: "Нет в наличии" },
  ],
  selectedStatusSort: { value: 2, label: "В продаже" },
};

const sellerProductSlice = createSlice({
  name: "sellerProduct",
  initialState,
  reducers: {
    setModalIsOpen: (state, action) => {
      state.modalIsOpen = action.payload;
    },

    setProduct: (state, action) => {
      state.product = action.payload;
    },

    setSelectedImages: (state, action) => {
      state.selectedImages = action.payload;
    },
    setNameVal: (state, action) => {
      state.nameVal = action.payload;
    },
    setPriceVal: (state, action) => {
      state.priceVal = action.payload;
    },
    setDescrVal: (state, action) => {
      state.descrVal = action.payload;
    },
    setSelectedCatSort: (state, action) => {
      state.selectedCatSort = action.payload;
    },
    setSelectedStatusSort: (state, action) => {
      state.selectedStatusSort = action.payload;
    },
  },
});

export const sellerProductSel = state => state.sellerProduct;
export const {
  setModalIsOpen,
  setProduct,
  setSelectedImages,
  setNameVal,
  setPriceVal,
  setDescrVal,
  setSelectedCatSort,
  setSelectedStatusSort,
} = sellerProductSlice.actions;

export default sellerProductSlice.reducer;
