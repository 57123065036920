import MyModal from '@components/_UI/modal/MyModal';
import cl from './Modal.module.scss';
import { Link, NavLink } from 'react-router-dom';
import MyBtn from '@components/_UI/btns/MyBtn';
import { useDispatch } from 'react-redux';
import { onHide } from '@hooks/toHide';

const Modal = ({ modalIsOpen, setModalIsOpen }) => {
  const dispatch = useDispatch();

  const onItem = () => {
    dispatch(setModalIsOpen(false));
    onHide(modalIsOpen);
  };

  return (
    <MyModal
      modalIsOpen={modalIsOpen}
      setModalIsOpen={setModalIsOpen}
      closeIsNeed={false}
      overlayCl={cl.overlay}
      modalCl={cl.modal}
      innerCl={cl.inner}
      hideBar={true}
    >
      <ul className={cl.list} onClick={onItem}>
        <li className={cl.list_item}>
          <NavLink
            to={'/about'}
            className={({ isActive }) =>
              isActive ? `${cl.list_link} ${cl.list_link_active}` : cl.list_link
            }
          >
            <span>О компании</span>
            <svg
              className="ico-20 stroke"
              width="19"
              height="19"
              viewBox="0 0 19 19"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M6.9375 4.625L11.5625 9.25L6.9375 13.875"
                stroke="currentColor"
                strokeWidth="1.54167"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </NavLink>
        </li>
        <li className={cl.list_item}>
          <NavLink
            to={'/partners'}
            className={({ isActive }) =>
              isActive ? `${cl.list_link} ${cl.list_link_active}` : cl.list_link
            }
          >
            <span>Партнерам</span>
            <svg
              className="ico-20 stroke"
              width="19"
              height="19"
              viewBox="0 0 19 19"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M6.9375 4.625L11.5625 9.25L6.9375 13.875"
                stroke="currentColor"
                strokeWidth="1.54167"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </NavLink>
        </li>
        <li className={cl.list_item}>
          <NavLink
            to={'/faq'}
            className={({ isActive }) =>
              isActive ? `${cl.list_link} ${cl.list_link_active}` : cl.list_link
            }
          >
            <span>Вопросы и ответы</span>
            <svg
              className="ico-20 stroke"
              width="19"
              height="19"
              viewBox="0 0 19 19"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M6.9375 4.625L11.5625 9.25L6.9375 13.875"
                stroke="currentColor"
                strokeWidth="1.54167"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </NavLink>
        </li>
        <li className={cl.list_item}>
          <NavLink
            to={'/delivery'}
            className={({ isActive }) =>
              isActive ? `${cl.list_link} ${cl.list_link_active}` : cl.list_link
            }
          >
            <span>Доставка</span>
            <svg
              className="ico-20 stroke"
              width="19"
              height="19"
              viewBox="0 0 19 19"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M6.9375 4.625L11.5625 9.25L6.9375 13.875"
                stroke="currentColor"
                strokeWidth="1.54167"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </NavLink>
        </li>
      </ul>
      <div className={cl.bot}>
        <MyBtn classNames={cl.btn + ' btn-accent w-full'} onClick={onItem}>
          Стать продавцом
        </MyBtn>
        <div className={cl.contacts}>
          <Link className={cl.contacts_link + ' link link-accent'} onClick={onItem}>
            +7 740 075 71 72
          </Link>
          <Link className={cl.contacts_link + ' link link-accent'} onClick={onItem}>
            info@qlixmed.kz
          </Link>
        </div>
      </div>
    </MyModal>
  );
};

export default Modal;
