import './Header.scss';
import { useDispatch, useSelector } from 'react-redux';
import { signSel } from 'src/store/slices/sign/signSlice';
import Logo from '@components/_UI/logo/Logo';
import Search from './search/Search';
import { Link, NavLink, useLocation } from 'react-router-dom';
import {
  cartSel,
  getCartProducts,
  setCartGot,
} from '@store/slices/cart/cartSlice';

import { clsx } from 'clsx';
import Catalog from './catalog/Catalog';
import { useEffect } from 'react';
import {
  headerSel,
  setBurgerIsOpen,
  setCatalogIsOpen,
  setSearchDebVal,
  setSearchIsOpen,
  setSearchResults,
  setSearchVal,
} from '@store/slices/header/headerSlice';
import { onHide } from '@hooks/toHide';
import Burger from './burger/Burger';

const Header = () => {
  const dispatch = useDispatch();
  const { isSign, uid } = useSelector(signSel);
  const { cartGot, totalLength } = useSelector(cartSel);
  const { catalogIsOpen, burgerIsOpen } = useSelector(headerSel);

  const location = useLocation();

  const defLinkCl = 'header__list-link';
  const activeLinkCl = 'header__list-link active';

  useEffect(() => {
    if (uid && !cartGot) {
      dispatch(setCartGot(true));
      dispatch(getCartProducts({ uid: uid }));
    }
  }, [uid]);

  const onRouting = () => {
    if (catalogIsOpen === true) {
      dispatch(setCatalogIsOpen(false));
    }
    if (burgerIsOpen === true) {
      dispatch(setBurgerIsOpen(false));
    }
    dispatch(setSearchVal(''));
    dispatch(setSearchDebVal(''));
    dispatch(setSearchResults([]));
    dispatch(setSearchIsOpen(false));
    onHide(true, false);
  };

  return (
    <header className="header fixed-block toHide">
      <div className="container">
        <div className="header__inner">
          <div className="header__top" onClick={onRouting}>
            <div className="header__top-contacts">
              <Link
                className="header__top-link link-accent"
                to={'tel:+77400757172'}
              >
                +7 740 075 71 72
              </Link>
              <Link
                className="header__top-link link-accent"
                to={'mailto:info@qlixmed.kz'}
              >
                info@qlixmed.kz
              </Link>
            </div>
            <div className="header__top-pages">
              <button className="header__top-btn btn">Стать продавцом</button>
              <NavLink
                className={({ isActive }) =>
                  isActive
                    ? 'header__top-link link-accent header__top-link-active'
                    : 'header__top-link link-accent'
                }
                to={'/about'}
              >
                О компании
              </NavLink>
              <NavLink
                className={({ isActive }) =>
                  isActive
                    ? 'header__top-link link-accent header__top-link-active'
                    : 'header__top-link link-accent'
                }
                to={'/partners'}
              >
                Партнерам
              </NavLink>
              <NavLink
                className={({ isActive }) =>
                  isActive
                    ? 'header__top-link link-accent header__top-link-active'
                    : 'header__top-link link-accent'
                }
                to={'/faq'}
              >
                Вопросы и ответы
              </NavLink>
              <NavLink
                className={({ isActive }) =>
                  isActive
                    ? 'header__top-link link-accent header__top-link-active'
                    : 'header__top-link link-accent'
                }
                to={'/delivery'}
              >
                Доставка
              </NavLink>
            </div>
          </div>
          <div className="header__bot" onClick={onRouting}>
            <Logo classNames={'header__logo'} />
            <div className="header__catalog-search">
              <Catalog />
              <Search />
            </div>

            <Burger />

            <nav className="header__list-outer">
              <ul className="header__list">
                <li className="header__list-item header__list">
                  <Link
                    className={clsx(defLinkCl, {
                      [activeLinkCl]: location.pathname.includes('profile'),
                    })}
                    to={isSign ? '/profile/personal' : '/sign'}
                  >
                    <svg
                      className="ico-24 stroke"
                      xmlns="http://www.w3.org/2000/svg"
                      width="26"
                      height="26"
                      viewBox="0 0 26 26"
                      fill="none"
                    >
                      <path
                        d="M21.6663 22.75V20.5833C21.6663 19.4341 21.2098 18.3319 20.3971 17.5192C19.5845 16.7065 18.4823 16.25 17.333 16.25H8.66634C7.51707 16.25 6.41487 16.7065 5.60221 17.5192C4.78955 18.3319 4.33301 19.4341 4.33301 20.5833V22.75"
                        stroke="currentColor"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M13.0003 11.9167C15.3936 11.9167 17.3337 9.97657 17.3337 7.58333C17.3337 5.1901 15.3936 3.25 13.0003 3.25C10.6071 3.25 8.66699 5.1901 8.66699 7.58333C8.66699 9.97657 10.6071 11.9167 13.0003 11.9167Z"
                        stroke="currentColor"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                    <span>{isSign ? <>Профиль</> : <>Войти</>}</span>
                  </Link>
                </li>
                <li className="header__list-item header__list">
                  <Link
                    className={clsx(defLinkCl, {
                      [activeLinkCl]: location.pathname.includes('orders'),
                    })}
                    to={isSign ? '/orders' : '/sign'}
                  >
                    <svg
                      className="ico-24 stroke"
                      xmlns="http://www.w3.org/2000/svg"
                      width="26"
                      height="26"
                      viewBox="0 0 26 26"
                      fill="none"
                    >
                      <path
                        d="M17.875 10.183L8.125 4.56055"
                        stroke="currentColor"
                        strokeWidth="1.25"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M22.75 17.3334V8.66672C22.7496 8.28677 22.6493 7.9136 22.4592 7.58465C22.269 7.2557 21.9957 6.98253 21.6667 6.79256L14.0833 2.45922C13.754 2.26906 13.3803 2.16895 13 2.16895C12.6197 2.16895 12.246 2.26906 11.9167 2.45922L4.33333 6.79256C4.00428 6.98253 3.73098 7.2557 3.54083 7.58465C3.35069 7.9136 3.25039 8.28677 3.25 8.66672V17.3334C3.25039 17.7133 3.35069 18.0865 3.54083 18.4155C3.73098 18.7444 4.00428 19.0176 4.33333 19.2076L11.9167 23.5409C12.246 23.7311 12.6197 23.8312 13 23.8312C13.3803 23.8312 13.754 23.7311 14.0833 23.5409L21.6667 19.2076C21.9957 19.0176 22.269 18.7444 22.4592 18.4155C22.6493 18.0865 22.7496 17.7133 22.75 17.3334Z"
                        stroke="currentColor"
                        strokeWidth="1.25"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M3.54297 7.54004L13.0005 13.0109L22.458 7.54004"
                        stroke="currentColor"
                        strokeWidth="1.25"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M13 23.92V13"
                        stroke="currentColor"
                        strokeWidth="1.25"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                    <span>Мои заказы</span>
                  </Link>
                </li>
                <li className="header__list-item header__list">
                  <Link
                    className={clsx(defLinkCl, {
                      [activeLinkCl]: location.pathname.includes('cart'),
                    })}
                    to={'/cart'}
                  >
                    <svg
                      className="ico-24 stroke"
                      xmlns="http://www.w3.org/2000/svg"
                      width="26"
                      height="26"
                      viewBox="0 0 26 26"
                      fill="none"
                    >
                      <path
                        d="M6.5 2.1665L3.25 6.49984V21.6665C3.25 22.2411 3.47827 22.7922 3.8846 23.1986C4.29093 23.6049 4.84203 23.8332 5.41667 23.8332H20.5833C21.158 23.8332 21.7091 23.6049 22.1154 23.1986C22.5217 22.7922 22.75 22.2411 22.75 21.6665V6.49984L19.5 2.1665H6.5Z"
                        stroke="currentColor"
                        strokeWidth="1.25"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M3.25 6.5H22.75"
                        stroke="currentColor"
                        strokeWidth="1.25"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M17.3337 10.8335C17.3337 11.9828 16.8771 13.085 16.0645 13.8976C15.2518 14.7103 14.1496 15.1668 13.0003 15.1668C11.8511 15.1668 10.7489 14.7103 9.9362 13.8976C9.12354 13.085 8.66699 11.9828 8.66699 10.8335"
                        stroke="currentColor"
                        strokeWidth="1.25"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                    <span>Корзина</span>
                    {totalLength > 0 && (
                      <span className="count">{totalLength}</span>
                    )}
                  </Link>
                </li>
              </ul>
            </nav>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
