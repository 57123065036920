import Rating from '@components/rating/Rating';
import cl from './Info.module.scss';
import defAva from '@assets/img/default-avatar.png';

const Info = ({ seller }) => {
  return (
    <div className={cl.info}>
      <div className={cl.top}>
        <img
          className={cl.img}
          src={seller?.avatar ? seller.avatar : defAva}
          alt=""
        />
        <div className={cl.main}>
          <h1 className={cl.name}>Медикс фарм</h1>
          <p className={cl.date}>В Qlixmed с 15.12.2022</p>
        </div>
      </div>
      <div className={cl.bot}>
        <div className={cl.block}>
          <p className={cl.item}>Всего продаж: 920</p>
          <Rating ratingCl={cl.rating} stars={5} rateCount={119} />
        </div>
        <div className={cl.block}>
          <p className={cl.item}>Местоположение: Алматы</p>
          <p className={cl.item}>+7 (707) 180-40-06</p>
        </div>
      </div>
    </div>
  );
};

export default Info;
