import { useDispatch, useSelector } from 'react-redux';

import Product from '@components/product/Product';

import './CatalogPageProducts.scss';
import {
  catalogSel,
  getFilteredProducts,
  setProductsPerPage,
  setSelectedSort,
} from '@store/slices/catalog/catalogSlice';
import Pagination from '@components/pagination/Pagination';
import MyDropdown from '@components/_UI/dropdown/MyDropdown';
import { useState } from 'react';
import { onHide } from '@hooks/toHide';
import useWindowWidth from '@hooks/windowWidth';

const CatalogPageItems = () => {
  const dispatch = useDispatch();
  const {
    filteredProducts,
    productsPerPage,
    perPageItems,
    totalProducts,
    curCatTree,
    selectedSort,
    sorts,
    curPage,
  } = useSelector(catalogSel);
  const [sortIsOpen, setSortIsOpen] = useState(false);
  const [perPageIsOpen, setPerPageIsOpen] = useState(false);
  const windowWidth = useWindowWidth();

  const onSort = () => {
    setSortIsOpen(!sortIsOpen);
    onHide(sortIsOpen);
  };

  const onPerPage = () => {
    setPerPageIsOpen(!perPageIsOpen);
    onHide(perPageIsOpen);
  };

  const onSelect = (option) => {
    dispatch(setSelectedSort(option));
    dispatch(
      getFilteredProducts({
        categoryId: curCatTree.id,
        sortBy: option.value,
        perPage: productsPerPage.value,
        curPage: curPage,
      }),
    );
    onSort();
  };

  const onPerPageItem = (option) => {
    dispatch(setProductsPerPage(option));
    dispatch(
      getFilteredProducts({
        categoryId: curCatTree.id,
        sortBy: selectedSort.value,
        perPage: option.value,
        curPage: curPage,
      }),
    );
    onPerPage();
  };

  const onPaginate = (selected) => {
    dispatch(
      getFilteredProducts({
        categoryId: curCatTree.id,
        sortBy: selectedSort.value,
        perPage: productsPerPage.value,
        curPage: selected,
      }),
    );
    window.scrollTo(0, 0);
  };

  return (
    <div className="w-full flex flex-col gap-24">
      {!filteredProducts || filteredProducts.length === 0 ? (
        <>нет товара с подходящими характеристиками</>
      ) : (
        <>
          {windowWidth > 1025 && (
            <div className="catalog__product-head flex items-center justify-between">
              <MyDropdown
                value={selectedSort}
                setValue={setSelectedSort}
                options={sorts}
                sortIsOpen={sortIsOpen}
                setSortIsOpen={setSortIsOpen}
                onSelect={onSelect}
              />
              <div className={'flex gap-12 items-center'}>
                <span>Показывать по:</span>
                <MyDropdown
                  classNames={'catalog__perPage'}
                  value={productsPerPage}
                  setValue={setProductsPerPage}
                  options={perPageItems}
                  sortIsOpen={perPageIsOpen}
                  setSortIsOpen={setPerPageIsOpen}
                  onSelect={onPerPageItem}
                />
              </div>
            </div>
          )}
          <div className="catalog__product-outer">
            {filteredProducts?.map((product) => (
              <Product
                link={`/product/${product.slug}-${product.id}`}
                classNames={'catalog__product slider-link'}
                product={product}
                key={product.id}
              />
            ))}
          </div>
          <Pagination
            itemsPerPage={productsPerPage.value}
            onPaginate={onPaginate}
            total={totalProducts}
          />
        </>
      )}
    </div>
  );
};

export default CatalogPageItems;
