import MyModal from '@components/_UI/modal/MyModal';
import React, { useState } from 'react';
import ReactPlayer from 'react-player';
import cl from './VideoModal.module.scss';
import playIco from '@assets/img/playIco.svg'

const VideoModal = ({
  videoUrl,
  banner,
  controls = true,
  modalIsOpen,
  setModalIsOpen,
}) => {
    const [playing, setPlaying] = useState(false);

    const onPlayVideo = () => {
        setPlaying(true);
    }

    const onClose = () => {
        setPlaying(false);
    }

  return (
    <MyModal
      overlayCl={cl.overlay}
      modalCl={cl.modal}
      innerCl={cl.inner}
      closeCl={cl.close}
      modalIsOpen={modalIsOpen}
      setModalIsOpen={setModalIsOpen}
      closeCallback={onClose}
      closeIsNeed={true}
    >
      <ReactPlayer
        url={videoUrl}
        light={banner}
        playing={playing}
        controls={controls}
        onPlay={onPlayVideo}
        height={'100%'}
        width={'100%'}
        playIcon={<img className={cl.playIco} src={playIco} alt='play ico' />}
        onClickPreview={onPlayVideo}
      />
    </MyModal>
  );
};

export default VideoModal;
