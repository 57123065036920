import { useSelector } from 'react-redux';
import cl from './Descr.module.scss';
import pageCl from '../../ProductPage.module.scss'
import { productSel } from '@store/slices/product/productSlice';

const Descr = () => {
  const { product } = useSelector(productSel);
  return (
    <>
      <div
        className={`${cl.content} ${pageCl.table}`}
        dangerouslySetInnerHTML={{ __html: product.full_description }}
      />
      <div className={`${cl.content}`}>
        <p className={cl.descr}>{product.description}</p>
      </div>
    </>
  );
};

export default Descr;
