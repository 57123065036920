import React from 'react';
import { Link } from 'react-router-dom';
import clsx from 'clsx';
import { useSelector } from 'react-redux';
import { catalogSel } from '@store/slices/catalog/catalogSlice';

const CatItem = ({ category, parentSlug = '', onCategory }) => {
  const { curCatTree } = useSelector(catalogSel);

  const hasSubCategories =
    category.sub_categories && category.sub_categories.length > 0;
  const categorySlug = `${parentSlug}/${category.slug}`;

  return (
    <li className="catalog__category-subitem">
      <Link
        className={clsx('catalog__category-link link link-accent', {
          active: curCatTree?.id === category.id,
        })}
        to={`/catalog/${categorySlug}`}
        onClick={() => onCategory(category)}
      >
        <span className="catalog__category-text">
          {category.category_name}{' '}
          <span className="catalog__category-count">
            ({category.product_count})
          </span>
        </span>
      </Link>
      {hasSubCategories && (
        <ul className="catalog__category-sublist">
          {category.sub_categories.map((subCat) => (
            <CatItem
              key={subCat.id}
              category={subCat}
              parentSlug={categorySlug}
              onCategory={onCategory}
            />
          ))}
        </ul>
      )}
    </li>
  );
};

export default CatItem;
