import React from "react";
import { Link } from "react-router-dom";

const NotFound = ({ children, classNames, ...props }) => {
  return (
    <section className={`${classNames} notFound`} {...props}>
      <div className="container">
        <div className="notFound__inner">
          {children}
          <Link className="notFound__btn btn btn-accent" to={'/'}>
            Вернуться на главную
          </Link>
        </div>
      </div>
    </section>
  );
};

export default NotFound;
