import React from 'react';
import { Link } from 'react-router-dom';
import clsx from 'clsx';
import { useSelector } from 'react-redux';
import { catalogSel } from '@store/slices/catalog/catalogSlice';
import outerCl from '../Modal.module.scss';

const CatItem = ({ category, parentSlug = '', onCategory }) => {
  const { curCatTree } = useSelector(catalogSel);
  const categorySlug = `${parentSlug}/${category.slug}`;
  const slashesCount = categorySlug.split('/').length;

  return (
    <li className={outerCl.subitem}>
      <Link
          className={clsx(outerCl.subitem__btn + ` ${outerCl.item__btn}`, {
            [outerCl.item__btn_active]: curCatTree?.id === category.id,
          })}
          to={`/catalog/${categorySlug}`}
          onClick={() => onCategory(category)}
          style={{ paddingLeft: 20 * slashesCount }}
        >
          <span>
            {category.category_name}{' '}
            <span className="catalog__category-count">
              ({category.product_count})
            </span>
          </span>{' '}
          <svg
            className="ico-12 stroke"
            width="7"
            height="13"
            viewBox="0 0 7 13"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M1 1L6 6.5L1 12" stroke="#CCCCCC" />
          </svg>
        </Link>
    </li>
  );
};

export default CatItem;
