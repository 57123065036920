import MyBtn from '@components/_UI/btns/MyBtn';
import React from 'react';
import cl from './Confirm.module.scss';

const Confirm = ({ onConfirm, onCancel }) => {
  return (
    <div className={`${cl.block} flex w-full justify-between gap-14 flex-wrap`}>
      <MyBtn classNames={cl.btn + ' btn-accent'} onClick={onConfirm}>
        Верно, продолжить
      </MyBtn>
      <MyBtn classNames={cl.btn + ' btn-accent btn-lazur'} onClick={onCancel}>
        Вернуться назад
      </MyBtn>
    </div>
  );
};

export default Confirm;
