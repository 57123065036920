import MyForm from '@components/_UI/MyForm';
import MyBtn from '@components/_UI/btns/MyBtn';
import Logo from '@components/_UI/logo/Logo';
import TelInput from '@components/telInput/TelInput';
import { setTelField } from '@store/slices/profile/profileSlice';
import { clsx } from 'clsx';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  setNumValue,
  signUser,
  signSel,
} from 'src/store/slices/sign/signSlice';

// import googleIco from '@assets/img/google.svg';
// import appleIco from '@assets/img/apple.svg';

const SignNum = () => {
  const dispatch = useDispatch();
  const { numValue, cleanedNum } = useSelector(signSel);

  const onSignIn = async (e) => {
    e.preventDefault();

    if (cleanedNum.length === 11) {
      dispatch(signUser({ phone: cleanedNum }));
      dispatch(setTelField(numValue));
    }
  };

  return (
    <>
      <MyForm classNames={'sign__form'} action="#" onSubmit={onSignIn}>
        <Logo classNames={'sign__logo'} />
        <h3 className="form__title">Введите номер телефона</h3>
        <p className="form__subtitle">
          Мы отправим код или позвоним. Отвечать на звонок не нужно
        </p>
        <TelInput
          classNames={'sign__input'}
          telInput={numValue}
          setTelInput={setNumValue}
        />
        <MyBtn
          classNames={clsx('sign__btn btn-accent', {
            dis: cleanedNum.length !== 11,
          })}
          type={'submit'}
        >
          Войти
        </MyBtn>
        {/* 
        <div className={'sign__other'}>
          <span className={'sign__other-or'}>или</span>
          <div className="sign__other-btns">
            <MyBtn
              classNames={'sign__google'}
              onClick={(e) => e.stopPropagation()}
              type={'button'}
            >
              <img className="ico-18" src={googleIco} alt="google" />
              <span>Вход через Google</span>
            </MyBtn>
            <MyBtn
              classNames={'sign__apple'}
              onClick={(e) => e.stopPropagation()}
              type={'button'}
            >
              <img className="ico-18" src={appleIco} alt="apple" />
              <span>Вход через Apple</span>
            </MyBtn>
          </div>
        </div> 
        */}
      </MyForm>
    </>
  );
};

export default SignNum;
