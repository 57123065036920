import { formatDate } from "@hooks/formatDate";
import cl from "./Head.module.scss";

const Head = ({order}) => {
  const updDate = formatDate(order?.created_at)
  return (
    <div className={cl.head}>
      <div className={cl.info}>
        <h3 className={cl.title}>Заказ от {updDate}</h3>
        <p className={cl.subtitle}>№ {order.id}</p>
      </div>
      <div className={cl.price}>{/*{order.total_price} KZT*/}Цена по запросу</div>
    </div>
  );
};

export default Head;
