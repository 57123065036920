import cl from './Textblock.module.scss'

const Textblock = () => {
  return (
    <div className={cl.block}>
      <p className={cl.first}>
        Мы предлагаем широкий ассортимент товаров — от последних
        инноваций в медицинской технике до необходимых расходных
        материалов, все это доступно в одном месте.
      </p>
      <p className={cl.sec}>
        Мы стремимся обеспечить простоту и комфорт во всем. Наша
        платформа обладает интуитивно понятным интерфейсом, позволяя
        легко находить, сравнивать и заказывать товары, экономя ваше
        время и силы. При этом мы гарантируем безопасность и
        прозрачность сделок, делая вас полностью уверенными в каждой
        покупке.
      </p>
    </div>
  )
}

export default Textblock