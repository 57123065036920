import MyPage from '@components/_UI/MyPage';
import MySection from '@components/_UI/MySection';
import Footer from '@components/footer/Footer';
import Header from '@components/header/Header';
import { useSelector } from 'react-redux';
import cl from './Seller.module.scss';
import Breadcrumbs from '@components/breadcrumbs/Breadcrumbs';
import NotFound from '@components/notFound/NotFound';
import Info from './info/Info';
import { sellerSel } from '@store/slices/seller/sellerSlice';
import Tabs from './tabs/Tabs';
import { Link } from 'react-router-dom';

const SellerPage = () => {
  const { seller } = useSelector(sellerSel);

  return (
    <>
      <Header />
      <MyPage
        metaTitle={`Qlixmed | ${seller?.seo_title}`}
        metaDescr={seller?.seo_description}
        metaKeywords={seller?.seo_keywords}
      >
        <MySection classNames={cl.product}>
          {/* {seller ? ( */}
            <>
              <Breadcrumbs />
              <Info seller={seller} />
              <Tabs outerCl={cl.bottom} />
            </>
          {/* ) : (
            <NotFound title={'Продавец не найден'} subtitle={'Вернитесь на главную страницу или обратитесь в службу поддержки'}>
              <Link className="btn btn-accent" to={'/'}>
                Вернуться на главную
              </Link>
            </NotFound>
          )} */}
        </MySection>
      </MyPage>

      <Footer />
    </>
  );
};

export default SellerPage;
