import React from 'react';
import AllActions from './allActions/AllActions';
import cl from './Products.module.scss';
import Product from './product/Product';

const Products = ({ cartProducts, toDelProduct }) => {
  return (
    <div className={cl.outer}>
      <AllActions toDelProduct={toDelProduct} />
      <div className={cl.products}>
        {cartProducts.map((product) => (
          <Product
            {...product}
            key={product.id}
            toDelProduct={toDelProduct}
          />
        ))}
      </div>
    </div>
  );
};

export default Products;
