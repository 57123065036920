import CheckRadio from '@components/_UI/CheckRadio';
import React from 'react';
import cl from './AllActions.module.scss';
import { useDispatch, useSelector } from 'react-redux';
import {
  cartSel,
  deselectAllCartProducts,
  selectAllCartProducts,
  setAllSelected,
} from '@store/slices/cart/cartSlice';
import { clsx } from 'clsx';

const AllActions = ({toDelProduct}) => {
  const dispatch = useDispatch();
  const { selectedCartProducts, cartProducts, allSelected } = useSelector(cartSel);


  const allCartProductsIds = [...cartProducts].map((item) => item.id);

  const onSelectAll = () => {
    if (selectedCartProducts.length !== allCartProductsIds.length) {
      dispatch(setAllSelected(true));
      dispatch(selectAllCartProducts(allCartProductsIds));
    } else {
      dispatch(setAllSelected(false));
      dispatch(deselectAllCartProducts());
    }
  };

  return (
    <div className={cl.actions}>
      <CheckRadio
        label={'Выбрать все'}
        classNames={cl.check}
        type={'checkbox'}
        value={allSelected}
        checked={selectedCartProducts.length === allCartProductsIds.length}
        onChange={onSelectAll}
      />
      <button
        className={clsx(cl.del + ' link link-red', {
          [cl.del__dis]: selectedCartProducts.length === 0,
        })}
        onClick={() => toDelProduct(null, false)}
      >
        Удалить выбранные
      </button>
    </div>
  );
};

export default AllActions;
