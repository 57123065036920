import MyPage from '@components/_UI/MyPage';
import MySection from '@components/_UI/MySection';
import Breadcrumbs from '@components/breadcrumbs/Breadcrumbs';
import Footer from '@components/footer/Footer';
import Header from '@components/header/Header';
import NotFound from '@components/notFound/NotFound';
import { aboutSel, getInfo } from '@store/slices/about/aboutSlice';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Advantages from './advantages/Advantages';
import Blockquote from './blockquote/Blockquote';
import Textblock from './textblock/Textblock';
import Mission from './mission/Mission';
import cl from './AboutPage.module.scss';

const AboutPage = () => {
  const dispatch = useDispatch();
  const { info } = useSelector(aboutSel);

  useEffect(() => {
    dispatch(getInfo());
  }, [dispatch]);

  return (
    <>
      <Header />
      <MyPage
        classNames={cl.about}
        metaTitle={`Qlixmed | ${info ? info.seo_title : 'Не найдено'}`}
        metaDescr={`${info ? info.seo_description : 'Не найдено'}`}
        metaKeywords={`${info ? info.seo_keywords : 'Не найдено'}`}
      >
        <MySection>
          {info ? (
            <>
              <Breadcrumbs />
              <div className={'flex flex-col gap-30'}>
                <h1 className={`title title-section`}>О Qlixmed</h1>
                <p className={cl.subtitle}>
                  Добро пожаловать в Qlixmed, у нас покупка медицинского
                  оборудования и материалов становится проще и доступнее для
                  всех. <br /> Наша платформа создана, чтобы каждый мог без
                  труда найти необходимое медицинское оборудование.
                </p>
                <Blockquote />
                <Textblock />
                <Advantages />
                <Mission />
              </div>
            </>
          ) : (
            <NotFound
              title={'Нет данных'}
              subtitle={'Не удалось получить данные'}
            />
          )}
        </MySection>
      </MyPage>
      <Footer />
    </>
  );
};

export default AboutPage;
