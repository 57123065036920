import React from 'react';
import { Link } from 'react-router-dom';
import img from '@assets/img/logo.svg';

const Logo = ({ classNames, ...props }) => {
  return (
    <Link className={`${classNames} logo`} to="/" {...props}>
      <img src={img} alt="Qlixmed logo" title='Qlixmed logo' width={167} height={41} />
    </Link>
  );
};

export default Logo;
