import MyPage from '@components/_UI/MyPage';
import MySection from '@components/_UI/MySection';
import { useLocation, useNavigate } from 'react-router-dom';
import Side from './side/Side';
import Personal from './personal/Personal';

import cl from './ProfilePage.module.scss';
import Payment from './payment/Payment';
import Addresses from './addresses/Addresses';
import useWindowWidth from '@hooks/windowWidth';
import { useEffect, useState } from 'react';
import { onHide } from '@hooks/toHide';
import { clsx } from 'clsx';
import Header from '@components/header/Header';
import Footer from '@components/footer/Footer';

const ProfilePage = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const windowWidth = useWindowWidth();
  const [blockIsOpen, setBlockIsOpen] = useState(false);

  useEffect(() => {
    if (location.pathname === '/profile') {
      setBlockIsOpen(false);
      onHide(true)
    }
  }, [location.pathname]);

  const onLink = () => {
    setBlockIsOpen(!blockIsOpen);
    onHide(blockIsOpen);
  };

  const goBack = () => {
    onLink();
    navigate('/profile');
  };

  useEffect(() => {
    if (windowWidth < 1025) {
      if (
        location.pathname.includes('profile/personal') ||
        location.pathname.includes('profile/payment') ||
        location.pathname.includes('profile/addresses')
      ) {
        onLink();
      }
    }
  }, []);

  return (
    <>
      <Header />
      <MyPage classNames={cl.profile}>
        <MySection innerCl={cl.inner}>
          <Side onLink={onLink} />
          {windowWidth < 1025 ? (
            <div className={clsx(cl.modal, { [cl.modal_active]: blockIsOpen })}>
              <div className={cl.head}>
                <button className={cl.goBack} onClick={goBack}>
                  <svg
                    className="ico-24 stroke"
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                  >
                    <path
                      d="M15 6L9 12L15 18"
                      stroke="currentColor"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </button>
                <h3 className={cl.head_title}>Личные данные</h3>
              </div>
              {location.pathname.includes('/profile/personal') ? (
                <Personal onLink={onLink} />
              ) : location.pathname.includes('/profile/payment') ? (
                <Payment />
              ) : (
                location.pathname.includes('/profile/addresses') && (
                  <Addresses />
                )
              )}
            </div>
          ) : location.pathname === '/profile' ? (
            <Personal onLink={onLink} />
          ) : location.pathname.includes('/profile/payment') ? (
            <Payment />
          ) : (
            location.pathname.includes('/profile/addresses') && <Addresses />
          )}
        </MySection>
      </MyPage>
      <Footer />
    </>
  );
};

export default ProfilePage;
