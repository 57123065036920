import React, { forwardRef, useRef } from "react";

import { Navigation } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import SliderBtn from "@components/_UI/slider/sliderBtn/SliderBtn";

import cl from "./Small.module.scss";
import { useSelector } from "react-redux";
import { productSel } from "@store/slices/product/productSlice";

const Small = forwardRef(
  (
    {
      classNames,
      sliderCl,
      slideCl,
      slides,
      slider2,
      slider3,
    },
    ref
  ) => {
    const {product} = useSelector(productSel);
    const goPrev = useRef(null);
    const goNext = useRef(null);

    return (
      <div className={`${classNames}  ${cl.outer}`}>
        <Swiper
          modules={[Navigation]}
          slidesPerView={3}
          spaceBetween={10}
          grabCursor={true}
          direction="vertical"
          speed={500}
          navigation={{
            prevEl: goPrev.current,
            nextEl: goNext.current,
          }}
          onSwiper={swiper => {
            setTimeout(() => {
              swiper.params.navigation.prevEl = goPrev.current;
              swiper.params.navigation.nextEl = goNext.current;

              swiper.navigation.destroy();
              swiper.navigation.init();
              swiper.navigation.update();
            });
          }}
          onSlideChange={swiper => {
            slider2.current.swiper.slideTo(swiper.activeIndex);
            slider3.current.swiper.slideTo(swiper.activeIndex);
          }}
          ref={ref}
          className={`${sliderCl} ${cl.slider} slider swiper`}
        >
          {slides.map((slide, i) => (
            <SwiperSlide
              className={`${slideCl} ${cl.slide} slider-slide swiper-slide`}
              key={slide.id}
            >
              <div className={cl.content}>
                <img className={cl.img} src={slide.path}   alt={product?.seo_title + " " + (i+1)} title={product?.seo_title + " " + (i+1)} />
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
        <div className={`${cl.nav} slider-nav`}>
          <SliderBtn classNames={`${cl.prev} prev`} ref={goPrev} />
          <SliderBtn classNames={`${cl.next} next`} ref={goNext} />
        </div>
      </div>
    );
  }
);

export default Small;
