import React from 'react';
import { Link } from 'react-router-dom';
import useBreadcrumbs from 'use-react-router-breadcrumbs';

import './Breadcrumbs.scss';
import { clsx } from 'clsx';

const routes = [
  { path: '/', breadcrumb: 'Главная' },
  { path: '/catalog', breadcrumb: 'Каталог' },
  { path: '/catalog/*' },
  {
    path: '/product',
    breadcrumb: 'Страница товара',
    props: { isHidden: true },
  },
  { path: '/product/:productSlug', breadcrumb: 'Страница товара' },
  {
    path: '/seller',
    breadcrumb: 'Страница продавца',
    props: { isHidden: true },
  },
  { path: 'cart', breadcrumb: 'Корзина' },
  { path: 'favors', breadcrumb: 'Избранное' },
  { path: 'favors/products', breadcrumb: 'Товары' },
  { path: 'favors/sellers', breadcrumb: 'Продавцы' },
  { path: 'chats/', breadcrumb: 'Чаты' },
  { path: 'chats/:uId', breadcrumb: 'Чат с продавцом' },
  { path: 'ordering', breadcrumb: 'Оформление заказа' },
  { path: 'orders', breadcrumb: 'Мои заказы' },
  { path: 'orders/:uId', breadcrumb: 'Заказ' },
  { path: '/faq', breadcrumb: 'Вопросы и ответы' },
  { path: '/partners', breadcrumb: 'Партнерам' },
  { path: '/about', breadcrumb: 'О компании' },
  { path: '/delivery', breadcrumb: 'Доставка' },
];

const Breadcrumbs = ({ classNames }) => {
  const crumbs = useBreadcrumbs(routes);

  return (
    <ul className={`crumbs ${classNames}`}>
      {crumbs.map((crumb) => (
        <li
          className={clsx('crumbs__item', {
            'crumbs__item-hidden': crumb.isHidden,
            'crumbs__item-pointless': crumb.pointerNone,
          })}
          key={crumb.match.pathname}
        >
          <Link
            className="crumbs__link link link-accent"
            to={crumb.match.pathname}
          >
            {crumb.breadcrumb}
          </Link>
        </li>
      ))}
    </ul>
  );
};

export default Breadcrumbs;
