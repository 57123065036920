import cl from './Provide.module.scss';
import ico1 from '@assets/img/provide-1.svg';
import ico2 from '@assets/img/provide-2.svg';
import ico3 from '@assets/img/provide-3.svg';
import ico4 from '@assets/img/provide-4.svg';

const Provide = () => {
  return (
    <div className={cl.wrapper}>
      <h3 className={cl.title}>Мы обеспечиваем:</h3>
      <div className={cl.block_outer}>
        <div className={cl.block}>
          <img className={cl.ico} src={ico1} alt="qlixmed ico" />
          <p className={cl.text}>
            Доступ к обширной базе потенциальных клиентов, заинтересованных в
            приобретении качественного медицинского оборудования и материалов.
          </p>
        </div>
        <div className={cl.block}>
          <img className={cl.ico} src={ico2} alt="qlixmed ico" />
          <p className={cl.text}>
            Возможность представления вашей продукции в максимально выгодном
            свете, благодаря нашим маркетинговым инструментам и оптимизированной
            системе размещения товаров.
          </p>
        </div>
        <div className={cl.block}>
          <img className={cl.ico} src={ico3} alt="qlixmed ico" />
          <p className={cl.text}>
            Прозрачные и честные условия сотрудничества, включая
            конкурентоспособные комиссионные ставки и четкие договорные
            обязательства.
          </p>
        </div>
        <div className={cl.block}>
          <img className={cl.ico} src={ico4} alt="qlixmed ico" />
          <p className={cl.text}>
            Поддержку на каждом этапе сотрудничества, начиная с момента подачи
            заявки и заканчивая послепродажным обслуживанием клиентов.
          </p>
        </div>
      </div>
    </div>
  );
};

export default Provide;
