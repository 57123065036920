import MyFilter from "@components/_UI/filter/MyFilter";
import CatalogPageFilterCategories from "./categories/CatalogPageFilterCategories";
// import CatalogPageFilterPrice from "./price/CatalogPageFilterPrice";
// import CatalogPageFilterSellers from "./sellers/CatalogPageFilterSellers";
// import CatalogPageFilterRating from "./rating/CatalogPageFilterRating";
import './CatalogPageFilter.scss';


const CatalogPageFilter = () => {
  return (
    <MyFilter classNames={'catalog__filter'}>
      <CatalogPageFilterCategories />
      {/* <CatalogPageFilterPrice /> */}
      {/* <CatalogPageFilterSellers />
      <CatalogPageFilterRating /> */}
    </MyFilter>
  );
};

export default CatalogPageFilter;
