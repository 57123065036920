
import cl from "./Stuff.module.scss";
import Head from "./head/Head";
import Products from "./products/Products";

const Stuff = ({order}) => {
  return (
    <div className={cl.stuff}>
      <Head order={order} />
      <Products order={order} />
    </div>
  );
};

export default Stuff;
