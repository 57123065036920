import cl from './Item.module.scss';
import Head from './head/Head';
import Output from './output/Output';
import Images from './images/Images';
import { Link } from 'react-router-dom';

const Item = ({ order }) => {
  return (
    <Link to={`/orders/${order.id}`} className={cl.outer}>
      <Head order={order} />
      <div className={cl.info}>
        <Output order={order} />
        <Images order={order} />
      </div>
    </Link>
  );
};

export default Item;
