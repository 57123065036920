
import { forwardRef } from 'react';
import { useDispatch } from 'react-redux';

const MyInput = forwardRef(({
  classNames,
  disabled,
  type = 'text',
  required = true,
  placeholder,
  value,
  setValue,
  ...props
}, ref) => {
  const dispatch = useDispatch();
  
  return (
    <input
      ref={ref}
      className={`${classNames} input`}
      type={type}
      value={value}
      placeholder={placeholder}
      disabled={disabled}
      onChange={e => dispatch(setValue(e.target.value))}
      {...props}
    />
  );
});

export default MyInput;