import React from 'react';
import cl from './Output.module.scss';
import { useDispatch, useSelector } from 'react-redux';
import { cartSel, setVideoModal } from '@store/slices/cart/cartSlice';
// import GrayWar from "@components/grayWar/GrayWar";
import MyBtn from '@components/_UI/btns/MyBtn';
import { onHide } from '@hooks/toHide';
import GrayWar from '@components/grayWar/GrayWar';

const Output = ({ toBuyProduct }) => {
  const dispatch = useDispatch();
  const { totalLength, videoModal } = useSelector(cartSel);

  const onOpenVideo = () => {
    dispatch(setVideoModal(true));
    onHide(videoModal);
  }

  return (
    <div className={cl.wrapper}>
      <div className={cl.output}>
        <div className={cl.to}>
          <MyBtn className={cl.btn + ' btn btn-accent'} onClick={toBuyProduct}>
            Перейти к оформлению
          </MyBtn>
          <GrayWar
          text={
            "Вы будете направлены на страницу связи с магазином для оформления товара"
          }
        />
        </div>
        <div className={cl.info}>
          <div className={cl.head}>
            <h5 className={cl.title}>Ваша корзина</h5>
            <p className={cl.info__count}>{totalLength} товар(-ов)</p>
          </div>
          {/* <ul className={cl.list}>
            <li className={cl.item}>
              <span className={cl.key}>Товары ({totalLength})</span>
              <span className={cl.val}>{totalPrice} ₸</span>
            </li>
            <li className={cl.item}>
              <span className={cl.key}>Скидка</span>
              <span className={cl.val}>0 ₸</span>
            </li>
          </ul> */}
        </div>
        <div className={cl.sum}>
          <div className={cl.head}>
            <h6 className={cl.title}>Общая стоимость</h6>
            <h6 className={cl.title + ` ${cl.all_price}`}>{/*{totalPrice} ₸*/} Цена по запросу</h6>
          </div>
        </div>
      </div>

      <div className={cl.openVideo_outer}>
        <p className={cl.openVideo_sup}>Возникают сложности с покупкой?</p>
        <button className={cl.openVideo + ' link link-accent'} onClick={onOpenVideo}>
          Просмотреть видео-инструкцию
        </button>
      </div>
    </div>
  );
};

export default Output;
