import React from 'react';
import cl from './Review.module.scss';
import Rating from '@components/rating/Rating';
import { formatDate } from '@hooks/formatDate';

const Review = ({ review }) => {
  console.log(review);
  const reviewDate = formatDate(review.created_at, true, true, true, false, false);

  return (
    <div className={cl.item}>
      <div className={cl.info}>
        <Rating stars={review.rating} ratingCl={cl.rating} rateCl={cl.rate} />
        <div>
          <p className={cl.user}>{review.customer.name}</p>
          <p className={cl.date}>{reviewDate}</p>
        </div>
      </div>
      <p className={cl.text}>
        {review.review}
      </p>
    </div>
  );
};

export default Review;
