import { Link } from 'react-router-dom';
import cl from './Success.module.scss';

const Success = () => {
  return (
    <div className={cl.block}>
      <svg
        className={cl.ico}
        width="177"
        height="177"
        viewBox="0 0 177 177"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M88.5 11.0625C109.038 11.0625 128.734 19.2211 143.257 33.7434C157.779 48.2658 165.938 67.9623 165.938 88.5C165.938 109.038 157.779 128.734 143.257 143.257C128.734 157.779 109.038 165.938 88.5 165.938C67.9623 165.938 48.2658 157.779 33.7434 143.257C19.2211 128.734 11.0625 109.038 11.0625 88.5C11.0625 67.9623 19.2211 48.2658 33.7434 33.7434C48.2658 19.2211 67.9623 11.0625 88.5 11.0625ZM78.8535 103.777L61.6513 86.5641C61.0346 85.9474 60.3025 85.4582 59.4968 85.1244C58.691 84.7907 57.8274 84.6189 56.9553 84.6189C56.0831 84.6189 55.2196 84.7907 54.4138 85.1244C53.6081 85.4582 52.8759 85.9474 52.2593 86.5641C51.0138 87.8095 50.3141 89.4987 50.3141 91.2601C50.3141 93.0214 51.0138 94.7107 52.2593 95.9561L74.163 117.86C74.778 118.48 75.5095 118.972 76.3155 119.307C77.1215 119.643 77.9859 119.816 78.859 119.816C79.7321 119.816 80.5966 119.643 81.4026 119.307C82.2085 118.972 82.9401 118.48 83.5551 117.86L128.911 72.4926C129.536 71.8784 130.033 71.1466 130.374 70.3394C130.715 69.5322 130.892 68.6655 130.896 67.7893C130.9 66.9131 130.731 66.0448 130.398 65.2344C130.065 64.4241 129.574 63.6877 128.955 63.0678C128.336 62.4479 127.6 61.9567 126.79 61.6225C125.98 61.2884 125.112 61.1179 124.236 61.121C123.36 61.124 122.493 61.3005 121.685 61.6403C120.878 61.98 120.145 62.4763 119.53 63.1005L78.8535 103.777Z"
          fill="#337BF9"
        />
      </svg>
      <div className={cl.info}>
        <h3 className={cl.title}>Заказ оформлен!</h3>
        <p className={cl.text}>Продавец свяжется с вами в ближайшее время.</p>
        <p className={cl.text}>
          Вы будете перенаправлены на{' '}
          <Link className="link-accent" to={'/orders'}>страницу заказов</Link>
        </p>
      </div>
    </div>
  );
};

export default Success;
