import cl from './MainInfo.module.scss';
import pageCl from '../ProductPage.module.scss';
import MyBtn from '@components/_UI/btns/MyBtn';

import sellerImg from '@assets/img/seller.webp';
import { useDispatch, useSelector } from 'react-redux';
import { productSel, setCurTab } from '@store/slices/product/productSlice';
import {
  addToCart,
  cartSel,
  setTotalLength,
} from '@store/slices/cart/cartSlice';
import { signSel } from '@store/slices/sign/signSlice';
import { Link } from 'react-scroll';
import SellerLink from '@components/sellerLink/SellerLink';
import Rating from '@components/rating/Rating';
import { useEffect, useState } from 'react';
import { loadStatus } from '@store/loadStatus';

const MainInfo = ({ toBuyProduct }) => {
  const dispatch = useDispatch();
  const { token, uid } = useSelector(signSel);
  const { product, tabs } = useSelector(productSel);
  const { totalLength, cartLoadStatus } = useSelector(cartSel);

  const [tableHtml, setTableHtml] = useState(null);

  useEffect(() => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(product.full_description, 'text/html');
    const rows = doc.querySelectorAll('tr');

    if (rows && rows.length > 0) {
      const outer = document.createElement('div');
      const table = document.createElement('table');
      const tbody = document.createElement('tbody');
      outer.appendChild(table);
      table.appendChild(tbody);

      for (let i = 0; i < Math.min(rows.length, 4); i++) {
        tbody.appendChild(rows[i]);
      }

      setTableHtml(outer.innerHTML);
    }
  }, [product]);

  const onAddToCart = () => {
    if (uid) {
      dispatch(
        addToCart({
          uid,
          product_id: product.id,
          quantity: 1,
        }),
      ).then(() => {
        const newTotalLen = totalLength > 0 ? totalLength + 1 : 1;
        console.log(newTotalLen);
        dispatch(setTotalLength(newTotalLen));
      });
    }
  };

  const toDescr = (key) => {
    const newTab = tabs.find((tab) => tab.key === key);
    dispatch(setCurTab(newTab));
  };

  return (
    <div className={cl.mainInfo}>
      <p className={cl.uId}>Код товара: {product.sku}</p>
      <h3 className={cl.title + ' title title-section'}>
        {product?.product_name}
      </h3>
      <Rating
        ratingCl={cl.rating}
        starCl={cl.star}
        rateCl={cl.rate}
        rateCount={product.review_count}
        stars={product.rating}
      />

      <div className={cl.block}>
        {tableHtml && (
          <>
            <h6 className={`${cl.block_title}`}>О товаре</h6>
            <div
              className={pageCl.table + ` ${cl.list}`}
              dangerouslySetInnerHTML={{ __html: tableHtml }}
            />
          </>
        )}

        <Link
          to="productTabs"
          spy={true}
          smooth={true}
          offset={-120}
          duration={500}
          className={`${
            cl.toDescr + ' link link-uderline link-accent'
          } link link-accent`}
          onClick={() => toDescr('descr')}
        >
          Перейти к описанию
        </Link>

        <SellerLink
          classNames={cl.seller}
          img={sellerImg}
          name={'Sony WH-1000XM4'}
          city={'Токио'}
          link={'/seller/sony-123'}
        />

        <p className={cl.price + ' title-section title'}>
          {
            /*product.price ? <>{product?.price} KZT</> : */ <>
              Цена по запросу
            </>
          }
        </p>

        <div className="flex flex-col gap-20">
          <div className={cl.btns}>
            <MyBtn
              classNames={`${cl.btn} w-full btn-accent`}
              onClick={() => onAddToCart(token, product.id, 1)}
              disabled={cartLoadStatus === loadStatus.pending}
            >
              {cartLoadStatus !== loadStatus.pending ? (
                <>
                  <svg
                    className="ico-20 stroke"
                    xmlns="http://www.w3.org/2000/svg"
                    width="23"
                    height="23"
                    viewBox="0 0 23 23"
                    fill="none"
                  >
                    <g clipPath="url(#clip0_895_5048)">
                      <path
                        d="M8.4375 20.625C8.95527 20.625 9.375 20.2053 9.375 19.6875C9.375 19.1697 8.95527 18.75 8.4375 18.75C7.91973 18.75 7.5 19.1697 7.5 19.6875C7.5 20.2053 7.91973 20.625 8.4375 20.625Z"
                        stroke="currentColor"
                        strokeWidth="1.40625"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M18.75 20.625C19.2678 20.625 19.6875 20.2053 19.6875 19.6875C19.6875 19.1697 19.2678 18.75 18.75 18.75C18.2322 18.75 17.8125 19.1697 17.8125 19.6875C17.8125 20.2053 18.2322 20.625 18.75 20.625Z"
                        stroke="currentColor"
                        strokeWidth="1.40625"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M0.9375 0.9375H4.6875L7.2 13.4906C7.28573 13.9222 7.52054 14.31 7.86332 14.5859C8.20611 14.8618 8.63503 15.0084 9.075 15H18.1875C18.6275 15.0084 19.0564 14.8618 19.3992 14.5859C19.742 14.31 19.9768 13.9222 20.0625 13.4906L21.5625 5.625H5.625"
                        stroke="currentColor"
                        strokeWidth="1.40625"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_895_5048">
                        <rect width="22.5" height="22.5" fill="currentColor" />
                      </clipPath>
                    </defs>
                  </svg>
                  <span>В корзину</span>
                </>
              ) : (
                <svg
                  className="ico-20 stroke"
                  xmlns="http://www.w3.org/2000/svg"
                  width="1em"
                  height="1em"
                  viewBox="0 0 24 24"
                >
                  <path
                    fill="none"
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M12 6V3m4.25 4.75L18.4 5.6M18 12h3m-4.75 4.25l2.15 2.15M12 18v3m-4.25-4.75L5.6 18.4M6 12H3m4.75-4.25L5.6 5.6"
                  ></path>
                </svg>
              )}
            </MyBtn>
            <MyBtn
              classNames={`${cl.btn} w-full btn-accent btn-lazur`}
              onClick={toBuyProduct}
            >
              <span>Купить сейчас</span>
            </MyBtn>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MainInfo;
