import { loadStatus } from '@store/loadStatus';
import axios from 'axios';
const { createSlice } = require('@reduxjs/toolkit');

const initialState = {
  sellerLoadStatus: 'idle',
  modalIsOpen: false,
  seller: null,
  similarsellers: null,

  tabs: [
    { key: 'products', val: 'Товары продавца' },
    { key: 'reviews', val: 'Отзывы (119)' },
  ],
  curTab: { key: 'products', val: 'Товары продавца' },
};

const sellerSlice = createSlice({
  name: 'seller',
  initialState,
  reducers: {
    setCurTab: (state, action) => {
      state.curTab = action.payload;
    },
  },
});

export const { setModalIsOpen, setseller, setCurTab } = sellerSlice.actions;
export const sellerSel = (state) => state.seller;

export default sellerSlice.reducer;
