import MyForm from '@components/_UI/MyForm';
import MyBtn from '@components/_UI/btns/MyBtn';
import MyInput from '@components/_UI/fields/MyInput';
import Logo from '@components/_UI/logo/Logo';
import { setTelField } from '@store/slices/profile/profileSlice';
import { clsx } from 'clsx';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
  getUser,
  sendCode,
  setCodeValue,
  setIsIn,
  setIsNum,
  setIsSign,
  setNumValue,
  signUser,
  signSel,
} from 'src/store/slices/sign/signSlice';

const SignIn = () => {
  const dispatch = useDispatch();
  const { numValue, cleanedNum, codeValue } = useSelector(signSel);
  const navigate = useNavigate();

  const onSubmit = async (e) => {
    e.preventDefault();
    dispatch(sendCode({ phone: cleanedNum, code: codeValue })).then(
      (action) => {
        try {
          const token = action.payload.token.split('|')[1];
          dispatch(getUser(token)).then(() => {
            dispatch(setCodeValue(''));
            dispatch(setIsSign(true));

            navigate('/profile');
          });
        } catch (err) {
          alert('Вы ввели неправильный код');
          console.log(err);
        }
      },
    );
    dispatch(setTelField(numValue));
  };

  const changeNumValue = () => {
    dispatch(setIsNum(false));
    dispatch(setIsIn(false));

    dispatch(setNumValue(''));
    dispatch(setTelField(''));
  };

  const [timeRemaining, setTimeRemaining] = useState(600); // Время в секундах (2 минуты)

  useEffect(() => {
    if (timeRemaining > 0) {
      // Если осталось время, устанавливаем интервал
      const timer = setInterval(() => {
        setTimeRemaining((prevTime) => prevTime - 1);
      }, 1000);

      // Очистка интервала таймера при размонтировании компонента
      return () => clearInterval(timer);
    }
  }, [timeRemaining]);

  const formatTime = (time) => {
    const minutes = Math.floor(time / 60);
    const seconds = time % 60;
    return `${minutes.toString().padStart(2, '0')}:${seconds
      .toString()
      .padStart(2, '0')}`;
  };

  const sendNewCode = (e) => {
    e.preventDefault();

    dispatch(signUser({ phone: cleanedNum }));
    dispatch(setCodeValue(''));
    setTimeRemaining(600);
  };

  return (
    <MyForm classNames="sign__form" onSubmit={onSubmit}>
      <Logo classNames={'sign__logo'} />
      <h3 className="form__title">Введите код</h3>
      <p className="form__subtitle">
        Мы отправили код подтверждения на номер <span>{numValue}</span>.{' '}
        <button
          className="form__subtitle-inner link link-accent"
          onClick={changeNumValue}
        >
          Изменить
        </button>
      </p>
      <MyInput
        classNames={'sign__input'}
        value={codeValue}
        setValue={setCodeValue}
        placeholder=""
      />

      {timeRemaining > 0 ? (
        <div className="sign__repeat dis">
          Получить новый код можно через:{' '}
          <span>{formatTime(timeRemaining)}</span>
        </div>
      ) : (
        <button className={'sign__repeat'} onClick={sendNewCode}>
          Получить новый код
        </button>
      )}

      <MyBtn
        classNames={clsx('sign__submit form__submit btn-accent', {
          dis: codeValue.length !== 4,
        })}
      >
        Войти
      </MyBtn>
    </MyForm>
  );
};

export default SignIn;
