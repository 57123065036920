import Product from "./product/Product"
import cl from './Products.module.scss'


const Products = ({order}) => {
  return (
    <div className={cl.products}>
      {
        order?.order_items?.map(product => (
          <Product product={product} key={product.id} />
        ))
      }
    </div>
  )
}

export default Products
