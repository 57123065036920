import MyPage from '@components/_UI/MyPage';
import MySection from '@components/_UI/MySection';
import Head from './head/Head';
import Items from './items/Items';
import Header from '@components/header/Header';
import Footer from '@components/footer/Footer';

const OrdersPage = () => {
  return (
    <>
      <Header />
      <MyPage>
        <MySection>
          <Head />
          <Items />
        </MySection>
      </MyPage>
      <Footer />
    </>
  );
};

export default OrdersPage;
