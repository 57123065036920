import { useDispatch, useSelector } from 'react-redux';
import useWindowWidth from '@hooks/windowWidth';
import {
  catalogSel,
  setCatTree,
  setCurCatRoot,
  setCurCatTree,
} from '@store/slices/catalog/catalogSlice';
import { Link, useMatch } from 'react-router-dom';
import CatItem from './catItem/CatItem';
import { clsx } from 'clsx';
import CategoriesMob from '@components/bar/catalog/modal/Modal';
import { headerSel, setCatalogIsOpen } from '@store/slices/header/headerSlice';
import { onHide } from '@hooks/toHide';

const CatalogPageFilterCategories = () => {
  const match = useMatch('/catalog/*');
  const nestedPath = match.params['*'];

  const windowWidth = useWindowWidth();

  const { catalogIsOpen } = useSelector(headerSel);
  const { curCatRoot, catTree, curCatTree, catRoots } = useSelector(catalogSel);
  const dispatch = useDispatch();

  const onCategories = () => {
    dispatch(setCatalogIsOpen(!catalogIsOpen));
    onHide(catalogIsOpen);
  };

  const onCategory = (curCat) => {
    dispatch(setCurCatTree(curCat));

    windowWidth < 1025 && onCategories();
  };

  return windowWidth < 1025 ? (
    <>
    <button className='catalog__head-filter-link' onClick={onCategories}>
      <span className='catalog__head-filter-link-title'>Категория</span>
      <span className='catalog__head-filter-link-subtitle'>{curCatTree?.category_name}</span>
    </button>
    <CategoriesMob
      onCategory={onCategories}
      catalogIsOpen={catalogIsOpen}
      setModalIsOpen={setCatalogIsOpen}
      catalogList={catRoots}
      curCatListItem={curCatRoot}
      setCatTree={setCatTree}
      setCurCatListItem={setCurCatRoot}
      catTree={catTree}
      curCatTree={curCatTree}
      setCurCatTree={setCurCatTree}
    />
    </>
  ) : (
    <ul className="caatalog__category-list">
      {curCatRoot && (
        <li className="catalog__category-item">
          <Link
            className={clsx('catalog__category-link link link-accent', {
              active: curCatRoot.slug === nestedPath,
            })}
            to={`/catalog/${curCatRoot.slug}`}
            onClick={() => onCategory(curCatRoot)}
          >
            <span className="catalog__category-text">
              {curCatRoot.category_name}{' '}
              <span className="catalog__category-count">
                ({curCatRoot.product_count})
              </span>
            </span>
          </Link>
          <ul className="catalog__category-sublist">
            {catTree?.map((cat) => (
              <CatItem
                category={cat}
                parentSlug={curCatRoot.slug}
                onCategory={onCategory}
                key={cat.id}
              />
            ))}
          </ul>
        </li>
      )}
    </ul>
  );
};

export default CatalogPageFilterCategories;
