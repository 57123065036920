import MyPage from '@components/_UI/MyPage';
import MySection from '@components/_UI/MySection';
import Breadcrumbs from '@components/breadcrumbs/Breadcrumbs';
import NotFound from '@components/notFound/NotFound';
import React, { useEffect } from 'react';

import cl from './CartPage.module.scss';
import Output from './output/Output';
import Products from './products/Products';
import { useDispatch, useSelector } from 'react-redux';
import {
  cartSel,
  deselectAllCartProducts,
  getCartProducts,
  removeCartProducts,
  setAllSelected,
  setDelModal,
  setRemovedProduct,
  setSelectedCartProducts,
  setTotalLength,
  setVideoModal,
} from '@store/slices/cart/cartSlice';
import {
  getUser,
  sendCode,
  setCodeValue,
  setIsNum,
  setIsSign,
  setNumValue,
  setUid,
  signSel,
  signUser,
} from '@store/slices/sign/signSlice';
import Header from '@components/header/Header';
import Footer from '@components/footer/Footer';
import { useLocation, useNavigate } from 'react-router-dom';
import { onHide } from '@hooks/toHide';
import DelModal from './delModal/DelModal';
import CartModal from '@components/ordering/cartModal/CartModal';
import {
  createOrder,
  ordersSel,
  setIsOrdered,
  setToBuyModal,
} from '@store/slices/orders/ordersSlice';
import { setTelField } from '@store/slices/profile/profileSlice';

import { v4 as uuidv4 } from 'uuid';
import VideoModal from '@components/videoModal/VideoModal';
import videoBanner from '@assets/img/video-banner.png';

const CartPage = () => {
  const dispatch = useDispatch();
  const { cartProducts, videoModal, delModal, selectedCartProducts } =
    useSelector(cartSel);
  const { token, uid, isSign, isNum, cleanedNum, numValue, codeValue } =
    useSelector(signSel);
  const { toBuyModal, isOrdered } = useSelector(ordersSel);

  const { pathname } = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (uid) {
      dispatch(getCartProducts({ uid: uid }));
    }
  }, [dispatch, uid, pathname]);

  const toDelProduct = (id, isSingle) => {
    onHide(delModal);
    dispatch(setDelModal(!delModal));
    if (!delModal && id && isSingle) {
      dispatch(setSelectedCartProducts(id));
    }

    if (delModal && !isSingle) {
      dispatch(deselectAllCartProducts());
    }
  };

  const onDelProduct = (cartIds) => {
    dispatch(removeCartProducts({ uid, cart_ids: cartIds })).then(() => {
      dispatch(setRemovedProduct({ cartIds }));
      toDelProduct();
      dispatch(deselectAllCartProducts());
      dispatch(setAllSelected(false));
    });
  };

  const toBuyProduct = () => {
    onHide(toBuyModal);
    dispatch(setIsOrdered(false));
    dispatch(setIsNum(false));
    dispatch(setNumValue(''));
    dispatch(setCodeValue(''));
    dispatch(setToBuyModal(true));
  };

  const onSignUser = (e) => {
    e.preventDefault();
    if (cleanedNum.length === 11) {
      dispatch(signUser({ phone: cleanedNum })).then(() => {
        dispatch(setIsNum(true));
        dispatch(setTelField(numValue));
      });
    }
  };

  const onSendCode = (e) => {
    e.preventDefault();

    dispatch(sendCode({ phone: cleanedNum, code: codeValue })).then(
      (action) => {
        const token = action.payload.token.split('|')[1];

        dispatch(getUser(token)).then(() => {
          dispatch(setCodeValue(''));
          dispatch(setIsSign(true));
        });
        onConfirm({ newToken: token });
      },
    );
    dispatch(setTelField(numValue));
  };

  const onSuccess = () => {
    const newUid = uuidv4();
    console.log(newUid);
    localStorage.setItem('uid', JSON.stringify(newUid));
    dispatch(setUid(newUid));
    getCartProducts({ uid: newUid });

    dispatch(setIsOrdered(true));

    setTimeout(() => {
      onHide(toBuyModal);
      dispatch(setToBuyModal(false));
      navigate('/orders');
    }, 3500);
  };

  const onConfirm = ({ newToken = false }) => {
    const newProducts = [...cartProducts].map((item) => ({
      product_id: item.product.id,
      unit_price: item.product.price,
      quantity: item.quantity,
    }));

    console.log(`newToken: ${newToken} \n token: ${token}`);

    dispatch(
      createOrder({
        token: newToken ? newToken : token,
        products: newProducts,
      }),
    ).then(() => {
      dispatch(setTotalLength(0))
      onSuccess();
    });
  };

  const onCancel = () => {
    onHide(toBuyModal);
    dispatch(setToBuyModal(false));
  };

  const toReNum = () => {
    dispatch(setIsNum(false));
    dispatch(setCodeValue(''));
    dispatch(setNumValue(''));
  };

  const onReSendNewCode = () => {
    dispatch(setCodeValue(''));
  };

  return (
    <>
      <Header />

      <MyPage>
        <MySection>
          <Breadcrumbs />
          {cartProducts && cartProducts.length > 0 ? (
            <>
              <div className={cl.head}>
                <h1 className={`title title-section ${cl.title}`}>Корзина</h1>
              </div>
              <div className={cl.flex}>
                <Products
                  cartProducts={cartProducts}
                  toDelProduct={toDelProduct}
                  onDelProduct={onDelProduct}
                />
                <Output toBuyProduct={toBuyProduct} />
              </div>
            </>
          ) : (
            <NotFound
              title={`Корзина пуста`}
              subtitle={`Воспользуйтесь поиском, чтобы найти всё, что нужно`}
            />
          )}
        </MySection>
      </MyPage>

      <VideoModal
        videoUrl={
          'https://www.youtube.com/watch?v=NRHCHVrq8nY&ab_channel=BaxterHealthcare-U.S.'
        }
        banner={videoBanner}
        modalIsOpen={videoModal}
        setModalIsOpen={setVideoModal}
      />

      <DelModal
        modalIsOpen={delModal}
        setModalIsOpen={setDelModal}
        onDelProduct={onDelProduct}
        toDelProduct={toDelProduct}
        selectedCartIds={selectedCartProducts}
      />

      <CartModal
        modalIsOpen={toBuyModal}
        setModalIsOpen={setToBuyModal}
        isNum={isNum}
        isSign={isSign}
        isOrdered={isOrdered}
        cleanedNum={cleanedNum}
        numValue={numValue}
        setNumValue={setNumValue}
        codeValue={codeValue}
        setCodeValue={setCodeValue}
        onCancel={onCancel}
        onConfirm={onConfirm}
        onSendCode={onSendCode}
        onSignUser={onSignUser}
        toReNum={toReNum}
        onReSendNewCode={onReSendNewCode}
      />

      <Footer />
    </>
  );
};

export default CartPage;
