import MyModal from '@components/_UI/modal/MyModal';
import outerCl from '../Ordering.module.scss';
import Auth from '../auth/Auth';
import cl from './ProductModal.module.scss';
import Success from '../success/Success';
import Confirm from '../confirm/Confirm';

const ProductModal = ({
  modalIsOpen,
  setModalIsOpen,
  item,
  cleanedNum,
  numValue,
  setNumValue,
  codeValue,
  setCodeValue,
  onSignUser,
  onSendCode,
  onConfirm,
  onCancel,
  toReNum,
  onReSendNewCode,
  isNum,
  isSign,
  isOrdered,
}) => {

  return (
    <MyModal
      modalCl={outerCl.modal}
      overlayCl={outerCl.overlay}
      innerCl={outerCl.inner}
      closeIsNeed={false}
      modalIsOpen={modalIsOpen}
      setModalIsOpen={setModalIsOpen}
    >
      {!isOrdered && item ? (
        <>
          <div className={cl.item__outer}>
            <h3 className={cl.title + ' title title-modal'}>Вы оформляете:</h3>
            <div className={cl.item}>
              <img
                className={cl.img}
                src={item.images[0].path}
                alt={item?.seo_title} title={item?.seo_title}
              />
              <div className={cl.info}>
                <p className={cl.price}>{item.price ? item.price + ' KZT' : 'Цена по запросу'} </p>
                <h5 className={cl.name}>{item.product_name}</h5>
                <ul className={cl.list}>
                  <li className={cl.list__item}>
                    <span>Код товара: {item.sku}</span>
                  </li>
                  <li className={cl.list__item}>
                    <span>Название магазина</span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          {!isSign ? (
            <Auth
            cleanedNum={cleanedNum}
              numValue={numValue}
              setNumValue={setNumValue}
              codeValue={codeValue}
              setCodeValue={setCodeValue}
              onSignUser={onSignUser}
              onSendCode={onSendCode}
              isNum={isNum}

  toReNum={toReNum}
  onReSendNewCode={onReSendNewCode}
            />
          ) : (
            <Confirm onConfirm={onConfirm} onCancel={onCancel} />
          )}
        </>
      ) : (
        <Success />
      )}
    </MyModal>
  );
};

export default ProductModal;
