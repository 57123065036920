import MyPage from '@components/_UI/MyPage';
import MySection from '@components/_UI/MySection';
import Breadcrumbs from '@components/breadcrumbs/Breadcrumbs';
import Footer from '@components/footer/Footer';
import Header from '@components/header/Header';
import NotFound from '@components/notFound/NotFound';
import { deliverySel, getInfo } from '@store/slices/delivery/deliverySlice';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

const DeliveryPage = () => {
  const dispatch = useDispatch();
  const { info } = useSelector(deliverySel);

  useEffect(() => {
    dispatch(getInfo());
  }, [dispatch]);

  return (
    <>
      <Header />
      <MyPage
        metaTitle={`Qlixmed | ${info ? 'Доставка' : 'Не найдено'}`}
        metaDescr={`${info ? info.seo_description : 'Не найдено'}`}
        metaKeywords={`${info ? info.seo_keywords : 'Не найдено'}`}
      >
        <MySection>
          {info ? (
            <>
            <Breadcrumbs />
            <div className={'flex flex-col gap-30'}>
              <h1 className="title title-section">Политика доставки Qlixmed</h1>
                <p>
                  В Qlixmed мы стремимся быстро и безопасно доставлять
                  высококачественное медицинское оборудование работникам и
                  учреждениям медицинской направленности.
                </p>
                <ul className="list-disc flex flex-col gap-16">
                  <li className="list-disc__item">
                    Бесплатная Доставка: Мы предлагаем бесплатную доставку для
                    заказов на сумму свыше 100.000 в пределах нашей зоны
                    обслуживания. Уточнить стоимость доставки можно у своего
                    менеджера.
                  </li>
                  <li className="list-disc__item">
                    График Доставки: Доставки осуществляются с 9:00 до 18:00 в
                    рабочие дни. Мы уведомим вас в день доставки с
                    ориентировочным временем прибытия.
                  </li>
                  <li className="list-disc__item">
                    Самовывоз: Позволяет вам забрать ваш заказ без стоимости
                    доставки. Вы получите уведомление по электронной почте,
                    когда ваш заказ будет готов, со всеми инструкциями по
                    получению.
                  </li>
                </ul>
                <div>
                <h3 className="title title-block">
                  Обработка Заказов и Отслеживание
                </h3>
                <ul className="list-disc flex flex-col gap-16">
                  <li className="list-disc__item">
                    Подтверждение и Трекинг: После отправки заказа вы получите
                    уведомление по электронной почте, включая трек-номер для
                    отслеживания его движения.
                  </li>
                  <li className="list-disc__item">
                    Запросы по Заказу: В случае задержек или проблем, наша
                    служба поддержки готова помочь. Контактная информация будет
                    предоставлена в вашем уведомлении о доставке.
                  </li>
                </ul>
                </div>
                <p>
                  Для получения более подробной информации свяжитесь с нашей
                  службой поддержки.
                </p>
            </div>
            </>
          ) : (
            <NotFound
              title={'Нет данных'}
              subtitle={'Не удалось получить данные'}
            />
          )}
        </MySection>
      </MyPage>
      <Footer />
    </>
  );
};

export default DeliveryPage;
