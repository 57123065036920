import cl from './Product.module.scss';

const Product = ({ product }) => {
  return (
    <div className={cl.product}>
      <img
        className={cl.img}
        src={product?.product.image.path}
        alt={product?.product.product_name}
        title={product?.product.product_name}
      />
      <div className={`${cl.info_output}`}>
        <div className={cl.info}>
          <h6 className={cl.name}>{product?.product?.product_name}</h6>
          <ul className={cl.list}>
            <li className={cl.list_item}>Код товара: {product?.id}</li>
            <li className={cl.list_item}>Дополнительно</li>
            <li className={cl.list_item}>{product?.quantity} шт.</li>
          </ul>
        </div>
        <div className={cl.output}>
          <h6 className={cl.price}>
            {/*{product?.price} KZT*/}Цена по запросу
          </h6>
          {/* <p className={cl.date}>Доставка: {updDate}</p> */}
        </div>
      </div>
    </div>
  );
};

export default Product;
