const { createSlice } = require("@reduxjs/toolkit");

const initialState = {
  catalogIsActive: false,
};

const barSlice = createSlice({
  name: "bar",
  initialState,
  reducers: {
    setCatalogIsActive: (state, action) => {
      state.catalogIsActive = action.payload;
    },
  },
});

export const { setCatalogIsActive } = barSlice.actions;
export const barSel = state => state.bar;

export default barSlice.reducer;
