import { loadStatus } from "@store/loadStatus";
import axios from "axios";

const { createSlice, createAsyncThunk } = require("@reduxjs/toolkit");

export const getSellerProducts = createAsyncThunk(
  "sellerProducts/getSellerProducts",
  async ({ token }) => {
    try {
      const config = {
        headers: { Authorization: `Bearer ${token}` },
      };
      const { data } = await axios.get(
        `${process.env.REACT_APP_API_URL}sellerProducts`,
        config
      );

      return data;
    } catch (err) {
      console.log(err);
    }
  }
);

export const createProduct = createAsyncThunk(
  "sellerProducts/createProduct",
  async ({ token, name, description, price, status }) => {
    try {
      const config = {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${token}`,
        },
      };

      const { data } = await axios.post(
        `${process.env.REACT_APP_API_URL}addNewProduct`,
        {
          product_name: String(name),
          description: String(description),
          price: Number(price),
          product_status_id: Number(status),
        },
        config
      );

      console.log(data);
      return data;
    } catch (err) {
      console.log(err);
    }
  }
);

export const deleteProduct = createAsyncThunk(
  "sellerProducts/deleteProduct",
  async ({ token, id }) => {
    try {
      const config = {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${token}`,
        },
      };

      await axios.delete(
        `${process.env.REACT_APP_API_URL}deleteProduct/${id}`,
        config
      );

      return id;
    } catch (err) {
      console.log(err);
    }
  }
);

export const uploadImgOnProduct = createAsyncThunk(
  "sellerProducts/uploadImgOnProduct",
  async ({ token, id, image }) => {
    console.log(image);
    try {
      const formData = new FormData();
      formData.append('image', image);

      console.log(formData) // 'image' это файл, не строка Base64

      const config = {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${token}`,
        },
      };

      await axios.post(
        `${process.env.REACT_APP_API_URL}AddProduct/${id}/upload`,
        formData,
        config
      );

      return id;
    } catch (err) {
      console.log(err);
    }
  }
);

const initialState = {
  sellerProducts: [],
  sellerProductsLoadStatus: "idle",
  searchValue: "",
  sortOptions: [
    { value: "all", label: "Все товары" },
    { value: 2, label: "В продаже" },
    { value: 1, label: "Сняты с продажи" },
    { value: 3, label: "Нет в наличии" },
  ],
  selectedSort: { value: "all", label: "Все товары" },

  sortById: true,
};

const sellerProductsSlice = createSlice({
  name: "sellerProducts",
  initialState,
  reducers: {
    setSearchValue: (state, action) => {
      state.searchValue = action.payload;
    },
    setSelectedSort: (state, action) => {
      state.selectedSort = action.payload;
    },
    setSortById: (state, action) => {
      state.sortById = action.payload;
    },
    setFilteredSellerProducts: (state, action) => {
      state.sellerProducts = action.payload;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(getSellerProducts.pending, state => {
        state.sellerProductsLoadStatus = loadStatus.pending;
        state.sellerProducts = [];
      })
      .addCase(getSellerProducts.fulfilled, (state, action) => {
        state.sellerProductsLoadStatus = loadStatus.fulfilled;
        state.sellerProducts = action.payload;
      })
      .addCase(getSellerProducts.rejected, state => {
        state.sellerProductsLoadStatus = loadStatus.rejected;
        state.sellerProducts = [];
      });

    builder
      .addCase(createProduct.pending, state => {
        state.productLoadStatus = loadStatus.pending;
      })
      .addCase(createProduct.fulfilled, (state, action) => {
        state.productLoadStatus = loadStatus.fulfilled;
        state.sellerProducts.push({ ...action.payload.product });
      })
      .addCase(createProduct.rejected, state => {
        state.productLoadStatus = loadStatus.rejected;
      });

    builder
      .addCase(deleteProduct.pending, state => {
        state.productLoadStatus = loadStatus.pending;
      })
      .addCase(deleteProduct.fulfilled, (state, action) => {
        state.productLoadStatus = loadStatus.fulfilled;

        state.sellerProducts = state.sellerProducts.filter(
          product => product.id !== action.payload
        );
      })
      .addCase(deleteProduct.rejected, state => {
        state.productLoadStatus = loadStatus.rejected;
      });
  },
});

export const sellerProductsSel = state => state.sellerProducts;
export const {
  setSelectedSort,
  setSearchValue,
  setSortById,
  setFilteredSellerProducts,
} = sellerProductsSlice.actions;

export default sellerProductsSlice.reducer;
