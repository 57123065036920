import { loadStatus } from '@store/loadStatus';
import axios from 'axios';

const { createSlice, createAsyncThunk } = require('@reduxjs/toolkit');

export const signUser = createAsyncThunk(
  'sign/signUser',
  async (credentials, { rejectWithValue }) => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    };
    try {
      const res = await axios.post(
        `${process.env.REACT_APP_API_URL}v1/auth/sendOtp`,
        credentials,
        config,
      );
      return res.data;
    } catch (err) {
      console.log(err);
      return rejectWithValue(err.message);
    }
  },
);

export const sendCode = createAsyncThunk(
  'sign/sendCode',
  async (credentials, { rejectWithValue }) => {
    try {
      const res = await axios.post(
        `${process.env.REACT_APP_API_URL}v1/auth/verify`,
        credentials,
      );
      return res.data;
    } catch (err) {
      console.log(err);
      return rejectWithValue(err.message);
    }
  },
);

export const getUser = createAsyncThunk(
  'sign/getUser',
  async (token, { rejectWithValue }) => {
    try {
      const config = {
        headers: { Authorization: `Bearer ${token}` },
      };

      const bodyParameters = {
        key: 'value',
      };

      const res = await axios.post(
        `${process.env.REACT_APP_API_URL}v1/profile`,
        bodyParameters,
        config,
      );
      return res.data;
    } catch (err) {
      console.log(err);
      return rejectWithValue(err.message);
    }
  },
);

export const updateUser = createAsyncThunk(
  'sign/updateUser',
  async ({ token,  bodyParams }) => {
    try {
      const config = {
        headers: { Authorization: `Bearer ${token}` },
      };
      const res = await axios.post(
        `${process.env.REACT_APP_API_URL}v1/profile`,
        bodyParams,
        config,
      );
      return res.data;
    } catch (err) {
      console.log(err);
    }
  },
);

const initialState = {
  user: {},
  token: '',
  uid: null,
  error: false,
  isSign: false,
  isNum: false,
  isIn: false,
  isUp: false,
  signLoadStatus: 'idle',
  numValue: '',
  cleanedNum: '',
  codeValue: '',
  nameValue: '',
  emailValue: '',
};

const signSlice = createSlice({
  name: 'sign',
  initialState,
  reducers: {
    setIsSign: (state, action) => {
      state.isSign = action.payload;
    },
    setIsNum: (state, action) => {
      state.isNum = action.payload;
    },
    setIsIn: (state, action) => {
      state.isIn = action.payload;
    },
    setIsUp: (state, action) => {
      state.isUp = action.payload;
    },
    setNumValue: (state, action) => {
      state.numValue = action.payload;
      state.cleanedNum = action.payload.replace(/\D/g, '');
    },
    setCodeValue: (state, action) => {
      state.codeValue = action.payload;
    },
    setNameValue: (state, action) => {
      state.nameValue = action.payload;
    },
    setEmailValue: (state, action) => {
      state.emailValue = action.payload;
    },

    setToken: (state, action) => {
      state.token = action.payload;
    },
    setUser: (state, action) => {
      state.user = action.payload;
    },

    setUid: (state, action) => {
      state.uid = action.payload;
    },

    signOut: (state) => {
      state.user = {};
      state.token = '';
      state.isSign = false;
      state.isNum = false;
      state.isIn = false;
      state.isUp = false;
      state.isSign = false;
      state.isSign = false;
      state.signLoadStatus = 'idle';
      state.numValue = '';
      state.codeValue = '';

      localStorage.removeItem('token');
      localStorage.removeItem('user');
    },
  },

  extraReducers: (builder) => {
    builder
      .addCase(signUser.pending, (state) => {
        state.signLoadStatus = loadStatus.pending;
      })
      .addCase(signUser.fulfilled, (state, action) => {
        state.signLoadStatus = loadStatus.fulfilled;
        state.isNum = true;
        if (action.payload.is_new_user) {
          state.isUp = true;
        } else {
          state.isIn = true;
        }
      })
      .addCase(signUser.rejected, (state, action) => {
        state.signLoadStatus = loadStatus.rejected;
        console.log(`rejected: ${action.payload}`);
      });

    builder
      .addCase(sendCode.pending, (state) => {
        state.signLoadStatus = loadStatus.pending;
      })
      .addCase(sendCode.fulfilled, (state, action) => {
        state.signLoadStatus = loadStatus.fulfilled;
        const token = action.payload.token.split('|')[1];
        state.token = token;

        state.error = false;

        localStorage.setItem('token', JSON.stringify(token));
      })
      .addCase(sendCode.rejected, (state, action) => {
        state.signLoadStatus = loadStatus.rejected;
        console.log(`rejected: ${action.payload}`);
        state.error = true;
      });

    builder
      .addCase(getUser.pending, (state) => {
        state.signLoadStatus = loadStatus.pending;
        state.user = {};
      })
      .addCase(getUser.fulfilled, (state, action) => {
        state.signLoadStatus = loadStatus.fulfilled;
        state.user = action.payload;

        localStorage.setItem('user', JSON.stringify(action.payload));
      })
      .addCase(getUser.rejected, (state, action) => {
        state.signLoadStatus = loadStatus.rejected;
        state.user = {};
        console.log(`rejected: ${action.payload}`);
      });

    builder
      .addCase(updateUser.pending, (state) => {
        state.signLoadStatus = loadStatus.pending;
        state.user = {};
      })
      .addCase(updateUser.fulfilled, (state, action) => {
        state.signLoadStatus = loadStatus.fulfilled;
        state.user = action.payload;

        localStorage.setItem('user', JSON.stringify(action.payload));
      })
      .addCase(updateUser.rejected, (state, action) => {
        state.signLoadStatus = loadStatus.rejected;
        state.user = {};
        state.error = action.error.message;
      });
  },
});

export const signSel = (state) => state.sign;

export const {
  setIsSign,
  setIsNum,
  setIsIn,
  setIsUp,
  setNumValue,
  setCodeValue,
  setNameValue,
  setEmailValue,

  setToken,
  setUser,
  setUid,
  signOut,
} = signSlice.actions;

export default signSlice.reducer;
