import MyModal from '@components/_UI/modal/MyModal';
import cl from './Modal.module.scss';
import { useDispatch } from 'react-redux';
import CatItem from './catItem/CatItem';
import { clsx } from 'clsx';
import { enableScroll } from '@hooks/enableScroll';

const Modal = ({
  catalogIsOpen,
  onCategory,
  setModalIsOpen,
  catalogList,
  setCurCatTree,
  setCatTree,
  curCatListItem,
  setCurCatListItem,
}) => {
  const dispatch = useDispatch();

  const onBack = () => {
    dispatch(setModalIsOpen(false));
  };

  const onMainClick = (item) => {
    dispatch(setCatTree(item.sub_categories));
    dispatch(setCurCatListItem(item));
  };

  const onSub = (sub) => {
    dispatch(setCurCatTree(sub));
    dispatch(setModalIsOpen(false));
    enableScroll();
  };

  return (
    <MyModal
      overlayCl={cl.overlay}
      modalCl={cl.modal}
      innerCl={cl.inner}
      closeIsNeed={false}
      modalIsOpen={catalogIsOpen}
      setModalIsOpen={setModalIsOpen}
    >
      <div className={cl.head}>
        <button className={`${cl.close} link link-accent`} onClick={onBack}>
          <svg
            className="ico-24 stroke"
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
          >
            <path
              d="M15 6L9 12L15 18"
              stroke="#337BF9"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </button>
        <h4 className={cl.title}>Категории</h4>
      </div>
      <div className={cl.content}>
        <ul className={cl.list}>
          {catalogList?.map((item) => (
            <li className={cl.item} key={item.id}>
              <button
                className={clsx(cl.item__btn, {
                  [cl.item__btn_active]: curCatListItem?.id === item.id,
                })}
                onClick={() => onMainClick(item)}
              >
                <span>
                  {item.category_name}
                  <span className="catalog__category-count">
                    ({item.product_count})
                  </span>
                </span>
                <svg
                  className="ico-12 stroke"
                  width="7"
                  height="13"
                  viewBox="0 0 7 13"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M1 1L6 6.5L1 12" stroke="#CCCCCC" />
                </svg>
              </button>
              <ul
                className={clsx(cl.sublist, {
                  [cl.sublist_active]: curCatListItem?.id === item.id,
                })}
              >
                {item.sub_categories?.map((subitem) => (
                  <CatItem
                    category={subitem}
                    parentSlug={item.slug}
                    onCategory={onSub}
                    key={subitem.id}
                  />
                ))}
              </ul>
            </li>
          ))}
        </ul>
      </div>
    </MyModal>
  );
};

export default Modal;
