
import cl from "./Product.module.scss";

const Product = ({product}) => {
  return (
    <div className={cl.product}>
      <img className={cl.img} src={product?.image?.path} alt={product?.seo_title} title={product?.seo_title} />
      <div className={`${cl.info_output}`}>
        <div className={cl.info}>
          <h6 className={cl.name}>{product?.product_name}</h6>
          <ul className={cl.list}>
            <li className={cl.list_item}>Код товара: {product?.id}</li>
          </ul>
        </div>
        <div className={cl.output}>
          {/* <h6 className={cl.price}>{product?.price} KZT</h6> */}
        </div>
      </div>
    </div>
  );
};

export default Product;
