import React, { useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { Swiper, SwiperSlide } from 'swiper/react';
import { EffectFade, Mousewheel, Navigation, Pagination } from 'swiper/modules';
import videoBanner from '@assets/img/video-banner.png';
import playIco from '@assets/img/playIco.svg'

import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/effect-fade';

import './HomePageHero.scss';
import SliderBtn from '@components/_UI/slider/sliderBtn/SliderBtn';

import heroHighImg from '@assets/img/hero-high.webp';
import heroLowSliderImg from '@assets/img/hero-low-slider.webp';
import heroLowBlockImg1 from '@assets/img/hero-low-block-1.webp';
import heroLowBlockImg2 from '@assets/img/hero-low-block-2.webp';
import ReactPlayer from 'react-player';

const HomePageHero = () => {
  const [slides] = useState([
    {
      id: 1,
      title: 'Product Title 1',
      img: heroLowSliderImg,
      text: 'In biomedical signal processing, gas monito',
      link: '#',
    },
    {
      id: 2,
      title: 'Product Title 2',
      img: heroHighImg,
      text: 'In biomedical signal processing, gas monito',
      link: '#',
    },
    {
      id: 3,
      title: 'Product Title 3',
      img: heroLowBlockImg1,
      text: 'In biomedical signal processing, gas monito',
      link: '#',
    },
    {
      id: 4,
      title: 'Product Title 4',
      img: heroLowBlockImg2,
      text: 'In biomedical signal processing, gas monito',
      link: '#',
    },
  ]);
  const [playing, setPlaying] = useState(false);

  const goPrev = useRef(null);
  const goNext = useRef(null);

  const onPlayVideo = () => {
    setPlaying(true);
  };

  return (
    <section className="hero">
      <div className="container">
        <div className="hero__inner">
          <div className="hero__top">
            <div className="hero__slider-outer">
              <Swiper
                className="hero__slider"
                modules={[Navigation, Pagination, Mousewheel, EffectFade]}
                grabCursor={true}
                speed={500}
                loop={true}
                pagination={{
                  clickable: true,
                }}
                navigation={{
                  prevEl: goPrev.current,
                  nextEl: goNext.current,
                }}
                mousewheel={{
                  forceToAxis: true,
                }}
                onSwiper={(swiper) => {
                  setTimeout(() => {
                    swiper.params.navigation.prevEl = goPrev.current;
                    swiper.params.navigation.nextEl = goNext.current;

                    swiper.navigation.destroy();
                    swiper.navigation.init();
                    swiper.navigation.update();
                  });
                }}
                effect="fade"
              >
                {slides.map((slide) => (
                  <SwiperSlide
                    className="hero__slider-slide slider-slide swiper-slide"
                    key={slide.id}
                  >
                    <Link
                      className="hero__block hero__slider-content slider-content"
                      to={'#'}
                    >
                      <img
                        className="hero__block-bg hero__block-bg"
                        loading="lazy"
                        src={slide.img}
                        width="1300"
                        height="160"
                        alt="img"
                        title="hero img"
                      />
                      <div className="hero__block-info">
                        <p className="hero__block-suptitle">{slide.title}</p>
                        <h3 className="hero__block-title">{slide.text}</h3>
                      </div>
                    </Link>
                  </SwiperSlide>
                ))}
                <SliderBtn
                  classNames="hero__slider-nav-btn prev"
                  ref={goPrev}
                />
                <SliderBtn
                  classNames="hero__slider-nav-btn next"
                  ref={goNext}
                />
              </Swiper>
            </div>
            <div className="hero__video-outer">
              <ReactPlayer
                url={"https://www.youtube.com/watch?v=NRHCHVrq8nY&ab_channel=BaxterHealthcare-U.S."}
                light={videoBanner}
                playing={playing}
                controls={true}
                onPlay={onPlayVideo}
                height={'100%'}
                width={'100%'}
                playIcon={<img className={'hero__video-playIco'} src={playIco} alt='play ico' />}
                onClickPreview={onPlayVideo}
              />
            </div>
          </div>

          {/* <div className="hero__deals-outer">
            <h5 className="hero__deals-suptitle">Совершено сделок за месяц:</h5>
            <div className="hero__deals">
              <span className="hero__deals-ellipse"></span>
              <span className="hero__deals-count">5 670</span>
            </div>
          </div> */}
        </div>
      </div>
    </section>
  );
};

export default HomePageHero;
