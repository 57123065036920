import { loadStatus } from '@store/loadStatus';
import axios from 'axios';

const { createSlice, createAsyncThunk } = require('@reduxjs/toolkit');

export const getOrders = createAsyncThunk(
  'orders/getOrders',
  async ({ token }, { rejectWithValue }) => {
    try {
      const config = {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      };

      const { data } = await axios.get(
        `${process.env.REACT_APP_API_URL}v1/order`,
        config,
      );

      console.log(data);
      return data;
    } catch (err) {
      console.log(`ошибка получения заказов: ${err}`);
      return rejectWithValue(err.message);
    }
  },
);

export const createOrder = createAsyncThunk(
  'orders/createOrder',
  async ({ token, products }, { rejectWithValue }) => {
    try {
      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      };

      const { data } = await axios.post(
        `${process.env.REACT_APP_API_URL}v1/order`,
        products,
        config,
      );

      console.log(data);
      return data;
    } catch (err) {
      console.log(`ошибка оформления заказа: ${err}`);
      return rejectWithValue(err.message);
    }
  },
);

const initialState = {
  ordersLoadStatus: 'idle',
  sorts: [
    { value: 'all', label: 'Все' },
    { value: 'payPending', label: 'Ожидают оплаты' },
    { value: 'onWork', label: 'В работе' },
    { value: 'completed', label: 'Выполненные' },
    { value: 'canceled', label: 'Отмененные' },
  ],
  selectedSort: { value: 'all', label: 'Все' },
  orderingProducts: [],
  orders: [],
  toBuyModal: false,
  isOrdered: false,
};

const ordersSlice = createSlice({
  name: 'orders',
  initialState,
  reducers: {
    setSelectedSort: (state, action) => {
      state.selectedSort = action.payload;
    },

    setToBuyModal: (state, action) => {
      state.toBuyModal = action.payload;
    },

    setIsOrdered: (state, action) => {
      state.isOrdered = action.payload;
    },
  },

  extraReducers: (builder) => {
    builder
      .addCase(getOrders.pending, (state) => {
        state.ordersLoadStatus = loadStatus.pending;
      })
      .addCase(getOrders.fulfilled, (state, action) => {
        state.ordersLoadStatus = loadStatus.fulfilled;
        state.orders = action.payload;
      })
      .addCase(getOrders.rejected, (state, action) => {
        state.ordersLoadStatus = loadStatus.rejected;
        console.log(`rejected: ${action.payload}`);
      });

    builder
      .addCase(createOrder.pending, (state) => {
        state.ordersLoadStatus = loadStatus.pending;
      })
      .addCase(createOrder.fulfilled, (state, action) => {
        state.ordersLoadStatus = loadStatus.fulfilled;
        console.log(`fulfilled: `, action);
        state.isOrdered = true;
      })
      .addCase(createOrder.rejected, (state, action) => {
        state.ordersLoadStatus = loadStatus.rejected;
        console.log(`rejected: `, action);
        state.isOrdered = false;
      });
  },
});

export const ordersSel = (state) => state.orders;
export const { setSelectedSort, setToBuyModal, setIsOrdered } =
  ordersSlice.actions;

export default ordersSlice.reducer;
