import MyBtn from '@components/_UI/btns/MyBtn';
import MyModal from '@components/_UI/modal/MyModal';
import cl from './DelModal.module.scss';

const DelModal = ({
  modalIsOpen,
  setModalIsOpen,
  onDelProduct,
  toDelProduct,
  selectedCartIds,
}) => {
  return (
    <MyModal
      modalIsOpen={modalIsOpen}
      setModalIsOpen={setModalIsOpen}
      modalCl={cl.modal}
      innerCl={cl.inner}
      closeIsNeed={false}
    >
      <div>
        <h3 className="title title-modal">Подтверждение удаления</h3>
        <p className="subtitle subtitle-modal">Вы удаляете товар из корзины.</p>
      </div>
      <div className="flex w-full gap-12">
        <MyBtn classNames={'btn-accent w-full'} onClick={() => onDelProduct(selectedCartIds)}>
          Удалить
        </MyBtn>
        <MyBtn
          classNames={'btn-accent btn-lazur w-full'}
          onClick={() => toDelProduct(null, false)}
        >
          Отмена
        </MyBtn>
      </div>
    </MyModal>
  );
};

export default DelModal;
