import { Link, useLocation, useNavigate } from "react-router-dom";
import { clsx } from "clsx";
import cl from "./Side.module.scss";
import Head from "./head/Head";
import { signOut } from "@store/slices/sign/signSlice";
import { useDispatch } from "react-redux";
import useWindowWidth from "@hooks/windowWidth";
import Arrow from "./arrow/Arrow";

const Side = ({ onLink }) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const windowWidth = useWindowWidth();

  const defLinkCl = cl.list_link + " link";
  const activeLinkCl = `${cl.list_link} link ${cl.list_link_active}`;

  const onSignOut = () => {
    dispatch(signOut());
    navigate("/");
  };

  return (
    <div className={cl.side}>
      <Head />
      <div className={cl.list_outer}>
        <nav className={cl.list_nav}>
          <h6 className={cl.list_title + " title"}>Аккаунт</h6>
          <ul className={cl.list}>
            <li className={cl.list_item}>
              <Link
                to="/profile/personal"
                className={clsx(defLinkCl, {
                  [activeLinkCl]:
                    location.pathname.includes("profile/personal"),
                })}
                onClick={onLink}
              >
                Личные данные
                {windowWidth < 1025 ? <Arrow /> : <></>}
              </Link>
            </li>
            {/* <li className={cl.list_item}>
              <Link
                to="/profile/payment"
                className={clsx(defLinkCl, {
                  [activeLinkCl]: location.pathname.includes("profile/payment"),
                  [cl.list_link_disabled]: true,
                })}
                onClick={onLink}
              >
                Способы оплаты
                {windowWidth < 1025 ? <Arrow /> : <></>}
              </Link>
            </li>
            <li className={cl.list_item}>
              <Link
                to="/profile/addresses"
                className={clsx(defLinkCl, {
                  [activeLinkCl]:
                    location.pathname.includes("profile/addresses"),
                  [cl.list_link_disabled]: true,
                })}
                onClick={onLink}
              >
                Адреса доставки
                {windowWidth < 1025 ? <Arrow /> : <></>}
              </Link>
            </li> */}
          </ul>
        </nav>
        <nav className={cl.list_nav}>
          <h6 className={cl.list_title}>Заказы</h6>
          <ul className={cl.list}>
            <li className={cl.list_item}>
              <Link to="/cart" className={`link ${cl.list_link}`}>
                Моя корзина
                {windowWidth < 1025 ? <Arrow /> : <></>}
              </Link>
            </li>
            <li className={cl.list_item}>
              <Link to="/orders" className={`link ${cl.list_link}`}>
                Мои заказы
                {windowWidth < 1025 ? <Arrow /> : <></>}
              </Link>
            </li>
            {/* <li className={cl.list_item}>
              <Link to="/chats" className={`link ${cl.list_link}`}>
                Сообщения
                {windowWidth < 1025 ? <Arrow /> : <></>}
              </Link>
            </li>
            <li className={cl.list_item}>
              <Link to="/favors/products" className={`link ${cl.list_link}`}>
                Избранное
                {windowWidth < 1025 ? <Arrow /> : <></>}
              </Link>
            </li> */}
          </ul>
        </nav>
      </div>
      <button
        className={clsx(`${cl.signOut}`, {
          "btn btn-red": windowWidth < 1025,
          "link link-red": windowWidth >= 1025,
        })}
        onClick={onSignOut}
      >
        Выйти из аккаунта
      </button>
    </div>
  );
};

export default Side;
