import React, { useEffect } from "react";

import "./HomePagePopular.scss";

import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/grid";
import { useDispatch, useSelector } from "react-redux";
import {
  getPopularProducts,
  productsSel,
} from "src/store/slices/products/productsSlice";
import Products from "@components/products/Products";
import { headerSel, setCatalogIsOpen } from "@store/slices/header/headerSlice";
import { enableScroll } from "@hooks/enableScroll";
import { disableScroll } from "@hooks/disableScroll";

const HomePagePopular = () => {
  const { popularProducts } = useSelector(productsSel);
  const { catalogIsOpen } = useSelector(headerSel);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getPopularProducts());
  }, [dispatch]);

  const toggleCatalog = () => {
    dispatch(setCatalogIsOpen(!catalogIsOpen));
    if (catalogIsOpen) {
      enableScroll();
    } else {
      disableScroll();
    }
  };

  return (
    <Products
      title={"Популярные товары"}
      products={popularProducts}
      sliderCl={"popular__slider"}
      sliderNavCl={"popular__slider-nav"}
      sliderBtnCl={"popular__slider-nav-btn"}
      onClick={toggleCatalog}
    />
  );
};

export default HomePagePopular;
