import MyPage from '@components/_UI/MyPage';
import MySection from '@components/_UI/MySection';
import Breadcrumbs from '@components/breadcrumbs/Breadcrumbs';
import { useNavigate, useParams } from 'react-router-dom';
import cl from './ProductPage.module.scss';
import Products from '@components/products/Products';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import Tabs from './tabs/Tabs';
import MainInfo from './mainInfo/MainInfo';
import Slider from './slider/Slider';
import {
  clearReviews,
  getProduct,
  getSimilarProducts,
  productSel,
} from '@store/slices/product/productSlice';
import Header from '@components/header/Header';
import Footer from '@components/footer/Footer';
import NotFound from '@components/notFound/NotFound';
import {
  createOrder,
  ordersSel,
  setIsOrdered,
  setToBuyModal,
} from '@store/slices/orders/ordersSlice';
import {
  getUser,
  sendCode,
  setCodeValue,
  setIsNum,
  setIsSign,
  setNumValue,
  signSel,
  signUser,
} from '@store/slices/sign/signSlice';
import ProductModal from '@components/ordering/productModal/ProductModal';
import { onHide } from '@hooks/toHide';
import { setTelField } from '@store/slices/profile/profileSlice';

const ProductPage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { token, isSign, isNum, cleanedNum, numValue, codeValue } =
    useSelector(signSel);
  const { toBuyModal, isOrdered } = useSelector(ordersSel);
  const { product, similarProducts } = useSelector(productSel);

  const { productSlugId } = useParams();
  const parts = productSlugId.split('-');
  const productId = parts[parts.length - 1];

  useEffect(() => {
    dispatch(getSimilarProducts({ productId }));
    dispatch(clearReviews());
  }, [dispatch, productId]);

  useEffect(() => {
    dispatch(getProduct({ productId }));
  }, [dispatch, productId]);

  const toBuyProduct = () => {
    onHide(toBuyModal);
    dispatch(setIsOrdered(false));
    dispatch(setIsNum(false));
    dispatch(setNumValue(''));
    dispatch(setCodeValue(''));
    dispatch(setToBuyModal(true));
  };

  const onSignUser = (e) => {
    e.preventDefault();
    if (cleanedNum.length === 11) {
      dispatch(signUser({ phone: cleanedNum })).then(() => {
        dispatch(setIsNum(true));
        dispatch(setTelField(numValue));
      });
    }
  };

  const onSendCode = (e) => {
    e.preventDefault();

    dispatch(sendCode({ phone: cleanedNum, code: codeValue })).then(
      (action) => {
        const token = action.payload.token.split('|')[1];

        dispatch(getUser(token)).then(() => {
          dispatch(setCodeValue(''));
          dispatch(setIsSign(true));
        });

        onConfirm({ newToken: token });
      },
    );
    dispatch(setTelField(numValue));
  };

  const onSuccess = () => {
    dispatch(setIsOrdered(true));

    setTimeout(() => {
      onHide(toBuyModal);
      dispatch(setToBuyModal(false));
      navigate('/orders');
    }, 3500);
  };

  const onConfirm = ({ newToken = false }) => {
    const newProduct = {
      product_id: product.id,
      unit_price: product.price,
      quantity: 1,
    };

    console.log(`newToken: ${newToken} \n token: ${token}`);

    dispatch(
      createOrder({
        token: newToken ? newToken : token,
        products: [newProduct],
      }),
    ).then(() => {
      onSuccess();
    });
  };

  const onCancel = () => {
    onHide(toBuyModal);
    dispatch(setToBuyModal(false));
  };

  const toReNum = () => {
    dispatch(setIsNum(false));
    dispatch(setCodeValue(''));
    dispatch(setNumValue(''));
  };

  const onReSendNewCode = () => {
    dispatch(setCodeValue(''));
  };

  return (
    <>
      <Header />
      <MyPage
        metaTitle={`Qlixmed | ${product?.seo_title}`}
        metaDescr={product?.seo_description}
        metaKeywords={product?.seo_keywords}
      >
        <MySection classNames={cl.product}>
          {product ? (
            <>
              <Breadcrumbs />
              <div className={cl.top}>
                <Slider slides={product?.images} />
                <MainInfo toBuyProduct={toBuyProduct} />
              </div>
              <Tabs outerCl={cl.bottom} />
            </>
          ) : (
            <NotFound title={'Товар не найден'} />
          )}
        </MySection>
        <Products title={'Похожие товары'} products={similarProducts} />
      </MyPage>

      <ProductModal
        modalIsOpen={toBuyModal}
        setModalIsOpen={setToBuyModal}
        item={product}
        isNum={isNum}
        isSign={isSign}
        isOrdered={isOrdered}
        cleanedNum={cleanedNum}
        numValue={numValue}
        setNumValue={setNumValue}
        codeValue={codeValue}
        setCodeValue={setCodeValue}
        onCancel={onCancel}
        onConfirm={onConfirm}
        onSendCode={onSendCode}
        onSignUser={onSignUser}
        toReNum={toReNum}
        onReSendNewCode={onReSendNewCode}
      />

      <Footer />
    </>
  );
};

export default ProductPage;
