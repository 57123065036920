import MyModal from '@components/_UI/modal/MyModal';
import cl from './Modal.module.scss';
import { useDispatch } from 'react-redux';
import { clsx } from 'clsx';
import NotFound from '@components/notFound/NotFound';
import { Link, useNavigate } from 'react-router-dom';
import { enableScroll } from '@hooks/enableScroll';

const Modal = ({
  catalogIsOpen,
  setModalIsOpen,
  catalogList,
  curCatListItem,
  setCurCatListItem,
  catTree,
  setCatTree,
  curCatTree,
  setCurCatTree,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const onMainHover = (item) => {
    dispatch(setCatTree(item.sub_categories));
    dispatch(setCurCatListItem(item));
  };

  const onMainClick = (item) => {
    onMainHover(item);
    dispatch(setModalIsOpen(false));
    enableScroll();
    navigate(`/catalog/${item.slug}`);
  };

  const onSub = (sub) => {
    dispatch(setCurCatTree(sub));
    dispatch(setModalIsOpen(false));
    enableScroll();
  };

  return (
    <MyModal
      overlayCl={cl.overlay}
      modalCl={cl.modal}
      innerCl={cl.inner}
      closeIsNeed={false}
      modalIsOpen={catalogIsOpen}
      setModalIsOpen={setModalIsOpen}
    >
      <ul className={clsx(cl.list, { [cl.list__active]: curCatListItem })}>
        {catalogList?.map((item) => (
          <li
            className={clsx(cl.list__item, {
              [cl.list__item_active]: item.id === curCatListItem?.id,
            })}
            key={item.id}
            onMouseEnter={() => onMainHover(item)}
            onClick={() => onMainClick(item)}
          >
            <span>{item.category_name}</span>
            <svg
              className="ico-12 stroke"
              xmlns="http://www.w3.org/2000/svg"
              width="8"
              height="13"
              viewBox="0 0 8 13"
              fill="none"
            >
              <path d="M1 1L6 6.5L1 12" stroke="currentColor" strokeWidth="2" />
              <path d="M1 1L6 6.5L1 12" stroke="currentColor" strokeWidth="2" />
            </svg>
          </li>
        ))}
      </ul>
      <div className={clsx(cl.blocks, { [cl.blocks__active]: curCatListItem })}>
        <div className={cl.blocks__inner}>
          {catTree?.length > 0 ? (
            catTree?.map((obj) => (
              <div className={cl.block} key={obj.id}>
                <Link
                  className={cl.block__title + ' title link link-accent'}
                  to={`/catalog/${curCatListItem?.slug}/${obj.slug}`}
                  onClick={() => onSub(obj)}
                >
                  {obj.category_name}
                </Link>
                <ul className={cl.block__list}>
                  {obj.sub_categories?.map((sub) => (
                    <li className={cl.block__item} key={sub.id}>
                      <Link
                        to={`/catalog/${curCatListItem?.slug}/${obj.slug}/${sub.slug}`}
                        className={clsx(cl.block__link + ' link link-accent', {
                          [cl.block__link_active]: sub?.id === curCatTree?.id,
                        })}
                        onClick={() => onSub(sub)}
                      >
                        {sub.category_name} ({sub.product_count})
                      </Link>
                    </li>
                  ))}
                </ul>
              </div>
            ))
          ) : (
            <NotFound title={'Подкатегории не найдены'} />
          )}
        </div>
      </div>
    </MyModal>
  );
};

export default Modal;
