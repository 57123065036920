import { forwardRef, useRef } from 'react';

import { Navigation, Pagination } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import SliderBtn from '@components/_UI/slider/sliderBtn/SliderBtn';

import cl from './Big.module.scss';
import { useDispatch, useSelector } from 'react-redux';
import { productSel, setModalIsOpen } from '@store/slices/product/productSlice';
import { onHide } from '@hooks/toHide';
import useWindowWidth from '@hooks/windowWidth';

const Big = forwardRef(
  ({ classNames, sliderCl, slideCl, slides, slider1, slider3 }, ref) => {
    const { modalIsOpen, product } = useSelector(productSel);
    const dispatch = useDispatch();

    const windowWidth = useWindowWidth();

    const goPrev = useRef(null);
    const goNext = useRef(null);

    const onModal = () => {
      dispatch(setModalIsOpen(true));
      onHide(modalIsOpen, true);
    };

    return (
      <div className={`${classNames} ${cl.outer}`}>
        <span className={cl.scaleText}>
          Чтобы увеличить, нажмите на картинку
        </span>
        <Swiper
          modules={[Navigation, Pagination]}
          slidesPerView={1}
          spaceBetween={20}
          grabCursor={true}
          speed={500}
          navigation={{
            prevEl: goPrev.current,
            nextEl: goNext.current,
          }}
          pagination={{
            clickable: true,
            clickableClass: [cl.pagination],
            bulletClass: cl.bullet,
            bulletActiveClass: cl.active,
          }}
          onSwiper={(swiper) => {
            setTimeout(() => {
              swiper.params.navigation.prevEl = goPrev.current;
              swiper.params.navigation.nextEl = goNext.current;

              swiper.navigation.destroy();
              swiper.navigation.init();
              swiper.navigation.update();
            });
          }}
          onSlideChange={(swiper) => {
            windowWidth >= 1025 &&
              slider1.current.swiper.slideTo(swiper.activeIndex);
            slider3.current.swiper.slideTo(swiper.activeIndex);
          }}
          ref={ref}
          className={`${sliderCl} ${cl.slider} slider swiper`}
        >
          {slides.map((slide, i) => (
            <SwiperSlide
              className={`${slideCl} ${cl.slide} slider-slide swiper-slide`}
              key={slide.id}
            >
              <div className={cl.content} onClick={onModal}>
                <img
                  className={cl.img}
                  src={slide.path}
                  alt={product?.seo_title + ' ' + (i + 1)}
                  title={product?.seo_title + ' ' + (i + 1)}
                />
              </div>
            </SwiperSlide>
          ))}
          <div className={`${cl.nav} slider-nav`}>
            <SliderBtn classNames={`${cl.prev} prev`} ref={goPrev} />
            <SliderBtn classNames={`${cl.next} next`} ref={goNext} />
          </div>
        </Swiper>
      </div>
    );
  },
);

export default Big;
