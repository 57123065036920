import OrderProducts from "@components/order/products/Products";

import cl from "./Products.module.scss";

const Products = ({order}) => {
  return (
    <div className={cl.outer}>
      <div className={cl.head}>
        <p className={cl.title}>Содержимое заказа:</p>
        <p className={cl.subtitle}>{/*{order?.order_items?.length} товара на сумму {order?.total_price} KZT*/}Цена по запросу</p>
      </div>
      <OrderProducts order={order} classNames={cl.products} productCl={cl.product} />
    </div>
  );
};

export default Products;
