import React, { useEffect, useState } from "react";
import outerCl from "../Personal.module.scss";
import cl from "./AccData.module.scss";
import MyInput from "@components/_UI/fields/MyInput";
import { clsx } from "clsx";
import { useDispatch, useSelector } from "react-redux";
import {
  profileSel,
  setAccFields,
  setEmailField,
  setFioField,
  setTelField,
} from "@store/slices/profile/profileSlice";
import { signSel, updateUser } from "@store/slices/sign/signSlice";
import MyForm from "@components/_UI/MyForm";

const AccData = () => {
  const dispatch = useDispatch();
  const { accFields } = useSelector(profileSel);
  const { user, token } = useSelector(signSel);
  const [isOnChange, setIsOnChange] = useState(false);
  const [oldFields, setOldFields] = useState(accFields);
  const [firstUpdate, setFirstUpdate] = useState(false);

  useEffect(() => {
    if (user) {
      console.log(user);
      dispatch(setFioField(user.first_name ? user.first_name : ""));
      dispatch(setTelField(user.phone));
      dispatch(setEmailField(user.email ? user.email : ""));
    }
  }, [dispatch, user]);

  useEffect(() => {
    if (!firstUpdate) {
      setOldFields(accFields);
      setFirstUpdate(true);
    }
  }, [user, accFields, firstUpdate]);

  const changeFields = e => {
    e.preventDefault();
    setIsOnChange(true);
  };

  const saveChanges = e => {
    e.preventDefault();

    dispatch(
      updateUser({
        token,
        bodyParams: {
          first_name: accFields.fioField,
          email: accFields.emailField,
        }
      })
    );

    setOldFields(accFields);
    setIsOnChange(false);
  };

  const undoChanges = e => {
    e.preventDefault();

    dispatch(setAccFields(oldFields));
    setIsOnChange(false);
  };

  return (
    <div className={outerCl.block}>
      <h3 className={`title title-ico ${outerCl.title}`}>
        <svg
          className="ico-24 stroke"
          xmlns="http://www.w3.org/2000/svg"
          width="26"
          height="26"
          viewBox="0 0 26 26"
          fill="none"
        >
          <path
            d="M21.6654 22.75V20.5833C21.6654 19.4341 21.2088 18.3319 20.3962 17.5192C19.5835 16.7065 18.4813 16.25 17.332 16.25H8.66536C7.51609 16.25 6.41389 16.7065 5.60124 17.5192C4.78858 18.3319 4.33203 19.4341 4.33203 20.5833V22.75"
            stroke="currentColor"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M13.0013 11.9167C15.3945 11.9167 17.3346 9.97657 17.3346 7.58333C17.3346 5.1901 15.3945 3.25 13.0013 3.25C10.6081 3.25 8.66797 5.1901 8.66797 7.58333C8.66797 9.97657 10.6081 11.9167 13.0013 11.9167Z"
            stroke="currentColor"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
        <span>Учетные данные</span>
      </h3>

      <MyForm className={outerCl.form} onSubmit={saveChanges}>
        <div
          className={clsx(`${outerCl.fields} ${cl.fields}`, {
            [outerCl.fields_input]: isOnChange,
            [outerCl.fields_text]: !isOnChange,
          })}
        >
          <label className={`${outerCl.field_outer} ${cl.field_outer}`}>
            <span className={`${outerCl.field_title}`}>ФИО</span>
            <MyInput
              classNames={clsx(outerCl.field, {
                [outerCl.field_input]: isOnChange,
                [outerCl.field_text]: !isOnChange,
              })}
              type={'text'}
              required={true}
              disabled={!isOnChange}
              value={isOnChange ? accFields.fioField : oldFields.fioField}
              setValue={setFioField}
              placeholder={isOnChange ? "" : "----------"}
            />
          </label>
          <label className={`${outerCl.field_outer} ${cl.field_outer}`}>
            <span className={`${outerCl.field_title}`}>Телефон</span>
            <MyInput
              classNames={clsx(`${outerCl.field_tel} ${outerCl.field}`, {
                [outerCl.field_input]: isOnChange,
                [outerCl.field_text]: !isOnChange,
              })}
              disabled={true}
              value={isOnChange ? accFields.telField : oldFields.telField}
              setValue={setTelField}
              placeholder={isOnChange ? "" : "----------"}
            />
          </label>
          <label className={`${outerCl.field_outer} ${cl.field_outer}`}>
            <span className={`${outerCl.field_title}`}>E-mail</span>
            <MyInput
              classNames={clsx(outerCl.field, {
                [outerCl.field_input]: isOnChange,
                [outerCl.field_text]: !isOnChange,
              })}
              type={'email'}
              required={true}
              disabled={!isOnChange}
              value={isOnChange ? accFields.emailField : oldFields.emailField}
              setValue={setEmailField}
              placeholder={isOnChange ? "" : "----------"}
            />
          </label>
        </div>

        {isOnChange ? (
          <div className={outerCl.btn_outer}>
            <button
              className={`${outerCl.btn_link} link link-accent`}
              type={'submit'}
            >
              Сохранить
            </button>
            <button
              className={`${outerCl.btn_link} link link-red`}
              onClick={undoChanges}
              type={'button'}
            >
              Отменить
            </button>
          </div>
        ) : (
          <button
            className={`${outerCl.btn_link} link link-accent`}
            onClick={changeFields}
          >
            Изменить учетные данные
          </button>
        )}
      </MyForm>
    </div>
  );
};

export default AccData;
