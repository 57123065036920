import MyPage from "@components/_UI/MyPage";
import MySection from "@components/_UI/MySection";
import Products from "@components/products/Products";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Head from "./head/Head";
import Stuff from "./stuff/Stuff";
import { useParams } from "react-router-dom";
import { getOrders, ordersSel } from "@store/slices/orders/ordersSlice";
import { orderSel, setOrder } from "@store/slices/order/orderSlice";
import { signSel } from "@store/slices/sign/signSlice";
import Header from "@components/header/Header";
import Footer from "@components/footer/Footer";
import { getSimilarProducts, productSel } from "@store/slices/product/productSlice";

const OrderPage = () => {
  const dispatch = useDispatch();
  const {token} = useSelector(signSel);
  const {similarProducts} = useSelector(productSel);
  const { orders } = useSelector(ordersSel);
  const {order} = useSelector(orderSel);
  const {uId} = useParams();

  useEffect(() => {
    dispatch(getOrders({token}));
  }, [dispatch, token]);

  useEffect(() => {
    if (order) {
      dispatch(getSimilarProducts({productId: order.order_items[0].id}))
    }
  }, [dispatch, order])

  useEffect(() => {
    const curOrder = orders?.find(product => Number(product.id) === Number(uId));

    dispatch(setOrder(curOrder));

  }, [dispatch, orders, uId]);

  console.log(orders)

  return (
    <>
    <Header />
    <MyPage>
      <MySection>
        <Head order={order} />
        {/* <Info order={order} /> */}
        <Stuff order={order} />
      </MySection>
      <Products title={"Похожие товары"} products={similarProducts} />
    </MyPage>
    <Footer />
    </>
  );
};

export default OrderPage;
