import React from 'react';

const Arrow = () => {
  return (
    <svg
      className="ico-16 stroke"
      xmlns="http://www.w3.org/2000/svg"
      width="13"
      height="16"
      viewBox="0 0 13 16"
      fill="none"
    >
      <path
        d="M3.84766 3.92285L9.07842 8.49977L3.84766 13.01025"
        stroke="currentColor"
        strokeWidth="0.980769"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default Arrow;
