const { createSlice } = require("@reduxjs/toolkit");

const initialState = {
  isConfirm: false,
  confirmProps: {
    title: null,
    subtitle: null,
    btns: null,
  },
};

const confirmSlice = createSlice({
  name: "confirm",
  initialState,
  reducers: {
    setIsConfirm: (state, action) => {
      state.isConfirm = action.payload;
    },
    setConfirmProps: (state, action) => {
      state.confirmProps = action.payload;
    },
    cancelConfirm: state => {
      state.isConfirm = false;
      state.confirmProps = {
        title: null,
        subtitle: null,
        btns: null,
      };
    },
  },

});

export const confirmSel = state => state.confirm;
export const { setIsConfirm, setConfirmProps, cancelConfirm } =
  confirmSlice.actions;
export default confirmSlice.reducer;

// title={"Сперва авторизируйтесь"}
//           subtitle={`Нажав "продолжить" Вы будете перенаправлены на страницу авторизации`}
// btns={[
//   {
//     id: 1,
//     cl: 'btn-accent',
//     text: 'Продолжить',
//     onClick: () => {
//       navigate('/sign')
//     }
//   },
//   {
//     id: 2,
//     cl: 'btn-cancel',
//     text: 'Отмена',
//     onClick: () => {
//       dispatch(setIsConfirm(false));
//     }
//   },
// ]}
