import './MyFilter.scss'

const MyFilter = ({children, classNames, ...props}) => {
  return (
    <div className={`${classNames} filter`} {...props}>
      {children}
    </div>
  )
}

export default MyFilter
