import axios from 'axios';
import { loadStatus } from 'src/store/loadStatus';

const { createSlice, createAsyncThunk } = require('@reduxjs/toolkit');

export const getAllProducts = createAsyncThunk(
  'catalog/getAllProducts',
  async ({ priceRanges, sortMethod }) => {
    try {
      const priceRangesParam =
        priceRanges.length > 0
          ? priceRanges.map((range) => `priceRanges[]=${range}`).join('&') + '&'
          : '';

      const { data } = await axios.get(
        `${process.env.REACT_APP_API_URL}productsWithSort?${priceRangesParam}sort=${sortMethod}`,
      );

      return data;
    } catch (err) {
      console.log(`ошибка получения товаров по категории: ${err}`);
    }
  },
);

export const getFilteredProducts = createAsyncThunk(
  'catalog/getFilteredProducts',
  async ({ categoryId, sortBy, perPage, curPage }, { rejectWithValue }) => {
    try {
      const { data } = await axios.get(
        `${process.env.REACT_APP_API_URL}v1/product/getByCategoryPaginated/${categoryId}?sortBy=${sortBy}&perPage=${perPage}&page=${curPage}`,
      );

      return data;
    } catch (err) {
      console.log(`ошибка получения товаров по категории: ${err}`);
      return rejectWithValue(err.message);
    }
  },
);

export const getCatRoots = createAsyncThunk(
  'catalog/getCatRoots',
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await axios.get(
        `${process.env.REACT_APP_API_URL}v1/category/roots`,
      );

      return data;
    } catch (err) {
      return rejectWithValue(err.message);
    }
  },
);

export const getCatTree = createAsyncThunk(
  'catalog/getCatTree',
  async ({ categoryId }, { rejectWithValue }) => {
    try {
      const { data } = await axios.get(
        `${process.env.REACT_APP_API_URL}v1/category/tree?categoryId=${categoryId}`,
      );

      return data;
    } catch (err) {
      return rejectWithValue(err.message);
    }
  },
);

export const getCatAll = createAsyncThunk(
  'catalog/getCatAll',
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await axios.get(
        `${process.env.REACT_APP_API_URL}v1/category/tree`,
      );

      return data;
    } catch (err) {
      return rejectWithValue(err.message);
    }
  },
);

const initialState = {
  // prices: [
  //   { value: '0 - 10000', text: 'до 10 000 тг.', count: '984' },
  //   { value: '10000 - 49999', text: '10 000 - 49 999 тг.', count: '984' },
  //   { value: '50000 - 99999', text: '50 000 - 99 999 тг.', count: '984' },
  //   { value: '100000 - 149999', text: '100 000 - 149 999 тг.', count: '984' },
  //   { value: '150000 - 199999', text: '150 000 - 199 999 тг.', count: '984' },
  //   { value: '200000 - 210000', text: '200 000 - 210 000 тг.', count: '984' },
  // ],
  // selectedPrices: [],

  // sellers: [
  //   { value: '1', text: 'Название магазина 1' },
  //   { value: '2', text: 'Название магазина 2' },
  //   { value: '3', text: 'Название магазина 3' },
  //   { value: '4', text: 'Название магазина 4' },
  //   { value: '5', text: 'Название магазина 5' },
  //   { value: '6', text: 'Название магазина 6' },
  // ],
  // selectedSellers: [],

  // rates: [
  //   { value: '5', count: '396' },
  //   { value: '4', count: '396' },
  //   { value: '3', count: '396' },
  //   { value: '2', count: '396' },
  //   { value: '1', count: '396' },
  // ],
  // selectedRates: [],

  sorts: [
    { value: 'popularity', label: 'Популярные' },
    { value: 'rating', label: 'По рейтингу' },
    { value: 'cheap', label: 'Сначала дешевые' },
    { value: 'expensive', label: 'Сначала дорогие' },
    { value: 'newest', label: 'Новинки' },
  ],
  selectedSort: { value: 'newest', label: 'Новинки' },

  filteredProducts: [],

  perPageModal: false,
  perPageItems: [
    {value: 6, label: 6},
    {value: 9, label: 9},
    {value: 12, label: 12},
    {value: 15, label: 15},
  ],
  productsPerPage: {value: 6, label: 6},
  curPage: 1,

  totalProducts: null,
  filterLoadStatus: 'idle',

  catAll: { id: '11244354', category_name: 'Все категории' },
  catRoots: null,
  curCatRoot: null,
  catTree: null,
  curCatTree: null,
  catLoadStatus: 'idle',
};

const catalogSlice = createSlice({
  name: 'catalog',
  initialState,
  reducers: {
    setSelectedPrices: (state, action) => {
      const value = action.payload;
      if (state.selectedPrices.includes(value)) {
        state.selectedPrices = state.selectedPrices.filter(
          (item) => item !== value,
        );
      } else {
        state.selectedPrices.push(value);
      }
    },

    setSelectedSellers: (state, action) => {
      const value = action.payload;
      if (state.selectedSellers.includes(value)) {
        state.selectedSellers = state.selectedSellers.filter(
          (item) => item !== value,
        );
      } else {
        state.selectedSellers.push(value);
      }
    },

    setSelectedRates: (state, action) => {
      const value = action.payload;
      if (state.selectedRates.includes(value)) {
        state.selectedRates = state.selectedRates.filter(
          (item) => item !== value,
        );
      } else {
        state.selectedRates.push(value);
      }
    },

    setSelectedSort: (state, action) => {
      state.selectedSort = action.payload;
    },

    setPerPageModal: (state,action) => {
      state.perPageModal = action.payload;
    },

    setProductsPerPage: (state,action) => {
      state.productsPerPage = action.payload;
    },

    clearFilteredProducts: (state) => {
      state.filteredProducts = [];
    },

    setCatTree: (state,action) => {
      state.catTree = action.payload;
    },

    setCurCatRoot: (state, action) => {
      state.curCatRoot = action.payload;
    },
    setCurCatTree: (state, action) => {
      state.curCatTree = action.payload;
    },
  },

  extraReducers: (builder) => {
    builder
      .addCase(getAllProducts.pending, (state) => {
        state.filterLoadStatus = loadStatus.pending;
      })
      .addCase(getAllProducts.fulfilled, (state, action) => {
        state.filterLoadStatus = loadStatus.fulfilled;
        state.filteredProducts = action.payload;
      })
      .addCase(getAllProducts.rejected, (state) => {
        state.filterLoadStatus = loadStatus.rejected;
      });

    builder
      .addCase(getFilteredProducts.pending, (state) => {
        state.filterLoadStatus = loadStatus.pending;
      })
      .addCase(getFilteredProducts.fulfilled, (state, action) => {
        state.filterLoadStatus = loadStatus.fulfilled;
        state.filteredProducts = action.payload.data;
        state.totalProducts = action.payload.meta.total;
        state.curPage = action.payload.meta.current_page;
      })
      .addCase(getFilteredProducts.rejected, (state) => {
        state.filterLoadStatus = loadStatus.rejected;
      });

    builder
      .addCase(getCatRoots.pending, (state) => {
        state.catLoadStatus = loadStatus.pending;
      })
      .addCase(getCatRoots.fulfilled, (state, action) => {
        state.catLoadStatus = loadStatus.fulfilled;
        state.catRoots = action.payload;
      })
      .addCase(getCatRoots.rejected, (state) => {
        state.catLoadStatus = loadStatus.rejected;
      });

    builder
      .addCase(getCatTree.pending, (state) => {
        state.catLoadStatus = loadStatus.pending;
      })
      .addCase(getCatTree.fulfilled, (state, action) => {
        state.catLoadStatus = loadStatus.fulfilled;
        state.catTree = action.payload;
      })
      .addCase(getCatTree.rejected, (state) => {
        state.catLoadStatus = loadStatus.rejected;
      });

    builder
      .addCase(getCatAll.pending, (state) => {
        state.catLoadStatus = loadStatus.pending;
      })
      .addCase(getCatAll.fulfilled, (state, action) => {
        state.catLoadStatus = loadStatus.fulfilled;
        state.catRoots = action.payload;
      })
      .addCase(getCatAll.rejected, (state) => {
        state.catLoadStatus = loadStatus.rejected;
      });
  },
});

export const {
  setSelectedPrices,
  setSelectedSellers,
  setSelectedRates,
  setSelectedSort,
  setPerPageModal,
  setProductsPerPage,
  clearFilteredProducts,
  setCatTree,
  setCurCatRoot,
  setCurCatTree,
} = catalogSlice.actions;
export const catalogSel = (state) => state.catalog;

export default catalogSlice.reducer;
