import { useEffect, useState } from 'react';
import cl from './Items.module.scss';
import Item from './item/Item';
import {
  getOrders,
  ordersSel,
  setSelectedSort,
} from '@store/slices/orders/ordersSlice';
import { useDispatch, useSelector } from 'react-redux';
import { signSel } from '@store/slices/sign/signSlice';
import NotFound from '@components/notFound/NotFound';
import useWindowWidth from '@hooks/windowWidth';
import { onHide } from '@hooks/toHide';
import MyBtn from '@components/_UI/btns/MyBtn';
import { clsx } from 'clsx';
import SortMob from '@components/sortMob/SortMob';
import MyDropdown from '@components/_UI/dropdown/MyDropdown';

const Items = () => {
  const dispatch = useDispatch();
  const { orders, sorts, selectedSort } = useSelector(ordersSel);
  const { token } = useSelector(signSel);

  const windowWidth = useWindowWidth();

  const [sortIsOpen, setSortIsOpen] = useState(false);

  const onSort = () => {
    setSortIsOpen(!sortIsOpen);
    onHide(sortIsOpen);
  };

  const onSelect = (option) => {
    dispatch(setSelectedSort(option));
    // if (curCat.id !== catAll.id) {
    //   dispatch(
    //     getFilteredProducts({
    //       catId: curCat.id,
    //       priceRanges: selectedPrices,
    //       sortMethod: option.value,
    //     })
    //   );
    // } else {
    //   dispatch(
    //     getAllProducts({
    //       priceRanges: selectedPrices,
    //       sortMethod: option.value,
    //     })
    //   );
    // }
    onSort();
  };

  useEffect(() => {
    dispatch(getOrders({ token }));
  }, [dispatch, token]);

  return (
    <div className={cl.items}>
      {orders && orders.length > 0 ? (
        <>
          {windowWidth < 1025 ? (
            <>
              <MyBtn
                classNames={clsx('btn-ico catalog__head-btn btn-sort', {
                  'active btn-accent': sortIsOpen,
                })}
                onClick={onSort}
              >
                <svg
                  className="ico-20 fill"
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M10.6469 7.35366C10.6003 7.30721 10.5634 7.25203 10.5382 7.19129C10.513 7.13054 10.5 7.06542 10.5 6.99966C10.5 6.93389 10.513 6.86877 10.5382 6.80802C10.5634 6.74728 10.6003 6.6921 10.6469 6.64565L13.6469 3.64565C13.6934 3.59917 13.7486 3.56229 13.8093 3.53713C13.87 3.51197 13.9351 3.49902 14.0009 3.49902C14.0666 3.49902 14.1317 3.51197 14.1925 3.53713C14.2532 3.56229 14.3084 3.59917 14.3549 3.64565C14.4014 3.69214 14.4383 3.74733 14.4634 3.80807C14.4886 3.86881 14.5015 3.93391 14.5015 3.99966C14.5015 4.0654 14.4886 4.1305 14.4634 4.19124C14.4383 4.25198 14.4014 4.30717 14.3549 4.35366L11.3549 7.35366C11.3084 7.40022 11.2533 7.43716 11.1925 7.46237C11.1318 7.48757 11.0667 7.50055 11.0009 7.50055C10.9351 7.50055 10.87 7.48757 10.8093 7.46237C10.7485 7.43716 10.6933 7.40022 10.6469 7.35366Z"
                    fill="currentColor"
                  />
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M17.3546 7.35366C17.3082 7.40022 17.253 7.43716 17.1923 7.46237C17.1315 7.48757 17.0664 7.50055 17.0006 7.50055C16.9349 7.50055 16.8697 7.48757 16.809 7.46237C16.7483 7.43716 16.6931 7.40022 16.6466 7.35366L13.6466 4.35366C13.5527 4.25977 13.5 4.13243 13.5 3.99966C13.5 3.86688 13.5527 3.73954 13.6466 3.64565C13.7405 3.55177 13.8679 3.49902 14.0006 3.49902C14.1334 3.49902 14.2607 3.55177 14.3546 3.64565L17.3546 6.64565C17.4012 6.6921 17.4381 6.74728 17.4633 6.80802C17.4886 6.86877 17.5015 6.93389 17.5015 6.99966C17.5015 7.06542 17.4886 7.13054 17.4633 7.19129C17.4381 7.25203 17.4012 7.30721 17.3546 7.35366Z"
                    fill="currentColor"
                  />
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M14.0006 4C14.1332 4 14.2604 4.05268 14.3542 4.14645C14.448 4.24021 14.5006 4.36739 14.5006 4.5V14C14.5006 14.1326 14.448 14.2598 14.3542 14.3536C14.2604 14.4473 14.1332 14.5 14.0006 14.5C13.868 14.5 13.7408 14.4473 13.6471 14.3536C13.5533 14.2598 13.5006 14.1326 13.5006 14V4.5C13.5006 4.36739 13.5533 4.24021 13.6471 4.14645C13.7408 4.05268 13.868 4 14.0006 4ZM9.35463 12.646C9.4012 12.6924 9.43814 12.7476 9.46334 12.8084C9.48855 12.8691 9.50153 12.9342 9.50153 13C9.50153 13.0658 9.48855 13.1309 9.46334 13.1916C9.43814 13.2524 9.4012 13.3076 9.35463 13.354L6.35463 16.354C6.26075 16.4479 6.13341 16.5006 6.00063 16.5006C5.86786 16.5006 5.74052 16.4479 5.64663 16.354C5.55275 16.2601 5.5 16.1328 5.5 16C5.5 15.8672 5.55275 15.7399 5.64663 15.646L8.64663 12.646C8.69308 12.5994 8.74825 12.5625 8.809 12.5373C8.86974 12.5121 8.93486 12.4991 9.00063 12.4991C9.0664 12.4991 9.13152 12.5121 9.19227 12.5373C9.25301 12.5625 9.30819 12.5994 9.35463 12.646Z"
                    fill="currentColor"
                  />
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M2.64689 12.6459C2.69334 12.5994 2.74851 12.5624 2.80926 12.5372C2.87001 12.512 2.93513 12.499 3.00089 12.499C3.06666 12.499 3.13178 12.512 3.19253 12.5372C3.25327 12.5624 3.30845 12.5994 3.35489 12.6459L6.35489 15.6459C6.40138 15.6924 6.43826 15.7476 6.46342 15.8083C6.48858 15.8691 6.50153 15.9342 6.50153 15.9999C6.50153 16.0657 6.48858 16.1308 6.46342 16.1915C6.43826 16.2522 6.40138 16.3074 6.35489 16.3539C6.30841 16.4004 6.25322 16.4373 6.19248 16.4624C6.13174 16.4876 6.06664 16.5005 6.00089 16.5005C5.93515 16.5005 5.87005 16.4876 5.80931 16.4624C5.74857 16.4373 5.69338 16.4004 5.64689 16.3539L2.64689 13.3539C2.60033 13.3075 2.56339 13.2523 2.53818 13.1916C2.51297 13.1308 2.5 13.0657 2.5 12.9999C2.5 12.9341 2.51297 12.869 2.53818 12.8083C2.56339 12.7475 2.60033 12.6924 2.64689 12.6459Z"
                    fill="currentColor"
                  />
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M6 16C5.86739 16 5.74021 15.9473 5.64645 15.8536C5.55268 15.7598 5.5 15.6326 5.5 15.5V6C5.5 5.86739 5.55268 5.74021 5.64645 5.64645C5.74021 5.55268 5.86739 5.5 6 5.5C6.13261 5.5 6.25979 5.55268 6.35355 5.64645C6.44732 5.74021 6.5 5.86739 6.5 6V15.5C6.5 15.6326 6.44732 15.7598 6.35355 15.8536C6.25979 15.9473 6.13261 16 6 16Z"
                    fill="currentColor"
                  />
                </svg>
              </MyBtn>
              <SortMob
                sortIsOpen={sortIsOpen}
                onSort={onSort}
                onSelect={onSelect}
                options={sorts}
                selected={selectedSort}
                titleText="Фильтр"
              />
            </>
          ) : (
            <MyDropdown
              value={selectedSort}
              setValue={setSelectedSort}
              options={sorts}
              sortIsOpen={sortIsOpen}
              setSortIsOpen={setSortIsOpen}
              onSelect={onSelect}
            />
          )}
          {orders?.map((item) => <Item order={item} key={item.id} />).reverse()}
        </>
      ) : (
        <NotFound
          title={'Заказов пока нет'}
          subtitle={
            'После успешного оформления товара здесь появятся позиции'
          }
        />
      )}
    </div>
  );
};

export default Items;
