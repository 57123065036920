import cl from './Potencial.module.scss';

const Potencial = () => {
  return (
    <div className={cl.wrapper}>
      <h3 className={cl.title}>Уважаемые потенциальные партнеры!</h3>
      <div className={cl.block}>
        <span className={cl.text + ` ${cl.text_first}`}>
          В рамках стратегического развития нашего маркетплейса Qlixmed, мы
          приглашаем к сотрудничеству производителей и поставщиков медицинского
          оборудования и материалов. Наша платформа предоставляет уникальную
          возможность расширить ваш рынок сбыта, предлагая продукцию передовым
          медицинским учреждениям, специалистам и частным лицам, стремящимся к
          обеспечению высококачественного уровня здравоохранения.
        </span>
        <span className={cl.text + ` ${cl.text_sec}`}>
          Qlixmed гарантирует прозрачные и выгодные условия сотрудничества,
          направленные на максимизацию вашего коммерческого успеха на нашей
          платформе.
        </span>
      </div>
    </div>
  );
};

export default Potencial;
