import { configureStore } from '@reduxjs/toolkit';

import window from './slices/window/windowSlice';
import host from './slices/host/hostSlice';

import header from './slices/header/headerSlice';
import confirm from './slices/confirm/confirmSlice';
import bar from './slices/bar/barSlice';
import sign from './slices/sign/signSlice';
// import categories from './slices/categories/categoriesSlice'
import products from './slices/products/productsSlice';

import profile from './slices/profile/profileSlice';

import catalog from './slices/catalog/catalogSlice';
import product from './slices/product/productSlice';

import cart from './slices/cart/cartSlice';

import chats from './slices/chats/chatsSlice';

import orders from './slices/orders/ordersSlice';
import order from './slices/order/orderSlice';

import about from './slices/about/aboutSlice';
import partners from './slices/partners/partnersSlice';
import delivery from './slices/delivery/deliverySlice';
import faq from './slices/faq/faqSlice';

import seller from './slices/seller/sellerSlice';

import sellerSign from './slices/sellerSign/sellerSignSlice';
import sellerProducts from './slices/sellerProducts/sellerProductsSlice';
import sellerProduct from './slices/sellerProduct/sellerProductSlice';

export const store = configureStore({
  reducer: {
    window,
    host,

    header,
    confirm,
    bar,
    sign,
    // categories,
    products,

    profile,

    catalog,
    product,

    cart,

    chats,

    orders,
    order,

    about,
    partners,
    delivery,
    faq,

    seller,

    sellerSign,
    sellerProducts,
    sellerProduct,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredPaths: ['confirm.confirmProps'],
        ignoredActions: ['confirm/setConfirmProps'],
      },
    }),
});
