import { loadStatus } from "@store/loadStatus";
import axios from "axios";

const { createSlice, createAsyncThunk } = require("@reduxjs/toolkit");

export const sellerSignUser = createAsyncThunk(
  "sign/sellerSignUser",
  async credentials => {
    try {
      const res = await axios.post(
        `${process.env.REACT_APP_API_URL}registerSellerWithPhone`,
        credentials
      );
      return res.data;
    } catch (err) {
      console.log(err);
    }
  }
);

export const sellerSendCode = createAsyncThunk(
  "sign/sellerSendCode",
  async ({ phone_number, code }) => {
    try {
      console.log(phone_number, code);
      const res = await axios.post(
        `${process.env.REACT_APP_API_URL}loginSellerWithPhone`,
        {
          phone_number,
          code,
        }
      );

      console.log(res);
      return res.data;
    } catch (err) {
      if(err.response) {
        return err.response.data;
      }
      throw err;
    }
  }
);

export const sellerCompleteReg = createAsyncThunk(
  "sign/sellerCompleteReg",
  async ({ company_name, city, address, token }) => {
    try {
      const config = {
        headers: { Authorization: `Bearer ${token}` },
      };

      const res = await axios.post(
        `${process.env.REACT_APP_API_URL}complete-seller-registration`,
        {
          company_name,
          city,
          address,
        },
        config
      );
      return res.data;
    } catch (err) {
      console.log(err);
    }
  }
);

export const sellerGetUser = createAsyncThunk(
  "sign/sellerGetUser",
  async token => {
    try {
      const config = {
        headers: { Authorization: `Bearer ${token}` },
      };

      const bodyParameters = {
        key: "value",
      };

      const res = await axios.post(
        `${process.env.REACT_APP_API_URL}user/profile`,
        bodyParameters,
        config
      );
      return res.data;
    } catch (err) {
      console.log(err);
    }
  }
);

const initialState = {
  user: {},
  token: "",
  error: null,
  isSign: false,
  isNum: false,
  isIn: false,
  isUp: false,
  isCode: false,
  signLoadStatus: "idle",
  numValue: "",
  codeValue: "",
  nameValue: "",
  emailValue: "",
  cityValue: "",
  addressValue: "",
};

const sellerSignSlice = createSlice({
  name: "sellerSign",
  initialState,
  reducers: {
    setIsSign: (state, action) => {
      state.isSign = action.payload;
    },
    setIsNum: (state, action) => {
      state.isNum = action.payload;
    },
    setIsIn: (state, action) => {
      state.isIn = action.payload;
    },
    setIsUp: (state, action) => {
      state.isUp = action.payload;
    },
    setIsCode: (state, action) => {
      state.isCode = action.payload;
    },
    setNumValue: (state, action) => {
      state.numValue = action.payload;
    },
    setCodeValue: (state, action) => {
      state.codeValue = action.payload;
    },
    setNameValue: (state, action) => {
      state.nameValue = action.payload;
    },
    setEmailValue: (state, action) => {
      state.emailValue = action.payload;
    },
    setCityValue: (state, action) => {
      state.cityValue = action.payload;
    },
    setAddressValue: (state, action) => {
      state.addressValue = action.payload;
    },

    setSellerToken: (state, action) => {
      state.token = action.payload;
    },
    setSellerUser: (state, action) => {
      state.user = action.payload;
    },

    sellerSignOut: state => {
      state.user = {};
      state.token = "";
      state.isSign = false;
      state.isNum = false;
      state.isIn = false;
      state.isUp = false;
      state.isCode = false;
      state.isSign = false;
      state.isSign = false;
      state.signLoadStatus = "idle";
      state.numValue = "";
      state.codeValue = "";

      localStorage.removeItem("token");
      localStorage.removeItem("user");
    },
  },

  extraReducers: builder => {
    builder
      .addCase(sellerSignUser.pending, state => {
        state.signLoadStatus = loadStatus.pending;
      })
      .addCase(sellerSignUser.fulfilled, (state, action) => {
        state.signLoadStatus = loadStatus.fulfilled;
        state.isNum = true;
        if (action.payload.status === "Auth") {
          state.isIn = true;
        } else if (action.payload.status === "Register") {
          state.isUp = true;
        }
      })
      .addCase(sellerSignUser.rejected, (state, action) => {
        state.signLoadStatus = loadStatus.rejected;
        state.error = action.error.message;
      });

    builder
      .addCase(sellerSendCode.pending, state => {
        state.signLoadStatus = loadStatus.pending;
      })
      .addCase(sellerSendCode.fulfilled, (state, action) => {
        state.signLoadStatus = loadStatus.fulfilled;

        const token = action.payload.token.split("|")[1];
        state.token = token;

        localStorage.setItem("token", JSON.stringify(token));
      })
      .addCase(sellerSendCode.rejected, (state, action) => {
        state.signLoadStatus = loadStatus.rejected;
        state.error = action.error.message;

        console.log(action);
          const token = action.payload.token.split("|")[1];
          state.token = token;
          localStorage.setItem("token", JSON.stringify(token));

      });

    builder
      .addCase(sellerCompleteReg.pending, state => {
        state.signLoadStatus = loadStatus.pending;
      })
      .addCase(sellerCompleteReg.fulfilled, (state, action) => {
        state.signLoadStatus = loadStatus.fulfilled;
        console.log(action.payload);
      })
      .addCase(sellerCompleteReg.rejected, state => {
        state.signLoadStatus = loadStatus.rejected;
      });

    builder
      .addCase(sellerGetUser.pending, state => {
        state.signLoadStatus = loadStatus.pending;
        state.user = {};
      })
      .addCase(sellerGetUser.fulfilled, (state, action) => {
        state.signLoadStatus = loadStatus.fulfilled;
        state.user = action.payload.profile;

        localStorage.setItem("user", JSON.stringify(action.payload.profile));
      })
      .addCase(sellerGetUser.rejected, (state, action) => {
        state.signLoadStatus = loadStatus.rejected;
        state.user = {};
        state.error = action.error.message;
      });

    // builder
    //   .addCase(sellerUpdateUser.pending, state => {
    //     state.signLoadStatus = loadStatus.pending;
    //     state.user = {};
    //   })
    //   .addCase(sellerUpdateUser.fulfilled, (state, action) => {
    //     state.signLoadStatus = loadStatus.fulfilled;
    //     state.user = action.payload.user;

    //     localStorage.setItem("user", JSON.stringify(action.payload.user));
    //   })
    //   .addCase(sellerUpdateUser.rejected, (state, action) => {
    //     state.signLoadStatus = loadStatus.rejected;
    //     state.user = {};
    //     state.error = action.error.message;
    //   });
  },
});

export const sellerSignSel = state => state.sellerSign;

export const {
  setIsSign,
  setIsNum,
  setIsIn,
  setIsUp,
  setIsCode,
  setNumValue,
  setCodeValue,
  setNameValue,
  setEmailValue,
  setCityValue,
  setAddressValue,

  setSellerToken,
  setSellerUser,
  sellerSignOut,
} = sellerSignSlice.actions;

export default sellerSignSlice.reducer;
