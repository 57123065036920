import { clsx } from "clsx";

import cl from "./SortMob.module.scss";

const SortMob = ({
  sortIsOpen,
  onSort,
  onSelect,
  options,
  selected,
  titleText = "Сортировка",
}) => {
  return (
    <div
      className={clsx(`${cl.sort__overlay} overlay fixed-block`, {
        active: sortIsOpen,
      })}
      onClick={onSort}
    >
      <div className={cl.sort} onClick={e => e.stopPropagation()}>
        <div className={`${cl.sort__head}`}>
          <h4 className={`${cl.sort__title}`}>{titleText}</h4>
          <button className={`${cl.sort__close}`} onClick={onSort}>
            <svg
              className="ico-40 stroke"
              xmlns="http://www.w3.org/2000/svg"
              width="40"
              height="40"
              viewBox="0 0 40 40"
              fill="none"
            >
              <path
                d="M13 26.7285L25.7279 14.0006"
                stroke="#337BF9"
                strokeWidth="1.5"
              />
              <path
                d="M13.3633 14.3643L26.0912 27.0922"
                stroke="#337BF9"
                strokeWidth="1.5"
              />
            </svg>
          </button>
        </div>
        <div className={`${cl.sort__item_outer}`}>
          {options.map(option => (
            <button
              className={`${cl.sort__item}`}
              key={option.value}
              onClick={() => onSelect(option)}
            >
              <span>{option.label}</span>
              {selected.value === option.value && (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="22"
                  height="22"
                  viewBox="0 0 22 22"
                  fill="none"
                >
                  <path
                    d="M11 1.375C13.5527 1.375 16.0009 2.38906 17.8059 4.1941C19.6109 5.99913 20.625 8.44729 20.625 11C20.625 13.5527 19.6109 16.0009 17.8059 17.8059C16.0009 19.6109 13.5527 20.625 11 20.625C8.44729 20.625 5.99913 19.6109 4.1941 17.8059C2.38906 16.0009 1.375 13.5527 1.375 11C1.375 8.44729 2.38906 5.99913 4.1941 4.1941C5.99913 2.38906 8.44729 1.375 11 1.375ZM9.801 12.8989L7.66287 10.7594C7.58622 10.6827 7.49523 10.6219 7.39508 10.5804C7.29493 10.539 7.18759 10.5176 7.07919 10.5176C6.97079 10.5176 6.86345 10.539 6.7633 10.5804C6.66315 10.6219 6.57215 10.6827 6.4955 10.7594C6.3407 10.9142 6.25373 11.1241 6.25373 11.3431C6.25373 11.562 6.3407 11.7719 6.4955 11.9267L9.218 14.6493C9.29443 14.7263 9.38536 14.7874 9.48554 14.8292C9.58572 14.8709 9.69317 14.8924 9.80169 14.8924C9.91021 14.8924 10.0177 14.8709 10.1178 14.8292C10.218 14.7874 10.3089 14.7263 10.3854 14.6493L16.0229 9.01038C16.1005 8.93404 16.1623 8.84308 16.2047 8.74275C16.247 8.64242 16.2691 8.53469 16.2696 8.42579C16.2701 8.31688 16.2491 8.20896 16.2076 8.10824C16.1662 8.00751 16.1053 7.91599 16.0283 7.83893C15.9514 7.76188 15.8599 7.70083 15.7592 7.6593C15.6586 7.61777 15.5507 7.59658 15.4418 7.59696C15.3328 7.59733 15.2251 7.61927 15.1247 7.6615C15.0243 7.70373 14.9333 7.76541 14.8569 7.843L9.801 12.8989Z"
                    fill="#337BF9"
                  />
                </svg>
              )}
            </button>
          ))}
        </div>
      </div>
    </div>
  );
};

export default SortMob;
